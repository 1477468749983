import axios from 'axios'

// const TAG = 'ApiCall'

export const ApiCallPost = async (url, parameters, headers) => {
  try {
    const response = await axios.post(url, parameters, { headers })
    return response
  } catch (error) {
    return error
  }
}

export const ApiCallGet = async (url, headers) => {
  try {
    const response = await axios.get(url, { headers })
    // console.log(
    //   TAG + ', ApiCallGet',
    //   `apiDebug, response : ${JSON.stringify(response.data)}`,
    // );
    return response
  } catch (error) {
    return error
  }
}
