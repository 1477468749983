import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { Switch, Route } from 'react-router-dom';
import Card from "./../components/Card/Index";
import { Divider } from '@mui/material';
import call_btn from '../../assets/call_btn.png';
import close_btn from '../../assets/close_btn.png';
import menu_toggle from '../../assets/menu_toggle.png';
import menu_toggle_black from '../../assets/menu_toggle_black.png';
import homepageWhatsapp from '../../assets/homepage-whatsapp.png';
import homepageWhatsappBlack from '../../assets/homepage-whatsapp-black.png';
import closeIcon from "../../assets/CloseIcon.png";
import whiteCallBtn from "../../assets/white-call-btn.png";
import blackCallBtn from "../../assets/black-call-btn.png";
import cartWhite from "../../assets/cart-white.png";
import cartBlack from "../../assets/cart-black.png";
import fbContact from '../../assets/fb-contact.png';
import youtubeContact from '../../assets/contact-youtube.png'
import instagramIcon from '../../assets/contact-insta.png'
import twitterIcon from '../../assets/contact-twitter.png'
import searchIcon from '../../assets/search-icon.png'
import greenBG from '../../assets/tab-green-bg.png'
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slider from "react-slick";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import useStyles from '../components/Card/styles';
import MerchantContactUs from "../components/MerchantContactUs/MerchantContactUs";
import { Constants } from "../utils/Constants";
import { useLocation } from 'react-router-dom';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const carousel = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 8000, // 8 seconds
  appendDots: dots => (
    <ul style={{ margin: '0' }}>
      {dots.slice(0, 4)} {/* Display only the first 4 dots */}
    </ul>
  ),
};

export default function Homepage(props) {

  const classes = useStyles();

  const history = useHistory();
  const [searchInput, setSearchInput] = useState('');
  const [mApp, setMApp] = useState('');
  const [openPhone, setOpenPhone] = useState(false);
  const [cartCount, setCartCount] = useState(null);
  const [cartVisible, setCartVisible] = useState(false);
  const [msgToWhatsapp, setMsgToWhatsapp] = useState('');
  const [openMenuTab, setOpenMenuTab] = useState(false);
  const { menuBarStyle, aboutUsPopupStyle, websiteThemeColors } = Constants;
  const [shopNameContainerHeight, setShopNameContainerHeight] = useState(0);
  const [menuOption, setMenuOption] = useState(0);
  const [aboutUs, setAboutUs] = useState(false);
  const location = useLocation();

  const merchantdetails = {
    data: JSON.parse(localStorage.getItem("merchantdetails")).merchantDetails
  }

  const cartData = JSON.parse(localStorage.getItem("cartData"))

  const imagelists = merchantdetails && merchantdetails.data.image_list.length > 0 ? merchantdetails.data.image_list.map((data) => data) : "";

  const shopinsidephotos = merchantdetails && merchantdetails.data.shop_inside_photos.length > 0 ? merchantdetails.data.shop_inside_photos.map((data) => data) : "";

  const picurls = merchantdetails && merchantdetails.data.picurl !== "" ? [merchantdetails.data.picurl] : "";

  console.log("shopinsidephotos", shopinsidephotos);

  const populatedarray = [...picurls, ...shopinsidephotos, ...imagelists];
  console.log("populatedarray", populatedarray);

  const myDivRef = useRef(null);
  const callRef = useRef(null);
  const mainRef = useRef(null);
  const searchInputRef = useRef(null);
  var mainMobileVisible = true;

  const indexToSwap = merchantdetails.data.mobile_numbers_enable_on_website.indexOf(merchantdetails.data.mainmobilenumber);

  // Check if the valueToCheck is in the array
  if (indexToSwap !== -1) {
    // Make a copy of the array to avoid directly mutating the state
    const newArray = [...merchantdetails.data.mobile_numbers_enable_on_website];

    // Swap the first element with the element containing the valueToCheck
    const temp = newArray[0];
    newArray[0] = newArray[indexToSwap];
    newArray[indexToSwap] = temp;
    merchantdetails.data.mobile_numbers_enable_on_website = newArray

    // Update the state with the new array
  } else {
    mainMobileVisible = false
  }
  const allEmptyStrings = merchantdetails.data.mobile_numbers_enable_on_website.every((element) => {
    // Check if element is not null or undefined
    if (element != null) {
      // Trim the element and check if it's an empty string
      return element.trim() === '';
    } else if (element === null) {
      // If element is null or undefined, consider it as an empty string
      return true
    } else {
      return false
    }
  });
  const firstNonEmptyIndex = merchantdetails.data.mobile_numbers_enable_on_website.findIndex((element) => element?.trim() !== '');

  const websiteTheme = merchantdetails.data.webisteBanner;
  const themeColor = merchantdetails.data.color;
  // const themeColor = 'white';
  // const websiteTheme = greenBG;

  useEffect(() => {
    if (mApp) {
      document.body.classList.add('non-clickable');
    } else {
      document.body.classList.remove('non-clickable');
    }
  }, [mApp]);

  useEffect(() => {
    if (searchInput.length === 1) {
      window.scrollTo(0, 0);

    }
  }, [searchInput])

  useLayoutEffect(() => {
    const updateHeight = () => {
      // Introduce a slight delay before getting the height
      setTimeout(() => {
        // Get the height of the shop-name-container
        const shopNameContainer = document.querySelector('.shop-name-container');
        if (shopNameContainer) {
          setShopNameContainerHeight(shopNameContainer.offsetHeight);
        }
      }, 50); // Adjust the delay as needed
    };

    // Call the updateHeight function initially
    updateHeight();

    // Use both resize and orientationchange events as fallbacks
    window.addEventListener('resize', updateHeight);
    window.addEventListener('orientationchange', updateHeight);

    // Clean up the event listeners on component unmount
    return () => {
      window.removeEventListener('resize', updateHeight);
      window.removeEventListener('orientationchange', updateHeight);
    };
  }, []);


  useEffect(() => {
    const urls = window.location.href;
    var url = new URL(urls);
    var merapp = url.searchParams.get('ismApp')
    setMApp(merapp);
  }, []);

  const arrays = [...picurls, ...shopinsidephotos, ...imagelists];
  const finalarray = [...arrays];

  const handleWhatsapp = () => {
    const urls = window.location.href;
    const aliasname1 = urls.substring(urls.lastIndexOf("/") + 1);
    const alias = aliasname1.split("#");
    const aliasname2 = alias[0].split("?");
    var url = new URL(urls);
    var userApp = url.searchParams.get("isUserApp");
    const aliasname = aliasname2[0];
    const encodedValue = encodeURIComponent(msgToWhatsapp);
    if (window.ReactNativeWebView && userApp != null) { // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
      window.ReactNativeWebView.postMessage(`https://api.whatsapp.com/send?phone=${merchantdetails.data.mainmobilenumber}`)
    } else {
      window.open(
        `https://api.whatsapp.com/send?phone=919289454472&text=Website order for ${aliasname}%0a${encodedValue}`
      );
    }
  };

  const handleClearSearch = () => {
    setSearchInput('');
    searchInputRef.current.focus();
  }

  const handleCartClick = () => {

    if (location.pathname === '/venkat-groceries/contact') {
      localStorage.setItem('cartFromContact', '1');
      history.push(`/${localStorage.getItem("fullurl")}`)
    } else {
      setCartVisible(true);
    }
  }

  function hasSingleNonEmptyValue(arr) {
    const nonEmptyValues = arr.filter(value => value !== '');
    return nonEmptyValues.length === 1;
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      searchInputRef.current.focus();
    }
  }
  // merchantdetails.data.storedescription = false

  return (
    <div className="main_container" ref={mainRef} style={{ margin: "0px 0px 0px 0px", position: 'relative' }}>
      <div className="data_container">

        {/* TOP-BAR */}
        <div className="top-bar">
          <img src={websiteTheme} className="tab-green-bg" alt="NA" />
          <div className="toggle-search-container">
            <img onClick={() => setOpenMenuTab(true)} src={themeColor === websiteThemeColors.black ? menu_toggle_black : menu_toggle} className="menu-toggle" alt="Menu" />
            <div ref={myDivRef} className={'search_bar'} >
              <input value={searchInput} ref={searchInputRef} type="text" className={'non_fixed_search'} onKeyDown={handleKeyDown} onChange={(e) => { history.push(`/${localStorage.getItem("fullurl")}`); setSearchInput(e.target.value); }} placeholder="Search any item" />
              <img src={searchIcon} className="search_icon" alt="search" />
              <img onClick={handleClearSearch} src={close_btn} className={searchInput !== '' ? 'cross-btn-visible' : 'cross-btn-invisible'} alt="NA" />
            </div>
          </div>
          <div hidden={searchInput !== ''} className="top-bar-right-icons-container">
            <img ref={callRef} onClick={(merchantdetails.data.mobile_numbers_enable_on_website && merchantdetails.data.mobile_numbers_enable_on_website.length !== 0) ? () => setOpenPhone(true) : null} src={themeColor === websiteThemeColors.black ? blackCallBtn : whiteCallBtn} className="top-bar-right-icons" alt="call" />
            <img onClick={handleWhatsapp} src={themeColor === websiteThemeColors.black ? homepageWhatsappBlack : homepageWhatsapp} className="top-bar-right-icons" alt="whatsapp" />
            <div onClick={handleCartClick} className="cart_icon">
              <img src={themeColor === websiteThemeColors.black ? cartBlack : cartWhite} className="top-bar-right-icons" alt="cart" />
              {cartData && cartData.length !== 0 && cartCount > 0 && (
                <div className="cart_count_container">
                  <p className="cart_count">{cartCount && cartCount}</p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="shopname-greenbg-container">
          <img src={websiteTheme} className="green-bg-shopname" style={{ height: `${shopNameContainerHeight + 8}px` }} alt="NA" />
          <div className="shop-name-container">
            {(
              merchantdetails.data.storedescription &&
              merchantdetails.data.storedescription !== 'null' &&
              merchantdetails.data.storedescription !== 'undefined'
            ) ? (
              <p className={`first-image-shop-name ${themeColor === websiteThemeColors.black ? 'black-font' : ''}`}>
                {merchantdetails.data.shopname}
              </p>
            ) : (
              <p className={`shop-name-no-description ${themeColor === websiteThemeColors.black ? 'black-font' : ''}`}>
                {merchantdetails.data.shopname}
              </p>
            )}

            {(
              merchantdetails.data.storedescription &&
              merchantdetails.data.storedescription !== 'null' &&
              merchantdetails.data.storedescription !== 'undefined'
            ) && (
                <>
                  <div className={themeColor === websiteThemeColors.black ? 'shop-name-divider-black' : 'shop-name-divider'} />
                  <div className="about-us-container">
                    <p onClick={() => setAboutUs(true)} className={`merchant-about-us ${themeColor === websiteThemeColors.black ? 'black-font' : ''}`}>{merchantdetails.data.storedescription}</p>
                    <FontAwesomeIcon onClick={() => setAboutUs(true)} color={themeColor === websiteThemeColors.black ? '#414042' : 'white'} style={{ width: '40px' }} icon={faChevronDown} />
                  </div>
                </>

              )}
          </div>
          <div className="powered-by-div">
            <p className="powered-by-text">Powered by</p>
            <p className="chattybao-text">ChattyBao</p>
          </div>
        </div>

        {searchInput === '' && (
          <div>
            <Slider {...carousel}>
              {finalarray.filter(dat => dat !== '')
                .map((data, index) => (
                  <div>
                    <div>
                      <img
                        src={data}
                        alt="img"
                        className="carousel-image"
                        height={363}
                      />
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        )}

        <Switch>
          <Route path={`/${localStorage.getItem("fullurl")}/contact`}>
            <MerchantContactUs merchantdetails={{
              latitude: merchantdetails.data.latitude,
              longitude: merchantdetails.data.longitude,
              addressline1: merchantdetails.data.addressline1,
              addressline2: merchantdetails.data.addressline2,
              city: merchantdetails.data.city,
              pincode: merchantdetails.data.pincode,
              opentime: merchantdetails.data.openTime,
              opentime2: merchantdetails.data.openTime2,
              closetime: merchantdetails.data.closeTime,
              closetime2: merchantdetails.data.closeTime2,
              weeklyoff: merchantdetails.data.weeklyoff,
              mainmobilenumber: merchantdetails.data.mainmobilenumber,
              teammobilenumbers: merchantdetails.data.teammobilenumbers,
              mobile_numbers_enable_on_website: merchantdetails.data.mobile_numbers_enable_on_website,
              socialMedia: merchantdetails.data.socialMedia,
              displayAddress: merchantdetails.data.displayAddress,
              merchant_email: merchantdetails.data.merchant_email
            }} />
          </Route>
          <Route path={`/${localStorage.getItem("fullurl")}`}>
            <Card
              searchInput={searchInput}
              setCartCount={setCartCount}
              cartVisible={cartVisible}
              setCartVisible={setCartVisible}
              menuOption={menuOption}
              setMenuOption={setMenuOption}
            />
          </Route>
        </Switch>

        {/* Menu Bar */}

        <Dialog
          open={openMenuTab}
          className=""
          fullScreen
          classes={{
            paper: classes.menuBar,
          }}
          PaperProps={{ sx: menuBarStyle }}
          onClose={() => setOpenMenuTab(!openMenuTab)}
        >
          <img
            src={closeIcon}
            className="menu-bar-close-icon"
            onClick={() => {
              setOpenMenuTab(!openMenuTab);
            }}
            alt="close"
          />
          <DialogContent>
            <div className="menu-bar-content">
              <div className="menu-bar-routes-container">
                <p className="menu-bar-route" onClick={() => { history.push(`/${localStorage.getItem("fullurl")}`); setOpenMenuTab(!openMenuTab); setMenuOption(0); }}>Home</p>
                <p className="menu-bar-route" onClick={() => { history.push(`/${localStorage.getItem("fullurl")}`); setOpenMenuTab(!openMenuTab); setMenuOption(1); }}>Bestsellers</p>
                <p className="menu-bar-route" onClick={() => { history.push(`/${localStorage.getItem("fullurl")}`); setOpenMenuTab(!openMenuTab); setMenuOption(2); }}>Products</p>
              </div>
              <p className="menu-bar-route" onClick={() => { history.push(`/${localStorage.getItem("fullurl")}/contact`); setOpenMenuTab(!openMenuTab); setMenuOption(3); }}>Contact Us</p>
              <div className="menu-bar-details-container">
                <p>{merchantdetails.data.shopname}</p>
                {(merchantdetails.data.displayAddress && merchantdetails.data.displayAddress.addressline1 !== "") ? (
                  <>
                    <p className="address-text-contact">{merchantdetails.data.displayAddress.addressline1}, {merchantdetails.data.displayAddress.addressline2}</p>
                    <p className="address-text-contact">{merchantdetails.data.displayAddress.city} - {merchantdetails.data.displayAddress.pincode}</p>
                  </>
                ) : (
                  <>
                    <p className="address-text-contact">{merchantdetails.data.addressline1}, {merchantdetails.data.addressline2}</p>
                    <p className="address-text-contact">{merchantdetails.data.city} - {merchantdetails.data.pincode}</p>
                  </>
                )}
                {merchantdetails.data.merchant_email && merchantdetails.data.merchant_email.showOnWebsite === true && (
                  <p className="email-text">{merchantdetails.data.merchant_email.email}</p>
                )}
                {allEmptyStrings !== true && (
                  <div className='call-icon-text-container'>
                    <img src={call_btn} className='call-icon' alt='social' />
                    <div className="phonenumbers-container">
                      {merchantdetails.data.mobile_numbers_enable_on_website.length !== 0 && merchantdetails.data.mobile_numbers_enable_on_website.map((data, index) => (
                        <>
                          {data && (
                            <a href={`tel:+91${data}`} className='ph-number-link phone-number-text-menu'>{firstNonEmptyIndex === index ? data : `, ${data}`}</a>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                )}
                <div className='social-icon-text-container'>
                  {merchantdetails.data.socialMedia && merchantdetails.data.socialMedia.instagramUrl !== "" && (
                    <a href={`https://www.instagram.com/${merchantdetails.data.socialMedia.instagramUrl}/`}>
                      <img src={instagramIcon} className='contact-social-icon' alt='social' />
                    </a>
                  )}
                  {merchantdetails.data.socialMedia && merchantdetails.data.socialMedia.twitterUrl !== "" && (
                    <a href={`https://twitter.com/${merchantdetails.data.socialMedia.twitterUrl}/`}>
                      <img src={twitterIcon} className='contact-social-icon' alt='social' />
                    </a>
                  )}
                  {/* <img src={fbContact} className='contact-social-icon' alt='social' />
                  <img src={youtubeContact} className='contact-social-icon' alt='social' /> */}
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        {/* Call layover */}

        <Dialog classes={{ paper: classes.botm_dialog }} open={openPhone} PaperProps={{ sx: { width: "100%", marginTop: "auto", marginBottom: "0", marginLeft: '0', marginRight: "0" } }} onClose={() => setOpenPhone(!openPhone)}>
          <img
            src={close_btn}
            className="call_close_btn"
            onClick={() => {
              setOpenPhone(!openPhone);
            }}
            alt="close"
          />
          <p className="call-contact-us-header">
            Contact Us
          </p>
          <DialogContent style={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: 0 }}>
            <div className="call-contact-container">
              {merchantdetails.data.mobile_numbers_enable_on_website && merchantdetails.data.mobile_numbers_enable_on_website.length !== 0 && (
                merchantdetails.data.mobile_numbers_enable_on_website.map((data, index) => {
                  return (
                    data ? (
                      <>
                        {console.log("MERRRR,", merchantdetails.data.mobile_numbers_enable_on_website)}
                        <div className="phone-owner-container">
                          <div style={{ width: '180px' }}>
                            <a style={{ color: 'black', display: 'flex', alignItems: 'center' }} href={`tel:+91${data}`}>
                              <img src={call_btn} style={{ height: '20px', width: '20px', marginRight: '10px' }} alt='call' />
                              <p className="phone_call_text">{data}</p>
                            </a>
                          </div>
                          {mainMobileVisible ? (
                            <p className="business-owner-text">{index === 0 ? 'Business Owner' : index === 1 ? 'Staff 1' : 'Staff 2'}</p>
                          ) : (
                            <p className="business-owner-text">{index === 0 ? 'Staff 1' : index === 1 ? 'Staff 2' : ''}</p>
                          )}
                        </div>
                        <div hidden={hasSingleNonEmptyValue(merchantdetails.data.mobile_numbers_enable_on_website)}>
                          <Divider className="phone-number-divider" />
                        </div>
                      </>

                    ) : ''
                  )
                })
              )}
            </div>
          </DialogContent>
        </Dialog>

        {/* About Us pop up */}
        <Dialog
          open={aboutUs}
          className=""
          fullScreen
          classes={{
            paper: classes.aboutUs,
          }}
          PaperProps={{ sx: aboutUsPopupStyle }}
          onClose={() => setAboutUs(!aboutUs)}
        >
          <img
            src={close_btn}
            className="call_close_btn"
            onClick={() => {
              setAboutUs(!aboutUs);
            }}
            alt="close"
          />
          <DialogContent>
            <p className="about-us-header">About Us</p>
            <p className="about-us-content">{merchantdetails.data.storedescription}</p>
          </DialogContent>
        </Dialog>

      </div>

    </div>
  );
}
