import React from "react";
import "./index.scss";
import playstore from "../../../assets/images/play-store.png";
import business_page_cover from "../../../assets/business_page_cover.jpg";
import business_image1 from "../../../assets/business_image1.png";
import business_image2 from "../../../assets/business_image2.png";
import business_image3 from "../../../assets/business_image3.png";
import business_image4 from "../../../assets/business_image4.png";
import text_box_business from "../../../assets/text_box_business.png";

const BusinessPage = () => {
  return (
    <>
      <div
        className="top_container margin_less"
        style={{ marginBottom: "80px" }}
      >
        <img
          className="business_cover_image"
          src={business_page_cover}
          style={{ width: "100%" }}
        />
        <div className="business_box_container">
          <img className="big_box_business" src={text_box_business} alt="NA" />
          <div
            style={{
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p className="business_box_container_text">Have a business?</p>
            <p className="inside_box_text">
              Sign up with ChattyBao
              <br />
              Your Online Business Partner
            </p>

            <a
              style={{ marginTop: "20px" }}
              href="https://play.google.com/store/apps/details?id=com.goping.merchant"
            >
              <img className="playstore_icon" src={playstore} alt="playstore" />
            </a>
          </div>
        </div>
      </div>
      <div className="body_container">
        <div
          className="business_ads_container"
          style={{ backgroundColor: "#def4ff" }}
        >
          <div className="business_ad_container">
            <img src={business_image1} className="business_ad_image" />
            <div className="text_align">
              <p className="choosefrom_text">
                Take your
                <br />
                Business Online
              </p>
              <p className="adsub_text max_width">
                Get your own website with catalog, multiple ways to promote your
                store, collect digital payments easily and get option of one
                click delivery service.
              </p>
            </div>
          </div>
        </div>
        <div className="business_ads_container">
          <div className="business_ad_container2">
            <div className="adtext_container text_align_end">
              <p className="adhead_text">
                Zero Commissions
                <br />
                Online Business App
              </p>
              <p className="adsub_text max_width">
                Zero per-order commissions.
              </p>
            </div>
            <img className="business_ad_image2" src={business_image2} />
          </div>
        </div>
        <div
          className="business_ads_container"
          style={{ backgroundColor: "#def4ff" }}
        >
          <div className="business_ad_container">
            <img className="business_ad_image" src={business_image3} />
            <div className="adtext_container">
              <p className="adhead_text">Get Online Orders</p>
              <p className="adsub_text max_width">
                Get your business discovered on WhatsApp & ChattyBao App.
                Receive orders from customers.
              </p>
            </div>
          </div>
        </div>
        <div className="business_ads_container">
          <div className="business_ad_container2">
            <div className="adtext_container text_align_end">
              <p className="adhead_text">Complete Control</p>
              <p className="adsub_text max_width">
                Set your own prices, promotions & offers. Interact directly with
                your customers over chat, call or video.
              </p>
            </div>
            <img className="business_ad_image2" src={business_image4} />
          </div>
        </div>
        <div className="third_container_business">
          <p className="thirdcontainer_text">
            Dukaan ka Bharosa, Online ka Aaram!
          </p>
          <p
            className="download_text"
            style={{ textAlign: "center", marginLeft: "-20px" }}
          >
            Download App now
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <a href="https://play.google.com/store/apps/details?id=com.goping.merchant">
              <img className="playstore_icon" src={playstore} alt="playstore" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessPage;
