const Constants = {
    menuBarStyle: {
        width: "90%",
        marginBottom: 0,
        height: '100%',
        marginRight: 'auto',
        // backgroundColor: '#AAD49F'
    },

    chattybaoContactTexts: {
        email: 'help@chattybao.com',
        contactNumber: '8076219166',
        addressline1: 'C-17, Basement, B-3, Guru Nanak Pura,',
        addressline2: 'Laxmi Nagar, New Delhi 110092'
    },

    aboutUsPopupStyle: {
        width: "100%",
        height: '40%',
        marginRight: '10%',
        marginLeft: '10%'
    },

    websiteThemeColors: {
        black: 'black',
        white: 'white'
    }
}

export { Constants };