import React, { useEffect, useState } from 'react'
import './OrderTracking.css'
import ApiService from '../../utils/ApiService';

const OrderTracking = () => {
  const [shipmentStatus, setShipmentStatus] = useState(null);
  const [orderId, setOrderId] = useState(null);

  useEffect(() => {
    const url = window.location.href;
    const newurl = new URL(url);
    const id = newurl.searchParams.get("orderId");
    setOrderId(id);
    getStatus(id)
  }, [])

  const getStatus = async (id) => {
    try {
      const result = await ApiService.trackOrder(id);
      if (result.data.code === 0) {
        console.log("res", result);
        setShipmentStatus(result.data.data.shipment_status);
      } else {
        console.log("Fail")
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      {shipmentStatus && (shipmentStatus > 0 && shipmentStatus < 7) ? (
        <div className='whole-contaier'>
          <p className='track-text'>Track your order</p>
          <p className='order-id-text'>Order ID: {orderId}</p>
          <div className='tracking-container'>
            <div className='progress-container'>
                  <div className='status-container'>
                    <div className={shipmentStatus >= 1 ? 'circle-marker' : 'circle-marker-empty'} />
                    <div>
                      <p className='status-text-header'>Order Created</p>
                      {/* <p className='status-text'>We have received your order</p> */}
                    </div>
                  </div>
                  <div className='vertical-line-container'>
                    <div className={shipmentStatus >= 2 ?'vertical-line-indicator' : 'vertical-line-indicator-black'} />
                  </div>
                  <div className='status-container'>
                    <div className={shipmentStatus >= 2 ? 'circle-marker' : 'circle-marker-empty'} />
                    <div>
                      <p className='status-text-header'>Delivery partner assigned</p>
                      {/* <p className='status-text'>Your order has been packed</p> */}
                    </div>
                  </div>

                  <div className='vertical-line-container'>
                    <div className={shipmentStatus >= 3 ?'vertical-line-indicator' : 'vertical-line-indicator-black'} />
                  </div>
                  <div className='status-container'>
                    <div className={shipmentStatus >= 3 ? 'circle-marker' : 'circle-marker-empty'} />
                    <div>
                      <p className='status-text-header'>Delivery partner reached</p>
                      {/* <p className='status-text'>Your order has been shipped</p> */}
                    </div>
                  </div>

                  <div className='vertical-line-container'>
                    <div className={shipmentStatus >= 4 ?'vertical-line-indicator' : 'vertical-line-indicator-black'} />
                  </div>
                  <div className='status-container'>
                    <div className={shipmentStatus >= 4 ? 'circle-marker' : 'circle-marker-empty'} />
                    <div>
                      <p className='status-text-header'>Order picked up</p>
                      {/* <p className='status-text'>Your order is out for delivery</p> */}
                    </div>
                  </div>

                  <div className='vertical-line-container'>
                    <div className={shipmentStatus >= 5 ?'vertical-line-indicator' : 'vertical-line-indicator-black'} />
                  </div>
                  <div className='status-container'>
                    <div className={shipmentStatus >= 5 ? 'circle-marker' : 'circle-marker-empty'} />
                    <div>
                      <p className='status-text-header'>Delivered</p>
                      {/* <p className='status-text'>Your order is delivered successfully</p> */}
                    </div>
                  </div>

                  <div className='vertical-line-container'>
                    <div className={shipmentStatus >= 6 ?'vertical-line-indicator' : 'vertical-line-indicator-black'} />
                  </div>
                  <div className='status-container'>
                    <div className={shipmentStatus >= 6 ? 'circle-marker' : 'circle-marker-empty'} />
                    <div>
                      <p className='status-text-header'>Cancelled</p>
                      {/* <p className='status-text'>Your order is cancelled</p> */}
                    </div>
                  </div>

            </div>
          </div>
        </div>
      ) : (
        <div className='tracking-container'>
          <p className='inactive-text'>Not an active order</p>
        </div>
      )}

    </div>

  )
}

export default OrderTracking
