import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import routes from "./../../src/routes";
import withTracker from "./../../src/withTracker";
import "./app.css";
import Routers from "./router";
import { APIConfig } from "./utils/apiConfig";
import OrderTracking from "./pages/ONDC-OrderTracking/OrderTracking";
import ApiService from "./utils/ApiService";

function App() {
	const history = useHistory();
	const [image, setImage] = useState([]);
	const [url, setUrl] = useState("");
	const [merchantName, setMerchantName] = useState("");
	const [visitingCards, setVisitingCards] = useState("");
	const [spinner, setSpinner] = useState(false);
	const [urlcheck, setUrlCheck] = useState(true);

	useEffect(() => {

		var urlPath = window.location.pathname;
		var fullurl = urlPath.substring(urlPath.indexOf("/") + 1);
		setUrlCheck(fullurl);
		//Google Analytics
		var urlTrack = window.location.href;
		var checkTrack = urlTrack.includes("?utm_source");
		console.log(urlTrack + "urlTrack");
		console.log(checkTrack);
		if (checkTrack != "") {
			var urlTrackAdd = window.location.href;
			var result = urlTrackAdd.slice(1);
			var finalUrl = urlTrackAdd.substring(urlTrackAdd.lastIndexOf("https"));
			window.location.replace(finalUrl);
		}

		//Google Analytics
		localStorage.setItem("fullurl", fullurl);

		var merchantName = urlPath.substring(urlPath.lastIndexOf("/") + 1);
		console.log("merchantName", merchantName);

		setUrl(merchantName);
		if (
			fullurl !== "" &&
			fullurl !== null &&
			fullurl !== undefined &&
			!window.location.hash
		) {
			getmerchant(merchantName, fullurl);
		}
	}, [merchantName]);

	const getmerchant = async (merchantName, fullurl) => {
		let token = null;
		if (localStorage.getItem('sessionId')) {
			token = localStorage.getItem('sessionId');
		}
		const data = {
			merchantId: merchantName,
			ulatitude: '16.43',
			ulongitude: '74.59'
		}
		try {
			const result = await ApiService.getMerchantDataForCatalogue(data, token);
			if (result.data.code === 0) {
				localStorage.removeItem("fromContact")
				localStorage.setItem("merchantdetails", JSON.stringify(result.data));
				if (localStorage.getItem("fullurl") !== localStorage.getItem("fullurlold")) {
					localStorage.removeItem("cartData");
					localStorage.removeItem("extraInstructions");
					localStorage.setItem("fullurlold", fullurl);
					localStorage.setItem("fullurlcheck", "0");
					window.location.reload()
				}
			} else {
				setUrl("");
				if (fullurl == "user-agreement" || fullurl == "privacy-policy" || fullurl == "merchantagreement" || fullurl == "business" || fullurl == "vip-plan" || fullurl == "Grievance-Redressal" || fullurl == "CancellationRefundsReturnExchange" || fullurl == "CbForce-PrivacyPolicy" || fullurl == "map" || fullurl == "merchant-agreement" || fullurl == "tracking") {
					setUrl(fullurl);
					localStorage.setItem("fullurlold", fullurl);
					localStorage.setItem("fullurl", fullurl);
					console.log("FulURL===> ", fullurl)
				} else {
					fullurl = "";
					setUrl('/');
					history.push('/');
					window.location.reload();

				}
			}
		} catch (error) {
			alert(error.message)
		} finally {

		}
	};

	console.log("url", url);


	return (
		<div>
			<div className="main">
				{url !== "" && url !== "user-agreement" && url !== "privacy-policy" && url !== "merchantagreement" && url !== "business" && url !== "vip-plan" && url !== "Grievance-Redressal" && url !== "CancellationRefundsReturnExchange" && url !== "CbForce-PrivacyPolicy" && url !== "map" && url !== "merchant-agreement" && (
					<>
						{url === "tracking" ? (
							<Router basename={process.env.REACT_APP_BASENAME || ""}>
								<Route
									path={"/tracking"}
									component={OrderTracking}
								/>
							</Router>
						) : (
							<Router basename={process.env.REACT_APP_BASENAME || ""}>
								<div>
									{routes.map((route, index) => {
										return (
											<Route
												key={index}
												path={route.path}
												exact={route.exact}
												component={withTracker((props) => {
													return (
														<route.layout {...props}>
															<route.component {...props} />
														</route.layout>
													);
												})}
											/>
										);
									})}
								</div>
							</Router>
						)}
					</>


				)}
				{urlcheck == "" && <Routers />}
				{url == "user-agreement" && <Routers />}
				{url == "privacy-policy" && <Routers />}
				{url == "merchantagreement" && <Routers />}
				{url == "business" && <Routers />}
				{url == "vip-plan" && <Routers />}
				{url == "Grievance-Redressal" && <Routers />}
				{url == "CancellationRefundsReturnExchange" && <Routers />}
				{url == "CbForce-PrivacyPolicy" && <Routers />}
				{url == "merchant-agreement" && <Routers />}
			</div>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={spinner}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</div>
	);
}

export default App;
