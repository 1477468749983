/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable no-undef */
import React, { useState } from "react";
import { useHistory } from "react-router";
import chattybao_logo from "../../../assets/chattybao_logo.png";
import menu_toggle from "../../../assets/menu_toggle.png";
import CloseIcon from "../../../assets/CloseIcon.png";
import "./index.scss";
import { Dialog, DialogContent } from "@mui/material";
import useStyles from "../../components/Card/styles";

const Header = () => {
  const history = useHistory();
  const [isTabOpen, setIsTabOpen] = useState(false);
  const [toggleTab, setToggleTab] = useState(1);
  const [menuVisible, setMenuVisible] = useState(false);

  const classes = useStyles();

  // const handleClickScroll = () => {
  //   history.push("/");
  //   window.scrollTo(0, 0);
  // };

  const handleConsumer = () => {
    setToggleTab(1);
    history.push("/");
    setMenuVisible(false);
  };

  const handleBusiness = () => {
    setToggleTab(2);
    history.push("business");
    setMenuVisible(false);
  };

  const handleContact = () => {
    setToggleTab(2);
    history.push("contact-us");
    setMenuVisible(false);
  };

  console.log(window.location.pathname)
  
  return (
    <div
      className="header"
      style={{
        backgroundColor: "#125d8d",
        color: "white",
        position: "relative",
      }}
    >
      {console.log("Path", history.location.pathname)}
      {(true) ? (
        <>
        <div
        onClick={handleConsumer}
        className="tabs"
        style={{
          paddingRight: "60px",
          fontWeight: "700",
          color: `${(toggleTab === 1 && history.location.pathname === '/') ? "white" : "#cccccc"}`,
        }}
      >
        <p style={{ fontFamily: "Barlow-bold" }}>HOME</p>
        {(toggleTab === 1 && history.location.pathname === '/') && <div className="toggle_highlight" />}
      </div>
      <div
        onClick={handleBusiness}
        className="tabs"
        style={{
          paddingLeft: "60px",
          fontWeight: "700",
          color: `${(toggleTab === 2 && history.location.pathname === '/business') ? "white" : "#cccccc" }`,
        }}
      >
        <p style={{ fontFamily: "Barlow-bold" }}>FOR BUSINESSES</p>
        {(toggleTab === 2 && history.location.pathname === '/business') && <div className="toggle_highlight" />}
      </div>
        </>
      ) : ''}
      

      {console.log("Toggl==>", toggleTab)}

      <div className="menu_toggle">
        <img
          onClick={() => setMenuVisible(true)}
          src={isTabOpen ? CloseIcon : menu_toggle}
          style={{
            width: `${isTabOpen ? "20px" : "40px"}`,
            alignSelf: "flex-end",
          }}
          alt="menu"
        />
      </div>

      <img
        src={chattybao_logo}
        className="logo_chattybao"
        alt="logo"
      />
      <Dialog
        classes={{ paper: classes.scrollableDialog }}
        onClose={() => setMenuVisible(!menuVisible)}
        BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0)" } }}
        open={menuVisible}
        PaperProps={{
          sx: {
            width: "100%",
            height: "230px",
            marginRight: "0",
            marginLeft: "auto",
            marginTop: "0",
            marginBottom: "auto",
            backgroundColor: "white",
            color: "black",
            borderRadius: "0",
            overflow: "hidden",
          },
        }}
      >
        <img
          src={CloseIcon}
          style={{
            alignSelf: 'flex-end',
            width: "20px",
            marginTop: '10px',
            marginRight: '10px'
          }}
          onClick={() => setMenuVisible(false)}
          alt="close"
        />
        <DialogContent className="toggle_data">
          <div onClick={handleConsumer} style={{ width: "100%" }}>
            <p className="menu_text">Home</p>
          </div>
          <div onClick={handleBusiness} style={{ width: "100%" }}>
            <p className="menu_text">For Businesses</p>
          </div>
          <div onClick={handleContact} style={{ width: "100%" }}>
            <p className="menu_text">Contact Us</p>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Header;
