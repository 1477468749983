import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory, BrowserRouter as Router } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { setCartValue, setTotalOrderData } from "../../redux/reduxStore.js";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import ReactGA from 'react-ga';
import { authentication } from "../../utils/firebase-config.js";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { BeatLoader, ClipLoader, MoonLoader } from "react-spinners";
import LoadingSpinner from "../loadingSpinner/loadingSpinner.js";

import "./Index.css";
import '../../pages/productPage.css';
import {
  Card,
  CardContent,
  CardActions,
  Divider,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import edit_icon from "../../../assets/edit.png";
import close_icon from "../../../assets/close.png";
import discount_tag from '../../../assets/Green_Discount_Tag.png';
import bestSeller_tag from '../../../assets/Bestseller_Tag.png';
import discount_tag_hor from '../../../assets/discount_tag_hor.png';
import confirm_btn from '../../../assets/Confirm_Button.png';
import discount_tag_variants from '../../../assets/Discount_Tag_Variants.png';
import close_btn from '../../../assets/close_btn.png';
import variant_bestseller from '../../../assets/variant_bestseller.png';
import down_arrow from '../../../assets/down_arrow.png';
import menu_icon from '../../../assets/menu_icon.png';
import chat_icon from '../../../assets/chat_icon.png';
import share_icon from '../../../assets/share_icon.png';
import up_arrow from '../../../assets/up_arrow.png';
import order_delivered_grey from '../../../assets/order_delivered_grey.png';
import order_delivered_green from '../../../assets/order_delivered_green.png';
import order_placed_green from '../../../assets/order_placed_green.png';
import flourishLeft from '../../../assets/flourish-left.png'
import flourishRight from '../../../assets/flourish-right.png'
import payment_grey from '../../../assets/payment_grey.png';
import payment_green from '../../../assets/payment_green.png';
import chattybao from '../../../assets/chattybao.png';
import info from '../../../assets/info.png';
import productsBanner from '../../../assets/product-banner.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import CloseIcon from "@mui/icons-material/Close";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { data } from "jquery";
import useStyles from "./styles";
import { TransformComponent, TransformWrapper } from "@pronestor/react-zoom-pan-pinch";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import veg_icon from '../../../../src/assets/veg.png';
import non_veg_icon from '../../../../src/assets/nonveg.png';
import egg_icon from '../../../../src/assets/egg.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ApiService from '../../utils/ApiService';
import { debounce } from 'lodash'

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  variantWidth: false
};

ReactGA.initialize('UA-115105611-2');
const Page = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { searchInput, isFruitsAndVegMerchant, PhoneNumber, gridType, businessName, merchId, isCheckout, shopOff, setCartCount, cartVisible, setCartVisible, catalogCount, userOfferBanner, menuOption, setMenuOption } = props;

  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);
  const [viewCartVisible, setViewCartVisible] = useState(false);
  const [productDetailVisible, setProductDetailVisible] = useState(false);
  const productdata = JSON.parse(localStorage.getItem("modifiedData"));
  const [bestSeller, setBestSeller] = useState([]);;
  const [modificationData, setModificationData] = React.useState([]);
  const [instruction, setInstruction] = useState();
  const [instructionIndex, setInstructionIndex] = useState();
  const [totalPrice, setTotalPrice] = useState("");
  const [oldTotalPrice, setOldTotalPrice] = useState("");
  const [savings, setSavings] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [productId, setProductId] = useState("");
  const [productImage, setProductImage] = useState([]);
  const [productName, setProductName] = useState("");
  const [pdBrand, setPdBrand] = useState("");
  const [productCount, setProductCount] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productUnit, setProductUnit] = useState("");
  const [productDesc, setProductDesc] = useState("");
  const [productAvailabilityData, setProductAvailabilityData] = useState('');
  const [productDiscPrice, setProductDiscPrice] = useState('');
  const [pdOffer, setPdOffer] = useState('');
  const [variantsVisible, setVariantsVisible] = useState(false);
  const [variants, setVariants] = useState([]);
  const [productVariantExists, setProductVariantExists] = useState(false);
  const [varDataFromPd, setVarDataFromPd] = useState([]);
  const [instructionFromHome, setInstructionFromHome] = useState(null);
  const [variantIndex, setVariantIndex] = useState(null);
  const [variantUnit, setVariantUnit] = useState('');
  const [menuModalVisible, setMenuModalVisible] = useState(false)
  const [bestsellersCount, setBestsellersCount] = useState(0);
  const [ifBestSeller, setIfBestSeller] = useState(false);
  const [variantFoodType, setVariantFoodType] = useState('');
  const [pdVariantLength, setPdVariantLength] = useState('');
  const [variantIsBestseller, setVariantIsBestseller] = useState(false);
  const [loginVisible, setLoginVisible] = useState(false);
  const [agreementCheck, setAgreementCheck] = useState(true);
  const [otpVisible, setOtpVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState(null);
  const [addressData, setAddressData] = useState([]);
  const [priorityAddress, setPriorityAddress] = useState('');
  const [showSelectAddress, setShowSelectAddress] = useState(false);
  const [logisticData, setLogisticData] = useState({});
  const [cartList, setCartList] = useState({});
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [showOrderSummary, setShowOrderSummary] = useState(false);
  const [cartDat, setCartDat] = useState([]);
  const [orderStatus, setOrderStatus] = useState({});
  const [otpVerified, setOtpVerified] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [catalogLoader, setCatalogLoader] = useState(false);
  const [currentLat, setCurrentLat] = useState(null);
  const [currentLng, setCurrentLng] = useState(null);
  const [showDownloadBanner, setShowDownloadBanner] = useState(true);
  const [linkOpening, setLinkOpening] = useState(false);
  const [customInstructions, setCustomInstructions] = useState(['']);
  const [infoPopUp, setInfoPopUp] = useState(false);
  const [s1CategoryList, setS1CategoryList] = useState([]);
  const [selectedS1, setSelectedS1] = useState({
    category: '',
    categoryId: ''
  });
  const [cartData, setCartData] = useState([]);
  const [instructionFromCart, setInstructionFromCart] = useState('');
  const [offerBanner, setOfferBanner] = useState(userOfferBanner);

  const urls = window.location.href;
  const aliasname1 = urls.substring(urls.lastIndexOf("/") + 1);
  const alias = aliasname1.split("#");
  const aliasname2 = alias[0].split("?");
  var url = new URL(urls);
  var userApp = url.searchParams.get("isUserApp");
  var mApp = url.searchParams.get('ismApp')
  const aliasname = aliasname2[0];
  const s1CategoryRef = useRef(null);
  // const s1CategoryRefGrid1 = useRef(null);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Merchant Website Clicked',
      'merchant_id': merchId,
      'shop_name': businessName
    });
    ReactGA.event({
      action: 'Merchant Website Clicked',
      label: 'Website Clicked',
      dimension1: merchId,
      dimension2: businessName
    });
  }, [])

  useEffect(() => {
    setCartCount(totalCount);
  }, [totalCount]);

  useEffect(() => {
    // opening cart based on value change from Homepage
    if (cartVisible) {
      if (!orderPlaced) {
        setCartVisible(false);
        if (localStorage.getItem("User")) {
          handleviewcart();
        } else {
          setLoginVisible(true);
        }
      }
    }
  }, [cartVisible])

  useEffect(() => {  // automatic page refresh to update status when coming from payment link
    const handlePageRefresh = () => {
      if (!document.hidden) {
        window.location.reload();
      }
    };

    document.addEventListener('visibilitychange', handlePageRefresh);

    return () => {
      document.removeEventListener('visibilitychange', handlePageRefresh);
    };
  }, []);

  const searchCatalogue = async (input) => { // api call for product search
    setCatalogLoader(true);
    const data = {
      merchantId: merchId,
      searchKeyword: input,
      available: true
    }
    try {
      const result = await ApiService.searchMerchantCatalogue(data);
      if (result.data.code === 0) {
        setBestSeller(result.data.data)
      } else if (result.data.message === 'No Data Available') {
        setBestSeller([]);
      }
    } catch (error) {
      alert(error.message)
    } finally {
      setCatalogLoader(false);
    }
  }

  const seachHandler = (input) => {
    handler(input)
  }

  const handler = useCallback(debounce((input) => {
    if (input === '') {
      getS1Categories();
    } else {
      searchCatalogue(input)
    }
  }, 1000), [])

  useEffect(() => {  // handling search when search input changes
    // if (searchInput !== '') {
    let timeoutId;

    const handleSearch = () => {
      seachHandler(searchInput)
      // searchCatalogue function here
      // searchCatalogue()
    };

    // Clear previous timeout (if any) when searchInput changes
    clearTimeout(timeoutId);

    // Set a new timeout for 300 milliseconds
    timeoutId = setTimeout(() => {
      handleSearch();
    }, 300);

    return () => {
      // Cleanup: clear the timeout when the component unmounts or when searchInput changes
      clearTimeout(timeoutId);
    };
    // } else {
    //   getS1Categories()
    // }

  }, [searchInput]);

  useEffect(() => {
    if (history.location.hash == "") {
      setModalVisible(false);
      setViewCartVisible(false);
      setProductDetailVisible(false);
    }
  }, [history.location.hash]);

  const getS1Categories = async () => {  // fetch s1 categories
    setCatalogLoader(true);
    const data = {
      merchantID: merchId
    }
    try {
      const result = await ApiService.getMerchantS1Categories(data);
      if (result.data.code === 0) {
        if (menuOption === 1 || menuOption === 2) {
          setIsLoading(true);
          setTimeout(() => {
            setIsLoading(false);
            scrollToS1Category();
            setMenuOption(0)
          }, 2000)
        }

        if (gridType === 1) {
          const updatedCategories = result.data.s1Categories.map((category, index) => {
            return {
              ...category,
              show: index === 0 ? true : false,
              apiCalled: index === 0 ? true : false
            };
          });
          setS1CategoryList(updatedCategories);
          setBestSeller(result.data.data);
        } else {
          setS1CategoryList(result.data.s1Categories);
          setBestSeller(result.data.data)
          setSelectedS1({ category: result.data.s1Categories[0].category })
        }


      } else {
        console.log("Data fetch failed")
      }
    } catch (error) {
      alert(error.message)
    } finally {
      setCatalogLoader(false);
    }
  }

  useEffect(() => {
    getS1Categories()
  }, [])

  // Showing products for Menu option clicks
  useEffect(() => {
    if (menuOption === 1) {
      if (s1CategoryList.length !== 0) {
        if (s1CategoryList.some(item => item.category === 'Best Seller')) {
          if (gridType !== 1) {
            handleS1CategoryClick(s1CategoryList[0].category, s1CategoryList[0].categoryId)
            setMenuOption(0)
            setIsLoading(true);
            setTimeout(() => {
              setIsLoading(false);
              scrollToS1Category();
            }, 2000)
          }
        }
      } else {
        // setIsLoading(true);
        // setTimeout(() => {
        //   setIsLoading(false);
        //   scrollToS1Category();
        //   setMenuOption(0)
        // }, 2000)
      }
    } else if (menuOption === 2) {
      setIsLoading(true);
      if (gridType !== 1) {
        setTimeout(() => {
          setIsLoading(false);
          scrollToS1Category();
          setMenuOption(0)
        }, 2000)
      } else {
        setTimeout(() => {
          setIsLoading(false);
          scrollToS1Category();
          setMenuOption(0)
        }, 2000)
      }

    }
  }, [menuOption])


  useEffect(() => {  // updating the modificationdata state whenever products are changed
    var filteredArray = bestSeller
    // && bestSeller.filter(function (el) { // filtering out unavailable products
    //   return el.status === 0;
    // });
    // filteredArray = filteredArray.sort(function (a, b) {
    //   if (a.id < b.id) {
    //     return 1;
    //   }
    //   if (a.id > b.id) {
    //     return -1;
    //   }
    //   return 0;
    // });

    const transformed =
      filteredArray &&
      filteredArray.map(({ id, country, desc, image_url, item_name, rate, unit, count, offer, discounted_rate, foodType, variants, category, bestseller, brandName, product_available, avail_msg, status }) => ({
        id: id,
        country: country,
        item_name: item_name,
        desc: desc,
        image_url: image_url,
        price: rate,
        unit: unit,
        count: 0,
        instruction: "",
        offer: offer,
        discounted_rate: discounted_rate,
        foodType: foodType,
        variants: variants,
        s1category: category,
        bestseller: bestseller,
        brandName: brandName,
        product_available: product_available,
        avail_msg: avail_msg,
        status: status

      }));

    for (let i = 0; i < transformed.length; i++) {
      if (transformed[i].variants !== undefined && transformed[i].variants !== null && transformed[i].variants !== "" && transformed[i].variants.length !== 0) {
        let data = {
          // count: 0,
          id: transformed[i].id,
          instruction: '',
          variantDisPrice: transformed[i].discounted_rate,
          variantName: transformed[i].item_name,
          variantPrice: transformed[i].price,
          varientId: uuidv4(),
          // instruction: '',
        }
        transformed[i].variants.unshift(data);
        for (let j = 0; j < transformed[i].variants.length; j++) {
          transformed[i].variants[j].count = 0
          transformed[i].variants[j].instruction = ""
          transformed[i].variants[j].instruction = ""
        }
      }
    }

    // sorting in alphabetical order
    const datass =
      transformed &&
      transformed.sort(function (a, b) {
        if (a.item_name < b.item_name) {
          return 1;
        }
        if (a.item_name > b.item_name) {
          return -1;
        }
        return 0;
      });

    const dat = datass && datass.sort(function (a, b) {
      if ((a.discounted_rate !== undefined || a.discounted_rate !== null || a.discounted_rate !== "") && (b.discounted_rate === undefined || b.discounted_rate === null || b.discounted_rate === "")) {
        return -1
      }
      if ((a.discounted_rate === undefined || a.discounted_rate === null || a.discounted_rate === "") && (b.discounted_rate !== undefined || b.discounted_rate !== null || b.discounted_rate !== "")) {
        return 1
      }
      if ((a.discounted_rate !== undefined || a.discounted_rate !== null || a.discounted_rate !== "") && (b.discounted_rate !== undefined || b.discounted_rate !== null || b.discounted_rate !== "")) {
        if (a.item_name < b.item_name) {
          return -1;
        }
        if (a.item_name > b.item_name) {
          return 1;
        }
      }
      if ((a.discounted_rate === undefined || a.discounted_rate === null || a.discounted_rate === "") && (b.discounted_rate === undefined || b.discounted_rate === null || b.discounted_rate === "")) {
        if (a.item_name < b.item_name) {
          return -1;

        }
        if (a.item_name > b.item_name) {
          return 1;
        }
      }
      return 0
    })

    const cart = (JSON.parse(localStorage.getItem("cartData")));
    if (cart) {
      // comparing cart array with main array to update count
      const updatedArray = dat.map((item1) => {
        const matchingItem = cart.find((item2) => item1.id === item2.id);
        console.log("MatchItem", matchingItem)
        if (matchingItem) {
          return matchingItem;
        }

        return item1;
      });
      setModificationData(updatedArray);
      setCartData(cart);
    } else {
      setModificationData(dat);
    }

    // Calculations for cart
    if (cart) {
      let filterData = cart.filter((data) => data.variants === undefined || data.variants === null || data.variants === "" || data.variants.length === 0)
      let countsum = filterData.map((data) => data.count);
      let varcountsum = [];
      for (let i = 0; i < cart.length; i++) {
        if (cart[i].variants && cart[i].variants.length !== 0) {
          for (let j = 0; j < cart[i].variants.length; j++) {
            varcountsum.push(cart[i].variants[j].count)
          }
        }

      }
      var varSumcount = varcountsum.reduce((partialSum, a) => partialSum + a, 0);
      var Sumcount = countsum.reduce((partialSum, a) => partialSum + a, 0);
      Sumcount = Sumcount + varSumcount;
      console.log("Sumcount", Sumcount);
      setTotalCount(Sumcount);
      let varPrice = [];
      let countprice = filterData.map((data) => (data.count > 0 ? ((data.discounted_rate !== null && data.discounted_rate !== "" && data.discounted_rate !== undefined) ? data.count * parseFloat(data.discounted_rate) : data.count * parseFloat(data.price)) : 0));
      console.log("countprice", countprice);
      for (let i = 0; i < cart.length; i++) {
        if (cart[i].variants && cart[i].variants.length !== 0) {
          for (let j = 0; j < cart[i].variants.length; j++) {
            if (cart[i].variants[j].count > 0) {
              if (cart[i].variants[j].variantDisPrice !== undefined && cart[i].variants[j].variantDisPrice !== null && cart[i].variants[j].variantDisPrice !== '') {
                varPrice.push(cart[i].variants[j].count * parseFloat(cart[i].variants[j].variantDisPrice))
              } else {
                varPrice.push(cart[i].variants[j].count * parseFloat(cart[i].variants[j].variantPrice))
              }
            }
          }
        }

      }
      var otherPrice = countprice.reduce((partialSum, a) => partialSum + a, 0);
      var ttPrice = varPrice.reduce((partialSum, a) => partialSum + a, 0);
      var Sumprice = otherPrice + ttPrice;
      console.log("Sumprice", Sumprice);
      setTotalPrice(Sumprice);

      let oldcountprice = filterData.map((data) => (data.count > 0 ? data.count * parseFloat(data.price) : 0));
      var oldSumprice = oldcountprice.reduce((partialSum, a) => partialSum + a, 0);
      let oldvarcountprice = [];
      for (let i = 0; i < cart.length; i++) {
        if (cart[i].variants && cart[i].variants.length !== 0) {
          for (let j = 0; j < cart[i].variants.length; j++) {
            let data = (cart[i].variants[j])
            oldvarcountprice.push(data.count > 0 ? (data.count > 0 ? data.count * parseFloat(data.variantPrice) : 0) : 0)
          }
        }

      }
      var oldvarSumprice = oldvarcountprice.reduce((partialSum, a) => partialSum + a, 0);
      oldSumprice = oldSumprice + oldvarSumprice;
      setOldTotalPrice(oldSumprice)
      setSavings(oldSumprice - Sumprice);
      if (Sumcount > 0) {  // To not display app download banner when something added to cart
        setShowDownloadBanner(false);
      }
    }


    const bestsellersData = dat.filter(data => data.bestseller === 0)
    setBestsellersCount(bestsellersData.length)

    let addressUpdated = localStorage.getItem("addressUpdated");
    if (addressUpdated !== null && addressUpdated === '1') {
      console.log("THISSUM", Sumprice)
      getaddressesAndLogistics(Sumprice);
      localStorage.removeItem("addressUpdated");
    } else {
      getaddresses();
    }

  }, [bestSeller]);

  useEffect(() => {
    if (localStorage.getItem("User")) {
      getMerchUserChannel(); //api call to check for latest order
    } else {
      getModifiedData();
    }
  }, []);
  let instructionFromHomePage = null;

  const addCountForVariant = (count, id, parentId, value) => {
    setShowDownloadBanner(false);
    count = count + 1
    let cart = cartData;
    const parentIndex = modificationData.map((item) => item.id).indexOf(parentId);
    const varIndex = modificationData[parentIndex].variants.map((item) => item.varientId).indexOf(id);
    modificationData[parentIndex].variants[varIndex].count = count
    modificationData[parentIndex].count++
    const cartIndex = cart.map((item) => item.id).indexOf(parentId);
    if (cartIndex !== -1) {
      cart[cartIndex].count = modificationData[parentIndex].count
      cart[cartIndex].variants[varIndex].count = count
    } else {
      cart.push(modificationData[parentIndex])
    }
    setCartData(cart)
    localStorage.setItem("cartData", JSON.stringify(cart));
    setProductCount(productCount + 1)

    let filterdata = [...modificationData];
    setModificationData(filterdata);

    // localStorage.setItem("modifiedData", JSON.stringify(modificationData));

    //  Calulations
    let varcountsum = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          varcountsum.push(cartData[i].variants[j].count)
        }
      }

    }

    var varSumcount = varcountsum.reduce((partialSum, a) => partialSum + a, 0);
    let filterData = cartData.filter((data) => data.variants === undefined || data.variants === null || data.variants === "" || data.variants.length === 0)
    let countsum = filterData.map((data) => data.count);
    var Sumcount = countsum.reduce((partialSum, a) => partialSum + a, 0);
    Sumcount = Sumcount + varSumcount;
    setTotalCount(Sumcount);

    let varcountprice = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          let data = (cartData[i].variants[j])
          varcountprice.push(data.count > 0 ? ((data.variantDisPrice !== null && data.variantDisPrice !== "" && data.variantDisPrice !== undefined) ? data.count * parseFloat(data.variantDisPrice) : data.count * parseFloat(data.variantPrice)) : 0)
        }
      }

    }
    let countprice = filterData.map((data) => (data.count > 0 ? ((data.discounted_rate !== null && data.discounted_rate !== "" && data.discounted_rate !== undefined) ? data.count * parseFloat(data.discounted_rate) : data.count * parseFloat(data.price)) : 0));
    var varSumprice = varcountprice.reduce((partialSum, a) => partialSum + a, 0);
    var Sumprice = countprice.reduce((partialSum, a) => partialSum + a, 0);
    Sumprice = Sumprice + varSumprice;
    setTotalPrice(Sumprice);
    if (value && value === "fromCart") {
      getLogisticChargeFromCart(Sumprice);
    }

    let oldcountprice = filterData.map((data) => (data.count > 0 ? data.count * parseFloat(data.price) : 0));
    var oldSumprice = oldcountprice.reduce((partialSum, a) => partialSum + a, 0);
    let oldvarcountprice = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          let data = (cartData[i].variants[j])
          oldvarcountprice.push(data.count > 0 ? (data.count > 0 ? data.count * parseFloat(data.variantPrice) : 0) : 0)
        }
      }

    }
    var oldvarSumprice = oldvarcountprice.reduce((partialSum, a) => partialSum + a, 0);
    oldSumprice = oldSumprice + oldvarSumprice;
    setOldTotalPrice(oldSumprice)
    setSavings(oldSumprice - Sumprice);
  };

  const decreaseCountForVariant = (count, id, parentId, value) => {
    count = count - 1
    if (value && value === "fromCart") {

      let cart = cartData;
      const parentIndex = modificationData.map((item) => item.id).indexOf(parentId);
      console.log("THIS EE", modificationData[parentIndex])
      const varIndex = modificationData[parentIndex].variants.map((item) => item.varientId).indexOf(id);
      const cartIndex = cart.map((item) => item.id).indexOf(parentId);
      if (count === 0) {
        if (modificationData[parentIndex].count === 1) {
          cart.splice(cartIndex, 1);
          modificationData[parentIndex].count--
          modificationData[parentIndex].variants[varIndex].count--
        } else {
          modificationData[parentIndex].variants[varIndex].count = count
          modificationData[parentIndex].count--
        }
      } else {
        modificationData[parentIndex].variants[varIndex].count = count
        modificationData[parentIndex].count--
      }
      setCartData(cart)
      localStorage.setItem("cartData", JSON.stringify(cart));
    } else {
      let cart = cartData;
      const parentIndex = modificationData.map((item) => item.id).indexOf(parentId);
      const varIndex = modificationData[parentIndex].variants.map((item) => item.varientId).indexOf(id);
      const cartIndex = cart.map((item) => item.id).indexOf(parentId);
      if (cartIndex !== -1) {
        if (modificationData[parentIndex].count === 1) {
          cart.splice(cartIndex, 1);
          modificationData[parentIndex].count--
          modificationData[parentIndex].variants[varIndex].count--
        } else {
          console.log("modPar", modificationData[parentIndex].count)
          console.log("modVar", modificationData[parentIndex].variants[varIndex].count)
          cart[cartIndex].count = modificationData[parentIndex].count
          cart[cartIndex].variants[varIndex].count = count
          modificationData[parentIndex].count--
          // modificationData[parentIndex].variants[varIndex].count --

        }
      } else {
        cart.push(modificationData[parentIndex])
      }
      setCartData(cart)
      localStorage.setItem("cartData", JSON.stringify(cart));
    }

    setProductCount(productCount - 1)
    let filterdata = [...modificationData];
    setModificationData(filterdata);
    // localStorage.setItem("modifiedData", JSON.stringify(modificationData));

    //  Calulations
    let varcountsum = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          varcountsum.push(cartData[i].variants[j].count)
        }
      }

    }
    var varSumcount = varcountsum.reduce((partialSum, a) => partialSum + a, 0);
    let filterData = cartData.filter((data) => data.variants === undefined || data.variants === null || data.variants === "" || data.variants.length === 0)
    let countsum = filterData.map((data) => data.count);
    var Sumcount = countsum.reduce((partialSum, a) => partialSum + a, 0);
    Sumcount = Sumcount + varSumcount;
    setTotalCount(Sumcount);

    let varcountprice = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          let data = (cartData[i].variants[j])
          varcountprice.push(data.count > 0 ? ((data.variantDisPrice !== null && data.variantDisPrice !== "" && data.variantDisPrice !== undefined) ? data.count * parseFloat(data.variantDisPrice) : data.count * parseFloat(data.variantPrice)) : 0)
        }
      }

    }
    let countprice = filterData.map((data) => (data.count > 0 ? ((data.discounted_rate !== null && data.discounted_rate !== "" && data.discounted_rate !== undefined) ? data.count * parseFloat(data.discounted_rate) : data.count * parseFloat(data.price)) : 0));
    var varSumprice = varcountprice.reduce((partialSum, a) => partialSum + a, 0);
    var Sumprice = countprice.reduce((partialSum, a) => partialSum + a, 0);
    Sumprice = Sumprice + varSumprice;
    setTotalPrice(Sumprice);
    if (value && value === "fromCart") {
      getLogisticChargeFromCart(Sumprice);
    }

    let oldcountprice = filterData.map((data) => (data.count > 0 ? data.count * parseFloat(data.price) : 0));
    var oldSumprice = oldcountprice.reduce((partialSum, a) => partialSum + a, 0);
    let oldvarcountprice = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          let data = (cartData[i].variants[j])
          oldvarcountprice.push(data.count > 0 ? (data.count > 0 ? data.count * parseFloat(data.variantPrice) : 0) : 0)
        }
      }

    }
    var oldvarSumprice = oldvarcountprice.reduce((partialSum, a) => partialSum + a, 0);
    oldSumprice = oldSumprice + oldvarSumprice;
    setOldTotalPrice(oldSumprice)
    setSavings(oldSumprice - Sumprice);
  };

  const addCount = (count, id, value) => {
    setShowDownloadBanner(false);
    if (value === "fromCart")
      window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Add to Cart',
      'merchant_id': merchId,
      'shop_name': businessName
    });
    ReactGA.event({
      action: 'Add to Cart',
      label: 'Adding products to cart',
      dimension1: merchId,
      dimension2: businessName,
    });
    count = count + 1;
    if (value && value === "fromCart") {
      const index2 = cartData.map((item) => item.id).indexOf(id); // Found the object index

      cartData[index2].count = count;
      const modifiedIndex = modificationData.map((item) => item.id).indexOf(id);
      if (modifiedIndex !== -1) {
        modificationData[modifiedIndex].count = count
        let filterdata = [...modificationData];
        setModificationData(filterdata);
      }
      let cart = [...cartData]
      setCartData(cart)
      localStorage.setItem("cartData", JSON.stringify(cart));
      setProductCount(count);
    } else {
      const index2 = modificationData.map((item) => item.id).indexOf(id); // Found the object index

      modificationData[index2].count = count;
      let cart = cartData;
      const cartIndex = cart.map((item) => item.id).indexOf(id);
      if (cartIndex !== -1) {
        cart[cartIndex].count = count
      } else {
        cart.push(modificationData[index2])
      }
      setCartData(cart)
      localStorage.setItem("cartData", JSON.stringify(cart));
      setProductCount(count);

      let filterdata = [...modificationData];
      setModificationData(filterdata);
    }
    // localStorage.setItem("modifiedData", JSON.stringify(modificationData));

    //  Calulations
    let filterData = cartData.filter((data) => data.variants === undefined || data.variants === null || data.variants === "" || data.variants.length === 0)
    let countsum = filterData.map((data) => data.count);
    let varcountsum = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          varcountsum.push(cartData[i].variants[j].count)
        }
      }

    }
    var varSumcount = varcountsum.reduce((partialSum, a) => partialSum + a, 0);
    var Sumcount = countsum.reduce((partialSum, a) => partialSum + a, 0);
    Sumcount = Sumcount + varSumcount;
    setTotalCount(Sumcount);
    let varPrice = [];
    let countprice = filterData.map((data) => (data.count > 0 ? ((data.discounted_rate !== null && data.discounted_rate !== "" && data.discounted_rate !== undefined) ? data.count * parseFloat(data.discounted_rate) : (data.price === 0 ? 0 : data.count * parseFloat(data.price))) : 0));

    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          if (cartData[i].variants[j].count > 0) {
            if (cartData[i].variants[j].variantDisPrice !== undefined && cartData[i].variants[j].variantDisPrice !== null && cartData[i].variants[j].variantDisPrice !== '') {
              varPrice.push(cartData[i].variants[j].count * parseFloat(cartData[i].variants[j].variantDisPrice))
            } else {
              varPrice.push(cartData[i].variants[j].count * parseFloat(cartData[i].variants[j].variantPrice))
            }

          }
        }
      }

    }
    var otherPrice = countprice.reduce((partialSum, a) => partialSum + a, 0);
    var ttPrice = varPrice.reduce((partialSum, a) => partialSum + a, 0);
    var Sumprice = otherPrice + ttPrice;
    setTotalPrice(Sumprice);

    let oldcountprice = filterData.map((data) => (data.count > 0 ? data.count * parseFloat(data.price) : 0));
    var oldSumprice = oldcountprice.reduce((partialSum, a) => partialSum + a, 0);
    let oldvarcountprice = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          let data = (cartData[i].variants[j])
          oldvarcountprice.push(data.count > 0 ? (data.count > 0 ? data.count * parseFloat(data.variantPrice) : 0) : 0)
        }
      }

    }
    var oldvarSumprice = oldvarcountprice.reduce((partialSum, a) => partialSum + a, 0);
    oldSumprice = oldSumprice + oldvarSumprice;
    setOldTotalPrice(oldSumprice)
    setSavings(oldSumprice - Sumprice);
    if (value && value === "fromCart") {
      getLogisticChargeFromCart(Sumprice);
    }
  };

  const decreaseCount = (count, id, value) => {
    count = count - 1;
    if (value && value === "fromCart") {
      const index2 = cartData.map((item) => item.id).indexOf(id); // Found the object index
      if (count === 0) {
        cartData.splice(index2, 1);
      } else {
        cartData[index2].count = count;
      }
      const modifiedIndex = modificationData.map((item) => item.id).indexOf(id);
      if (modifiedIndex !== -1) {
        modificationData[modifiedIndex].count = count
        let filterdata = [...modificationData];
        setModificationData(filterdata);
      }
      let cart = [...cartData]
      setCartData(cart)
      localStorage.setItem("cartData", JSON.stringify(cart));
      setProductCount(count);
    } else {
      const index2 = modificationData.map((item) => item.id).indexOf(id); // Found the object index

      modificationData[index2].count = count;
      let cart = cartData;
      const cartIndex = cart.map((item) => item.id).indexOf(id);
      if (cartIndex !== -1) {
        if (count === 0) {
          cartData.splice(cartIndex, 1);
        } else {
          cart[cartIndex].count = count
        }
      } else {
        cart.push(modificationData[index2])
      }
      setCartData(cart)
      localStorage.setItem("cartData", JSON.stringify(cart));
      setProductCount(count);

      let filterdata = [...modificationData];
      setModificationData(filterdata);
    }

    //  Calulations
    let filterData = cartData.filter((data) => data.variants === undefined || data.variants === null || data.variants === "" || data.variants.length === 0)
    let countsum = filterData.map((data) => data.count);
    let varcountsum = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          varcountsum.push(cartData[i].variants[j].count)
        }
      }

    }
    var varSumcount = varcountsum.reduce((partialSum, a) => partialSum + a, 0);
    var Sumcount = countsum.reduce((partialSum, a) => partialSum + a, 0);
    Sumcount = Sumcount + varSumcount;
    setTotalCount(Sumcount);
    let varPrice = [];
    let countprice = filterData.map((data) => (data.count > 0 ? ((data.discounted_rate !== null && data.discounted_rate !== "" && data.discounted_rate !== undefined) ? data.count * parseFloat(data.discounted_rate) : data.count * parseFloat(data.price)) : 0));

    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          if (cartData[i].variants[j].count > 0) {
            if (cartData[i].variants[j].variantDisPrice !== undefined && cartData[i].variants[j].variantDisPrice !== null && cartData[i].variants[j].variantDisPrice !== '') {
              varPrice.push(cartData[i].variants[j].count * parseFloat(cartData[i].variants[j].variantDisPrice))
            } else {
              varPrice.push(cartData[i].variants[j].count * parseFloat(cartData[i].variants[j].variantPrice))
            }

          }
        }
      }

    }
    var otherPrice = countprice.reduce((partialSum, a) => partialSum + a, 0);
    var ttPrice = varPrice.reduce((partialSum, a) => partialSum + a, 0);
    var Sumprice = otherPrice + ttPrice;
    setTotalPrice(Sumprice);

    let oldcountprice = filterData.map((data) => (data.count > 0 ? data.count * parseFloat(data.price) : 0));
    var oldSumprice = oldcountprice.reduce((partialSum, a) => partialSum + a, 0);
    let oldvarcountprice = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          let data = (cartData[i].variants[j])
          oldvarcountprice.push(data.count > 0 ? (data.count > 0 ? data.count * parseFloat(data.variantPrice) : 0) : 0)
        }
      }

    }
    var oldvarSumprice = oldvarcountprice.reduce((partialSum, a) => partialSum + a, 0);
    oldSumprice = oldSumprice + oldvarSumprice;
    setOldTotalPrice(oldSumprice)
    setSavings(oldSumprice - Sumprice);
    if (value && value === "fromCart") {
      getLogisticChargeFromCart(Sumprice);
    }
  };

  const modalopen = (id, instruction, fromHomePage, fromCart) => {
    if (!window.location.hash) {
      window.location = window.location + "#instruction";
    }

    if (fromHomePage) {
      if (fromCart) {
        const cartIndex = cartData.map((item) => item.id).indexOf(id);
        setInstructionIndex(cartIndex);
        setInstructionFromCart(true);
      } else {
        const index2 = modificationData.map((item) => item.id).indexOf(id); // Found the object index
        setInstructionIndex(index2);
        setInstructionFromCart(false);
      }
      setInstructionFromHome(true);

    } else {
      if (fromCart) {
        for (let i = 0; i < cartData.length; i++) {
          if (cartData[i].variants && cartData[i].variants.length !== 0) {
            for (let j = 0; j < cartData[i].variants.length; j++) {
              let data = (cartData[i].variants[j])
              if (data.varientId === id) {
                setVariantIndex(j);
                setInstructionIndex(i)
                break;
              }
            }
          }
        }
        setInstructionFromCart(true);
      } else {
        for (let i = 0; i < modificationData.length; i++) {
          if (modificationData[i].variants && modificationData[i].variants.length !== 0) {
            for (let j = 0; j < modificationData[i].variants.length; j++) {
              let data = (modificationData[i].variants[j])
              if (data.varientId === id) {
                setVariantIndex(j);
                setInstructionIndex(i)
                break;
              }
            }
          }

        }
      }
      setInstructionFromHome(false);
      setInstructionFromCart(false);
    }
    setModalVisible(true);
    setInstruction(instruction);
  };

  const saveInstruction = () => {
    if (instructionFromHome) {
      if (instructionFromCart) {
        if (cartData[instructionIndex].variants && cartData[instructionIndex].variants.length !== 0) {
          cartData[instructionIndex].variants.map((variant => {
            variant.instruction = instruction;
          }))
        }
        cartData[instructionIndex].instruction = instruction;
        let filterdata = [...cartData];
        setCartData(filterdata);
        // localStorage.setItem("modifiedData", JSON.stringify(modificationData));
        setModalVisible(false);
      } else {
        if (modificationData[instructionIndex].variants && modificationData[instructionIndex].variants.length !== 0) {
          modificationData[instructionIndex].variants.map((variant => {
            variant.instruction = instruction;
          }))
        }
        modificationData[instructionIndex].instruction = instruction;
        let filterdata = [...modificationData];
        setModificationData(filterdata);
        // localStorage.setItem("modifiedData", JSON.stringify(modificationData));
        setModalVisible(false);
      }
    } else {
      if (instructionFromCart) {
        cartData[instructionIndex].variants[variantIndex].instruction = instruction;
        let filterdata = [...cartData];
        setCartData(filterdata);
        // localStorage.setItem("modifiedData", JSON.stringify(modificationData));
        setModalVisible(false);
      } else {
        // modificationData[instructionIndex].variants[variantIndex].instruction = instruction;
        // let filterdata = [...modificationData];
        // setModificationData(filterdata);
        cartData[instructionIndex].variants[variantIndex].instruction = instruction;
        let cart = [...cartData];
        setCartData(cart);
        // localStorage.setItem("modifiedData", JSON.stringify(modificationData));
        setModalVisible(false);
      }

    }


  };

  const handleVariantsButton = (data) => {
    if (!window.location.hash) {
      window.location = window.location + "#variants";
    }

    setVariantsVisible(true);
    setVariants(data.variants);
    if (data.foodType) {
      setVariantFoodType(data.foodType)
    } else {
      setVariantFoodType('none')
    }
    if (data.unit) {
      setVariantUnit(data.unit)
    }
    if (data.bestseller === 0) {
      setVariantIsBestseller(true)
    } else {
      setVariantIsBestseller(false)
    }

  }

  const makeCartData = () => {
    let cart = [];
    let cartObj = {}
    cartData.filter((modData) => modData.count > 0)
      .map((data) => {
        if (data.variants && data.variants.length !== 0) {
          if (data.variants[0].count > 0) {
            cartObj = {
              id: data.id,
              image_url: data.image_url,
              item_name: data.variants[0].variantName,
              count: data.variants[0].count,
              rate: data.variants[0].variantPrice,
              discounted_rate: data.variants[0].variantDisPrice ? data.variants[0].variantDisPrice : '',
              instructions: data.variants[0].instruction ? data.variants[0].instruction : '',
              inStock: 0,
            }
            cart.push(cartObj);
          }
          for (let i = 1; i < data.variants.length; i++) {
            if (data.variants[i].count > 0) {
              cartObj = {
                id: data.variants[i].varientId,
                image_url: data.image_url,
                item_name: data.variants[i].variantName,
                count: data.variants[i].count,
                rate: data.variants[i].variantPrice,
                discounted_rate: data.variants[i].variantDisPrice ? data.variants[i].variantDisPrice : '',
                instructions: data.variants[i].instruction ? data.variants[i].instruction : '',
                inStock: 0,
              }
              cart.push(cartObj)
            }
          }
        } else {
          cartObj = {
            id: data.id,
            image_url: data.image_url,
            item_name: data.item_name,
            count: data.count,
            rate: data.price,
            discounted_rate: data.discounted_rate ? data.discounted_rate : '',
            instructions: data.instruction ? data.instruction : '',
            inStock: 0,
          }
          cart.push(cartObj);
        }
      })
    setCartList(cart);
    return (cart)


  }

  const handleviewcart = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'View Cart',
      'merchant_id': merchId,
      'shop_name': businessName
    });
    ReactGA.event({
      action: 'View Cart',
      label: 'Viewing cart items',
      dimension1: merchId,
      dimension2: businessName,
    });
    setViewCartVisible(true);
    getLogisticCharge();
  };

  const handleviewproductdetail = (data) => {
    console.log("ClickedData", data)
    if (!window.location.hash) {
      window.location = window.location + "#product";
    }
    setProductId(data.id);
    let arr = []
    if (Array.isArray(data.image_url)) {
      setProductImage(data.image_url);
    } else {
      arr.push(data.image_url)
      setProductImage(arr)
    }

    if (data.bestseller !== null && data.bestseller !== undefined && data.bestseller !== '' && data.bestseller === 0) {
      setIfBestSeller(true);
    } else {
      setIfBestSeller(false);
    }

    setProductName(data.item_name);
    setProductPrice(data.price);
    if (data.brandName) {
      setPdBrand(data.brandName);
    } else {
      setPdBrand("");
    }

    if (data.discounted_rate === null || data.discounted_rate === undefined || data.discounted_rate === "") {
      setProductDiscPrice("");
      setPdOffer("")
    } else {
      setProductDiscPrice(data.discounted_rate);
      let offer_temp = '';
      offer_temp = 100 - (data.discounted_rate / data.price) * 100;
      setPdOffer(Math.round(offer_temp));
    }
    setProductCount(data.count);
    setProductUnit(data.unit);
    setProductDesc(data.desc);
    if (data.product_available === 1) {
      setProductAvailabilityData(data.avail_msg);
    } else {
      setProductAvailabilityData('');
    }

    console.log(data.desc);
    setProductDetailVisible(true);
    if (data.variants && data.variants.length !== 0) {
      setProductVariantExists(true)
      setVarDataFromPd(data)
      setPdVariantLength(data.variants.length)
    } else {
      setProductVariantExists(false)
    }
  };

  const handlesendoder = () => {
    const mid = 'MJwjteKs18BJ42Kmvafr-x';

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'send_order',
      'merchant_id': merchId,
      'shop_name': businessName
    });

    ReactGA.event({
      action: 'Send Order',
      label: 'Website Orders',
      dimension1: merchId,
      dimension2: businessName
    });

    console.log("handleSendOrder", modificationData)

    let del = modificationData
      .filter((modificationData) => modificationData.count > 0 && (modificationData.variants === undefined || modificationData.variants === null || modificationData.variants === "" || modificationData.variants.length === 0))

      .map((data) => {

        let unit = data.unit.replace("/", "");
        unit = unit.charAt(0).toUpperCase() + unit.slice(1);
        if (data.instruction == "") {
          if (isFruitsAndVegMerchant === "FRUITS" || isFruitsAndVegMerchant === "GROCERY") {
            let check = data.unit.replace("/", "");
            check = check.split(/([0-9]+)/);
            let count = "";
            let unitcheck = "";
            if (check.length == 3) {
              count = parseInt(check[1]) * parseInt(data.count);
              if (count >= 1000) {
                count = count / 1000;
                unitcheck = "kg";
              } else {
                unitcheck = check[2].charAt(0).toUpperCase() + check[2].slice(1);
              }
            } else {
              count = data.count;
              unitcheck = check[0].charAt(0).toUpperCase() + check[0].slice(1);
            }
            return data.item_name + `%20${data.desc ? "(" + data.desc + ")" : ""}%20%20` + count + "%20" + unitcheck;
          } else {
            return data.count + "%20%78%20" + data.item_name + `${data.desc ? "(" + data.desc + ")" : ""}%20%20` + unit;
          }
        } else {
          if (isFruitsAndVegMerchant === "FRUITS") {
            let check = data.unit.replace("/", "");
            check = check.split(/([0-9]+)/);
            let count = "";
            let unitcheck = "";
            if (check.length == 3) {
              count = parseInt(check[1]) * parseInt(data.count);
              if (count >= 1000) {
                count = count / 1000;
                unitcheck = "kg";
              } else {
                unitcheck = check[2].charAt(0).toUpperCase() + check[2].slice(1);
              }
            } else {
              count = data.count;
              unitcheck = check[0].charAt(0).toUpperCase() + check[0].slice(1);
            }

            return data.item_name + `${data.desc ? "(" + data.desc + ")" : ""}%20%20` + count + "%20" + unitcheck + "%20(" + data.instruction + ")";
          } else {
            return data.count + "%20%78%20" + data.item_name + `${data.desc ? "(" + data.desc + ")" : ""}%20` + unit + "%20(" + data.instruction + ")";
          }
        }
      })
      .join("%0a");
    del = del.replace("&", "%26");

    for (let i = 0; i < modificationData.length; i++) {
      if (modificationData[i].count > 0) {
        if (modificationData[i].variants !== undefined && modificationData[i].variants !== null && modificationData[i].variants !== '' && modificationData[i].variants.length !== 0) {
          for (let j = 0; j < modificationData[i].variants.length; j++) {
            if (modificationData[i].variants[j].count > 0) {
              if (modificationData[i].variants[j].instruction !== "") {
                del = del + "%0a" + modificationData[i].variants[j].count + "%20" + '%78' + "%20" + modificationData[i].variants[j].variantName + "%20%20" + `${(modificationData[i].variants[j].desc !== "" && modificationData[i].variants[j].desc !== null && modificationData[i].variants[j].desc !== undefined) ? "(" + modificationData[i].variants[j].desc + ")" : ""}` + "(" + modificationData[i].variants[j].instruction + ")";
              } else {
                del = del + "%0a" + modificationData[i].variants[j].count + "%20" + '%78' + "%20" + modificationData[i].variants[j].variantName + "%20%20" + `${(modificationData[i].variants[j].desc !== "" && modificationData[i].variants[j].desc !== null && modificationData[i].variants[j].desc !== undefined) ? "(" + modificationData[i].variants[j].desc + ")" : ""}`;
              }
            }
          }
        }
      }
    }

    if (customInstructions.length > 1) {
      let str = '%0a%0aMore Items';
      for (let i = 0; i < customInstructions.length - 1; i++) {
        str = str + "%0a" + customInstructions[i];
      }
      del = del + str;
    }

    console.log(del);
    if (window.ReactNativeWebView && userApp != null) { // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
      window.ReactNativeWebView.postMessage(`https://api.whatsapp.com/send?phone=${PhoneNumber}&text=${del}`)
    } else {
      window.open(`https://api.whatsapp.com/send?phone=919289454472&text=Website order for ${aliasname}%0a%0a${del}`);
    }
  };
  const currency = (num) => {
    const hasFractionalPart = num % 1 !== 0;
    const fractionDigits = hasFractionalPart ? 1 : 0;
    const curr = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits
    })
      .format(num)
      .replace(/(\.|,)00$/g, "");
    return curr;

  };

  const handlewhatsapp = () => {
    if (window.ReactNativeWebView && userApp != null) { // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
      window.ReactNativeWebView.postMessage(`https://api.whatsapp.com/send?phone=${PhoneNumber}`)
    } else {
      window.open(
        `https://api.whatsapp.com/send?phone=919289454472&text=Website order for ${aliasname}`
      );
    }
  };

  const getS1CategoryProductsForGrid1 = async (category) => {
    setIsLoading(true);
    const data = {
      merchantID: merchId,
      category: category.category,
      categoryId: category.categoryId
    }
    try {
      const result = await ApiService.getS1CategoriesProducts(data);
      if (result.data.code === 0) {
        const newArrayToAdd = result.data.data;
        const filteredNewArray = newArrayToAdd.filter(
          (newItem) => !bestSeller.some((existingItem) => existingItem.id === newItem.id)
        );
        setBestSeller([...bestSeller, ...filteredNewArray]);
        let list = [];
        s1CategoryList.filter((dat) => dat.category === category.category)
          .map((d => {
            d.show = !d.show;
            d.apiCalled = true;
          }))
        list = [...s1CategoryList]
        setS1CategoryList(list);
      } else {
        console.log("Data fetch failed")
      }
    } catch (error) {
      alert(error.message)
    } finally {
      setIsLoading(false);
    }
  }

  const openCategory = (category) => {
    let apiCalled = false;
    s1CategoryList.filter((dat) => dat.category === category.category)
      .map((d => {
        if (d.apiCalled === true) {
          d.show = !d.show
          apiCalled = true;
        }
      }))
    if (apiCalled) {
      let list = [...s1CategoryList];
      setS1CategoryList(list);
    } else {
      getS1CategoryProductsForGrid1(category);
    }
  }

  function scrollToElement(id) {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  const gotoMenuItem = (index) => {
    scrollToElement(index);
    if (s1CategoryList[index].show === false) {
      openCategory(s1CategoryList[index]);
    }
    setMenuModalVisible(false);
  }

  const handleShare = () => {
    let merUrl = window.location.href;
    const hashIndex = merUrl.indexOf("#");
    const trimmedUrl = hashIndex === -1 ? merUrl : merUrl.substring(0, hashIndex);
    if (navigator.share) {
      navigator.share({
        text: `Hey, I found this store on ChattyBao: \n${businessName} \n`,
        url: trimmedUrl
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.error('Error sharing link:', error))
    } else {
      console.log('Web Share API not supported');
    }
  };

  const getaddresses = async () => {
    const token = localStorage.getItem('sessionId')
    await ApiService.getaddresses(token)
      .then(async result => {

        // console.log('order list====>', result)
        if (result.data.message === 'Invalid Token') {
          console.log('logout called')
        }
        if (result.data.code === 0) {
          const arr = []
          let prAddress = {}
          for (let i = 0; i < result.data.data.length; i++) {
            if (result.data.data[i].sequence !== 0) {
              arr.push(result.data.data[i])
              if (result.data.data[i].sequence === Number(result.data.priority)) {
                setCurrentLat(result.data.data[i].latitude);
                setCurrentLng(result.data.data[i].longitude);
                if (result.data.data[i].label !== '' && result.data.data[i].label !== 'null') {
                  if (result.data.data[i].label !== 'Others') {
                    prAddress = {
                      label: result.data.data[i].label,
                      address: (result.data.data[i].address_line1 !== '' ? result.data.data[i].address_line1 + ', ' : '') + (result.data.data[i].apartment_or_area !== '' ? result.data.data[i].apartment_or_area + ', ' : '') + (result.data.data[i].address_line2 !== '' ? result.data.data[i].address_line2 : '')
                    }
                  } else {
                    prAddress = {
                      label: result.data.data[i].label,
                      address: (result.data.data[i].address_line1 !== '' ? result.data.data[i].address_line1 + ', ' : '') + (result.data.data[i].apartment_or_area !== '' ? result.data.data[i].apartment_or_area + ', ' : '') + (result.data.data[i].address_line2 !== '' ? result.data.data[i].address_line2 : '')
                    }
                  }
                } else if (result.data.data[i].city !== 'null' && result.data.data[i].city !== '') {
                  prAddress = {
                    label: result.data.data[i].city,
                    address: (result.data.data[i].address_line1 !== '' ? result.data.data[i].address_line1 + ', ' : '') + (result.data.data[i].apartment_or_area !== '' ? result.data.data[i].apartment_or_area + ', ' : '') + (result.data.data[i].address_line2 !== '' ? result.data.data[i].address_line2 : '')
                  }
                } else {
                  prAddress = {
                    label: "Others",
                    address: (result.data.data[i].address_line1 !== '' ? result.data.data[i].address_line1 + ', ' : '') + (result.data.data[i].apartment_or_area !== '' ? result.data.data[i].apartment_or_area + ', ' : '') + (result.data.data[i].address_line2 !== '' ? result.data.data[i].address_line2 : '')
                  }
                }
                setPriorityAddress(prAddress)
                console.log("prADDr", prAddress)
              } else if (Number(result.data.priority) === 0 && result.data.data.length > 1) {
                setPriorityAddress('currentLocation')
              }
            }

          }
          console.log(result)
          let tempArr = arr.sort((a, b) => b.sequence - a.sequence);
          setAddressData(tempArr)
        } else if (result.message === 'Network Error') {

        }

      })
  }

  const getaddressesAndLogistics = async (value) => {
    setIsLoading(true);
    const token = localStorage.getItem('sessionId')
    await ApiService.getaddresses(token)
      .then(async result => {
        setIsLoading(false);
        let lat = null;
        let lng = null;

        // console.log('order list====>', result)
        if (result.data.message === 'Invalid Token') {
          console.log('logout called')
        }
        if (result.data.code === 0) {
          const arr = []
          let prAddress = {}
          for (let i = 0; i < result.data.data.length; i++) {
            if (result.data.data[i].sequence !== 0) {
              arr.push(result.data.data[i])
              if (result.data.data[i].sequence === Number(result.data.priority)) {
                setCurrentLat(result.data.data[i].latitude);
                setCurrentLng(result.data.data[i].longitude);
                lat = result.data.data[i].latitude;
                lng = result.data.data[i].longitude;
                if (result.data.data[i].label !== '' && result.data.data[i].label !== 'null') {
                  if (result.data.data[i].label !== 'Others') {
                    prAddress = {
                      label: result.data.data[i].label,
                      address: (result.data.data[i].address_line1 !== '' ? result.data.data[i].address_line1 + ', ' : '') + (result.data.data[i].apartment_or_area !== '' ? result.data.data[i].apartment_or_area + ', ' : '') + (result.data.data[i].address_line2 !== '' ? result.data.data[i].address_line2 : '')
                    }
                  } else {
                    prAddress = {
                      label: result.data.data[i].label,
                      address: (result.data.data[i].address_line1 !== '' ? result.data.data[i].address_line1 + ', ' : '') + (result.data.data[i].apartment_or_area !== '' ? result.data.data[i].apartment_or_area + ', ' : '') + (result.data.data[i].address_line2 !== '' ? result.data.data[i].address_line2 : '')
                    }
                  }
                } else if (result.data.data[i].city !== 'null' && result.data.data[i].city !== '') {
                  prAddress = {
                    label: result.data.data[i].city,
                    address: (result.data.data[i].address_line1 !== '' ? result.data.data[i].address_line1 + ', ' : '') + (result.data.data[i].apartment_or_area !== '' ? result.data.data[i].apartment_or_area + ', ' : '') + (result.data.data[i].address_line2 !== '' ? result.data.data[i].address_line2 : '')
                  }
                } else {
                  prAddress = {
                    label: "Others",
                    address: (result.data.data[i].address_line1 !== '' ? result.data.data[i].address_line1 + ', ' : '') + (result.data.data[i].apartment_or_area !== '' ? result.data.data[i].apartment_or_area + ', ' : '') + (result.data.data[i].address_line2 !== '' ? result.data.data[i].address_line2 : '')
                  }
                }
                setPriorityAddress(prAddress)
                console.log("prADDr", prAddress)
              } else if (Number(result.data.priority) === 0 && result.data.data.length > 1) {
                setPriorityAddress('currentLocation')
              }
            }

          }
          console.log(result)
          let tempArr = arr.sort((a, b) => b.sequence - a.sequence);
          setAddressData(tempArr)
          let logValue = {}
          if (value) {
            logValue = {
              lat: lat,
              lng: lng,
              val: value
            }
          } else {
            logValue = {
              lat: lat,
              lng: lng,
              val: totalPrice
            }
          }

          setViewCartVisible(true);
          console.log("LogValue==>", logValue)
          getLogisticCharge(logValue)
        } else if (result.message === 'Network Error') {
          setIsLoading(false);

        }

      })
  }

  const getLogisticCharge = async (value) => {
    setIsLoading(true);
    let data = {}
    if (value) {
      data = {
        merchant_mob_no: PhoneNumber,
        order_amount: value.val ? value.val : 0,
        ulatitude: value.lat,
        ulongitude: value.lng,
        eligibility_of_checkout: isCheckout
      }
    } else {
      data = {
        merchant_mob_no: PhoneNumber,
        order_amount: totalPrice !== "" ? totalPrice : 0,
        ulatitude: currentLat,
        ulongitude: currentLng,
        eligibility_of_checkout: isCheckout
      }
    }
    const token = localStorage.getItem('sessionId')

    await ApiService.getUserLogisticCharge(data, token)
      .then(async result => {
        console.log('data======> ', '  ' + (result.data))
        setIsLoading(false)
        if (result.data.code === 0) {
          let data = {}
          data = {
            deliveryCharge: result.data.data.totalLogistic,
            govtTax: result.data.data.govt_charge,
            payAmount: result.data.data.amtToPay,
            cashback: result.data.data.userOffer,
            cgst: result.data.data.cgstOnLogistic,
            sgst: result.data.data.sgstOnLogistic,
            logisticCharge: result.data.data.logisticCharge,
            distance: result.data.data.distanceInKM,
            deliveryMsg: result.data.data.btnMsg,
            orderMsg: result.data.data.orderMsg,
            userOffer: result.data.data.userOffer ? result.data.data.userOffer : 0,
            nightChargeMsg: result.data.data.nightChargeMsg,
            nightCharges: result.data.data.nightCharges
          }
          setLogisticData(data);
        } else if (result.data.message === 'Network Error') {
          // Network error
        }
      })
      .catch(err => { console.log(err); setIsLoading(false); })
  }

  const getLogisticChargeFromCart = async (value) => {
    setIsLoading(true)
    let orderAmount = value;
    const token = localStorage.getItem('sessionId')
    const headers = {
      token
    }
    const data = {
      merchant_mob_no: PhoneNumber,
      order_amount: orderAmount,
      ulatitude: currentLat,
      ulongitude: currentLng,
      eligibility_of_checkout: isCheckout
    }
    await ApiService.getUserLogisticCharge(data, token)
      .then(async result => {
        console.log('data======> ', '  ' + (result.data))
        setIsLoading(false)
        if (result.data.code === 0) {
          let data = {}
          data = {
            deliveryCharge: result.data.data.totalLogistic,
            govtTax: result.data.data.govt_charge,
            payAmount: result.data.data.amtToPay,
            cashback: result.data.data.userOffer,
            cgst: result.data.data.cgstOnLogistic,
            sgst: result.data.data.sgstOnLogistic,
            logisticCharge: result.data.data.logisticCharge,
            distance: result.data.data.distanceInKM,
            deliveryMsg: result.data.data.btnMsg,
            orderMsg: result.data.data.orderMsg,
            userOffer: result.data.data.userOffer ? result.data.data.userOffer : 0,
            nightChargeMsg: result.data.data.nightChargeMsg,
            nightCharges: result.data.data.nightCharges
          }
          setLogisticData(data);
        } else if (result.message === 'Network Error') {
          // Network error
        }
      })
      .catch(err => { console.log(err); setIsLoading(false); })
  }

  const paymentProcess = async (orderData) => {
    if (!linkOpening) {
      if (priorityAddress === '') {
        toast("Add an address to continue", { autoClose: 500 })
      } else if (priorityAddress === 'currentLocation') {
        toast("Select an address to continue", { autoClose: 500 })
      } else {
        setLoader(true);
        let cartData = makeCartData();
        const token = localStorage.getItem('sessionId')
        let customInstr = customInstructions;
        customInstr.pop();
        const data = {
          cart_list: cartData,
          merchant_mobile_no: PhoneNumber,
          full_amount: (oldTotalPrice >= totalPrice) ? parseFloat(oldTotalPrice).toFixed(2) : 0,
          total_amount: totalPrice,
          total_count: totalCount,
          logistic_charge: logisticData.logisticCharge,
          cgst_on_logistic: logisticData.cgst,
          sgst_on_logistic: logisticData.sgst,
          total_logistic: logisticData.deliveryCharge,
          amt_to_pay: logisticData.payAmount,
          govt_gst: logisticData.govtTax,
          is_from_app: 1,
          extra_cart_items: customInstr,
          latest_apk: 0,
          user_offer_amt: logisticData.userOffer,
          nightCharges: logisticData.nightCharges
        }
        console.log("paynow data", data)
        await ApiService.makePayment(data, token)
          .then(async result => {
            setLoader(false);
            if (result.data.code === 0) {
              localStorage.setItem("orderId", result.data.data.orderId);
              const url = result.data.data.paymentLink;
              window.location.href = url;
              setLinkOpening(true);
            }
          })
          .catch(err => { console.log(err); setLoader(false); toast("Something went wrong. Please try again.", { autoClose: 500 }) });
      }
    }


  }

  const generateRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier('recapcha-container', {
        'size': 'invisible',
        'callback': (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // onSignInSubmit();
          console.log(response);
        }
      }, authentication);
    }
  }

  const handleSubmit = (event) => {
    setLoader(true);
    event.preventDefault();
    const sendOtp = () => {

      generateRecaptcha();
      let appVerifier = window.recaptchaVerifier;
      var phoneNum = '+91' + phoneNumber;
      signInWithPhoneNumber(authentication, phoneNum, appVerifier)
        .then((confirmationResult) => {
          setLoader(false);
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          console.log(confirmationResult);
          window.confirmationResult = confirmationResult;
          setOtpVisible(true);
          // history.push("/otp");
          // ...
          setLoginVisible(false);
          // if ('OTPCredential' in window) {
          //   window.addEventListener('DOMContentLoaded', e => {
          //     const ac = new AbortController();
          //     navigator.credentials.get({
          //       otp: { transport: ['sms'] },
          //       signal: ac.signal
          //     }).then(otp => {
          //       alert(otp.code)
          //     }).catch(err => {
          //       console.log(err)
          //     });
          //   })
          // } else {
          //   alert('WebOTP not supported!.')
          // }
        }).catch((error) => {
          setLoader(false);
          // Error; SMS not sent
          console.log(error.message);
          if (error.message === 'Firebase: Error (auth/invalid-phone-number).') {
            toast("Enter a valid phone number", { autoClose: 500 });
          } else {
            toast("Something went wrong. Please try again.", { autoClose: 500 });
          }

          // ...

        });

    }

    if (phoneNumber === '') {
      // setErrorMessage('Please enter Mobile Number');
      console.log("error")
      return;
    }
    else {
      sendOtp();

    }
  }

  const getMerchantDet = async (sessionId) => {
    const token = sessionId;
    var urlPath = window.location.pathname;
    var merchantName = urlPath.substring(urlPath.lastIndexOf("/") + 1);
    const data = {
      merchantId: merchantName,
      ulatitude: '16.43',
      ulongitude: '74.59'
    }
    try {
      const result = await ApiService.getMerchantDataForCatalogue(data, token);
      if (result.data.code === 0) {
        localStorage.setItem("merchantdetails", JSON.stringify(result.data));
        setOfferBanner(result.data.merchantDetails.userOfferBanner)
      } else {
        console.log("Error")
      }
    } catch (error) {
      alert(error.message)
    } finally {

    }
  }

  const verifyOTp = () => {
    console.log(otp);

    if (otp.length === 6) {
      setLoader(true);
      //verify otp
      console.log("kkk");
      let confirmationResult = window.confirmationResult;
      confirmationResult.confirm(otp).then((result) => {
        // User signed in successfully.
        console.log("userSignInSuccess")
        const user = result.user;
        const firebaseId = user.uid;
        console.log(user);
        console.log(firebaseId);
        const user_check = {
          "mobileNumber": "91" + phoneNumber,
          "firebaseId": user.uid,
          "os": null,
          "osVer": null,
          "platform": "2",
        };
        console.log(user_check);
        setOtpVerified(true)
        setOtpVisible(false);
        setLoader(false);
        ApiService.webUserLogin(user_check)
          .then(res => {
            console.log(res.data);
            console.log(res.data.message);
            if (res.data.message === "Success") {
              console.log(res.data.data.sessionId);
              localStorage.setItem("User", phoneNumber);
              localStorage.setItem("sessionId", res.data.data.sessionId);
              getMerchUserChannel("fromLogin")
              getMerchantDet(res.data.data.sessionId)
            } else {
              // setErrorMessage('');
              console.log(res.data.message);
            }
          })
        // ...
      }).catch((error) => {
        // User couldn't sign in (bad verification code?)
        setOtpVerified(false);
        setLoader(false);
      });

      //verify otp
    }
  }

  const handleChangeAddress = async (address) => {
    const data = { priority: address.sequence }
    const token = localStorage.getItem('sessionId')
    await ApiService.updateUserAddress(data, token)
      .then(async result => {
        if (result.data.code === 0) {
          let prAddress = {};
          if (address.label !== '' && address.label !== 'null') {
            if (address.label !== 'Others') {
              prAddress = {
                label: address.label,
                address: address.address_line2
              }
            } else {
              prAddress = {
                label: address.label,
                address: address.address_line1
              }
            }
          } else if (address.city !== 'null' && address.city !== '') {
            prAddress = {
              label: address.city,
              address: address.address_line2
            }
          } else {
            prAddress = {
              label: "Others",
              address: address.address_line2
            }
          }
          setPriorityAddress(prAddress)
          setCurrentLat(address.latitude);
          setCurrentLng(address.longitude);
          getLogisticCharge({
            lat: address.latitude,
            lng: address.longitude,
            val: totalPrice
          });
          console.log("selected address==>", address)
        } else {
          console.log("error")
        }
      })
      .catch(err => { console.log(err); toast("Couldn't update address. Please try again.", { autoClose: 500 }) })

  }

  const handlePhoneNumber = (value) => {
    if (value.length < 11) {
      setPhoneNumber(value);
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === '-' || event.key === '+' || event.key === '.') {
      event.preventDefault();
    }
  };

  const handleOtpInput = (value) => {
    if (value.length !== 7) {
      setOtp(value);
    }
  }

  const handleOrderSummary = () => {
    getChannelDetailsv3();
  }

  const getChannelDetailsv3 = async (fromValue) => {
    setIsLoading(true);
    const token = localStorage.getItem('sessionId');
    const data = {
      merchantId: merchId,
    }
    await ApiService.getChannelDetailsv3(data, token)
      .then(async result => {
        setIsLoading(false);
        if (result.data.data.is_checkout_order === 0) {
          if ((result.data.data.delivery_mode === 0 && result.data.data.shipment_status === 5) || result.data.data.overall_status === 1 || result.data.data.PaymentDone === 1) {
            if (fromValue === "fromLogin") {
              getModifiedData(fromValue);
            }
            setShowOrderSummary(false);
            setOrderPlaced(false);
            getModifiedData();
          } else {
            var dateObj = new Date(result.data.data.order_created_date);
            function getOrdinalSuffix(day) {
              if (day > 3 && day < 21) return "th";
              switch (day % 10) {
                case 1:
                  return "st";
                case 2:
                  return "nd";
                case 3:
                  return "rd";
                default:
                  return "th";
              }
            }
            localStorage.removeItem("modifiedData")
            localStorage.removeItem("cartData")
            setShowOrderSummary(true);
            setOrderPlaced(true);
            setTotalCount(0);
            // Format the date and time
            var formattedDate =
              dateObj.getDate() +
              getOrdinalSuffix(dateObj.getDate()) +
              " " +
              dateObj.toLocaleString("default", { month: "long" }) +
              ", " +
              dateObj.getFullYear() +
              " | " +
              dateObj.toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true
              });
            setOrderStatus({
              orderId: result.data.data.order_id,
              stageStatus: result.data.data.stage_status,
              deliveryMode: result.data.data.delivery_mode,
              shipmentStatus: result.data.data.shipment_status,
              orderDate: formattedDate
            });
            let sav = Number(result.data.data.full_amount) - Number(result.data.data.order_total_amount)
            let logiData = {
              deliveryCharge: (result.data.data.total_user_logistic_charge !== "null" && result.data.data.total_user_logistic_charge !== "undefined") ? result.data.data.total_user_logistic_charge : 0,
              govtTax: result.data.data.govt_gst,
              payAmount: result.data.data.amt_to_pay,
              cashback: result.data.data.user_offer_amt,
              mrpTotal: result.data.data.full_amount,
              itemTotal: result.data.data.order_total_amount,
              savings: sav,
              orderStatusText: result.data.data.orderMsg,
              orderStatusTitle: result.data.data.orderMsgTitle,
              customProduct: result.data.data.extra_cart_items,
            }
            setLogisticData(logiData);
            setCartDat(result.data.data.order_summary);
            setShowDownloadBanner(false);
          }
        } else {
          if (fromValue === "fromLogin") {
            getModifiedData(fromValue);
          }
          setShowOrderSummary(false);
          setOrderPlaced(false);
          getModifiedData();
        }

      })
  }

  const giveToastMessage = () => {
    toast("Cannot add to cart when an order is in progress!", { autoClose: 500 })
  }

  const getMerchUserChannel = async (value) => {
    if (value === "fromLogin") {
      getChannelDetailsv3(value);
    } else {
      getChannelDetailsv3();
    }
  }
  const getModifiedData = (value) => {
    if (productdata && productdata.length !== 0) {
      setModificationData(productdata);
      console.log("modifiedData executed", productdata)

      //  Calulations
      let filterData = productdata.filter((data) => data.variants === undefined || data.variants === null || data.variants === "" || data.variants.length === 0)
      let countsum = filterData.map((data) => data.count);
      let varcountsum = [];
      for (let i = 0; i < productdata.length; i++) {
        if (productdata[i].variants !== undefined && productdata[i].variants !== null && productdata[i].variants !== "" && productdata[i].variants.length !== 0) {
          for (let j = 0; j < productdata[i].variants.length; j++) {
            varcountsum.push(productdata[i].variants[j].count)
          }
        }

      }
      var varSumcount = varcountsum.reduce((partialSum, a) => partialSum + a, 0);
      var Sumcount = countsum.reduce((partialSum, a) => partialSum + a, 0);
      Sumcount = Sumcount + varSumcount;
      console.log("Sumcount", Sumcount);
      setTotalCount(Sumcount);
      let varPrice = [];
      let countprice = filterData.map((data) => (data.count > 0 ? ((data.discounted_rate !== null && data.discounted_rate !== "" && data.discounted_rate !== undefined) ? data.count * parseFloat(data.discounted_rate) : data.count * parseFloat(data.price)) : 0));
      console.log("countprice", countprice);
      for (let i = 0; i < productdata.length; i++) {
        if (productdata[i].variants !== undefined && productdata[i].variants !== null && productdata[i].variants !== "" && productdata[i].variants.length !== 0) {
          for (let j = 0; j < productdata[i].variants.length; j++) {
            if (productdata[i].variants[j].count > 0) {
              if (productdata[i].variants[j].variantDisPrice !== undefined && productdata[i].variants[j].variantDisPrice !== null && productdata[i].variants[j].variantDisPrice !== '') {
                varPrice.push(productdata[i].variants[j].count * parseFloat(productdata[i].variants[j].variantDisPrice))
              } else {
                varPrice.push(productdata[i].variants[j].count * parseFloat(productdata[i].variants[j].variantPrice))
              }
            }
          }
        }

      }
      var otherPrice = countprice.reduce((partialSum, a) => partialSum + a, 0);
      var ttPrice = varPrice.reduce((partialSum, a) => partialSum + a, 0);
      var Sumprice = otherPrice + ttPrice;
      console.log("Sumprice", Sumprice);
      setTotalPrice(Sumprice);

      let oldcountprice = filterData.map((data) => (data.count > 0 ? data.count * parseFloat(data.price) : 0));
      var oldSumprice = oldcountprice.reduce((partialSum, a) => partialSum + a, 0);
      let oldvarcountprice = [];
      for (let i = 0; i < productdata.length; i++) {
        if (productdata[i].variants !== undefined && productdata[i].variants !== null && productdata[i].variants !== "" && productdata[i].variants.length !== 0) {
          for (let j = 0; j < productdata[i].variants.length; j++) {
            let data = (productdata[i].variants[j])
            oldvarcountprice.push(data.count > 0 ? (data.count > 0 ? data.count * parseFloat(data.variantPrice) : 0) : 0)
          }
        }

      }
      var oldvarSumprice = oldvarcountprice.reduce((partialSum, a) => partialSum + a, 0);
      oldSumprice = oldSumprice + oldvarSumprice;
      setOldTotalPrice(oldSumprice)
      setSavings(oldSumprice - Sumprice);
    }

    if (Sumcount === 0) {
      for (let i = 0; i < productdata.length; i++) {
        productdata[i].instruction = '';
        if (productdata[i].variants !== undefined && productdata[i].variants !== null && productdata[i].variants !== "" && productdata[i].variants.length !== 0) {
          for (let j = 0; j < productdata[i].variants.length; j++) {
            productdata[i].variants[j].instruction = '';
          }
        }
      }

    }

    if (localStorage.getItem("extraInstructions")) {
      setCustomInstructions(JSON.parse(localStorage.getItem("extraInstructions")));
    }
    if (localStorage.getItem("fromContact") === null) {
      if (value === "fromLogin") {
        console.log("THISSUM", Sumprice)
        getaddressesAndLogistics(Sumprice);
      }
    } else {
      if (localStorage.getItem("fromContact") === '1') {
        localStorage.removeItem("fromContact")
      }
    }

    if (Sumcount > 0) {
      setShowDownloadBanner(false);
    }

    if (localStorage.getItem("cartFromContact") === '1') {
      if (localStorage.getItem("User")) {
        handleviewcart();
      } else {
        setLoginVisible(true);
      }
      localStorage.removeItem("cartFromContact");
    }
  }

  const handleCustomProduct = (event, index) => {
    let input = event.target;
    if (input.value.includes('  ')) {
      input.value = input.value.replace(/\s+/g, ' ');
    }
    if (input.value.charAt(0) === ' ') {
      input.value = input.value.substring(1);
    }
    const newInputValues = [...customInstructions]
    let text = input.value;
    newInputValues[index] = text
    if (text !== '' && text !== ' ' && index === newInputValues.length - 1) {
      newInputValues.push('')
    } else if (text === '' && index < newInputValues.length - 1) {
      newInputValues.splice(index, 1)
    }
    setCustomInstructions(newInputValues);

  }

  const getS1CategoryProducts = async (categoryName, categoryId) => {
    setCatalogLoader(true);
    const data = {
      merchantID: merchId,
      category: categoryName,
      categoryId: categoryId
    }
    try {
      const result = await ApiService.getS1CategoriesProducts(data);
      if (result.data.code === 0) {
        console.log("catalogs1", result)
        setBestSeller(result.data.data)
      } else {
        alert("Data fetch failed")
      }
    } catch (error) {
      alert(error.message)
    } finally {
      setCatalogLoader(false);
    }
  }

  const handleS1CategoryClick = (categoryName, categoryId) => {
    setSelectedS1({ category: categoryName, categoryId: categoryId });
    getS1CategoryProducts(categoryName, categoryId)
  }

  const scrollToS1Category = () => {
    if (gridType !== 1) {
      if (s1CategoryRef.current) {
        s1CategoryRef.current.scrollIntoView({ top: 0, behavior: 'smooth' });
      }
    } else {
      if (s1CategoryRef.current) {
        s1CategoryRef.current.scrollIntoView({ top: 0, behavior: 'smooth' });
      }
    }

  };

  return (
    <div style={{ position: 'relative', paddingTop: `${searchInput !== '' ? '30px' : 0}` }}>
      <ToastContainer style={{ marginTop: '50%', textAlign: 'center', marginBottom: '3%' }} hideProgressBar={true} theme="dark" position="bottom-center" toastStyle={{ borderRadius: '20px', alignSelf: 'center', marginLeft: '3%', marginRight: '3%', verticalAlign: 'middle' }} />
      {isLoading && (
        <LoadingSpinner />
      )}
      {menuModalVisible ? (
        <Dialog classes={{ paper: classes.menuDialog }} onClose={() => setMenuModalVisible(!menuModalVisible)} open={menuModalVisible} PaperProps={{ sx: {} }}>
          <DialogContent className="dialog_instruction" style={{}}>
            <div className="menu-dialog-container">
              <p className="preferred_heading">Menu</p>
              {s1CategoryList.map((d, index) => {
                return (
                  <div hidden={d.count === 0} key={index} onClick={() => gotoMenuItem(index)} style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }}>
                    <p className="menu_category">{d.category}</p>
                    <p className='menu_category menu-category-count'>{d.count}</p>
                  </div>
                )
              })}
            </div>
          </DialogContent>
        </Dialog>
      ) : gridType === 1 ? (
        <div className={totalCount > 0 || orderPlaced ? 'menu_icon2' : "menu_icon"}>
          <img src={menu_icon} onClick={() => setMenuModalVisible(true)} className="menu-icon-img" alt="NA" />
        </div>
      ) : ''}

      {(searchInput === '' && offerBanner !== null) && (
        <div className="banner-container">
          <img className="offer-banner" onClick={() => window.open('https://webadmin.chattybao.com/offer/usercashback')} src={offerBanner} alt="Banner" />
        </div>
      )}

      {searchInput === '' ? (
        <div ref={s1CategoryRef} className="products-header-container">
          <img src={flourishLeft} className="flourish-icon" alt="Flourish" />
          <p className="products-header-text">Products</p>
          <img src={flourishRight} className="flourish-icon" alt="Flourish" />
        </div>
        // <img src={productsBanner} className="products-banner" alt="banner" />
        // <p className="allproducts_header" style={{ textAlign: 'center' }}>All Products {`(${catalogCount})`}</p>
      ) : ''}


      {modificationData.length === 0 && (
        <div className="no-data-text-container">
          <Typography className="search_empty_text" variant="caption" color="text.secondary">
            Did not find what you are looking for?
          </Typography>
          <Typography onClick={handlewhatsapp} className="chat_enquire_text" variant="caption" noWrap color="text.secondary">
            Chat & Enquire
          </Typography>
        </div>
      )}


      {gridType !== 1 && searchInput === '' && (
        <div className="category-bar">
          {s1CategoryList.length !== 0 && s1CategoryList.map((data) => (
            <div onClick={() => handleS1CategoryClick(data.category, data.categoryId)} className={selectedS1.category === data.category ? 's1category-name-selected' : "s1category-name"}>{data.category}</div>
          ))}
        </div>
      )}

      {gridType !== 1 && searchInput === '' && (
        <div>
          {catalogLoader ? (
            <div className="catalog-loader-container">
              <MoonLoader size={30} color="#125d8d" loading={true} />
            </div>
          ) : (
            <div className="grid">

              {modificationData && modificationData
                .map((data, index) => {
                  let discountExists = "";
                  let textDecoration = "";
                  let fdType = '';
                  let foodTypeIconShow = false;
                  let variantsExists = false;
                  let variantLength = ''
                  discountExists = "none";
                  textDecoration = "none";
                  let offer = '';
                  if (data.foodType !== null && data.foodType !== undefined && data.foodType !== "") {
                    foodTypeIconShow = true;
                    if (data.foodType === 'Non-Veg') {
                      fdType = non_veg_icon;
                    } else if (data.foodType === 'Egg') {
                      fdType = egg_icon;
                    } else if (data.foodType === 'Veg') {
                      fdType = veg_icon;
                    }
                  }
                  if (data.variants !== null && data.variants !== undefined && data.variants !== "" && data.variants.length !== 0) {
                    variantsExists = true;
                    if (data.variants[0].variantDisPrice !== null && data.variants[0].variantDisPrice !== "" && data.variants[0].variantDisPrice !== undefined) {
                      discountExists = "inline";
                      textDecoration = "line-through";
                      offer = 100 - (data.variants[0].variantDisPrice / data.variants[0].variantPrice) * 100;
                      offer = Math.round(offer)
                    }
                    variantLength = data.variants.length;
                  } else {
                    if (data.discounted_rate !== null && data.discounted_rate !== "" && data.discounted_rate !== undefined) {
                      discountExists = "inline";
                      textDecoration = "line-through";
                      offer = 100 - (data.discounted_rate / data.price) * 100;
                      offer = Math.round(offer)
                    }
                  }

                  let instructionFromHomePage = true;
                  return (
                    <>
                      <Card className="box" key={data.id}>

                        <div className="relative-position">
                          <div hidden={discountExists === 'none'} className="offer-tag-container">
                            <p className="offer_text">{offer}% {"\n"} OFF </p>
                            <img src={discount_tag} className="discount-tag" alt='discount' />
                          </div>
                          <div className="best-seller-tag-container">
                            {data.bestseller === 0 && data.bestseller !== undefined && data.bestseller !== null && data.bestseller !== '' ? (
                              <img src={bestSeller_tag} className="best-seller-tag-img" alt='bestseller-tag' />
                            ) : ''}

                          </div>
                          {data.image_url !== undefined && data.image_url !== null && data.image_url !== '' ? (
                            <LazyLoadImage component="img" src={Array.isArray(data.image_url) ? data.image_url[0] : data.image_url} className='gridImage' alt="NA" onClick={data.status === 0 ? () => handleviewproductdetail(data) : null} />
                          ) : (
                            <div>
                              <p className="image-not-available-text">Image not available</p>
                            </div>
                          )}
                        </div>

                        <div className="product-bottom-container">
                          <div className="brand-name-container">
                            {data.brandName ? (
                              <p className="brand_name">{data.brandName}</p>
                            ) : ''}
                            <Typography className={`item_name ${data.brandName ? '' : 'item-name-optional2'}`} color="text.secondary">
                              {data.item_name}
                            </Typography>
                          </div>
                          <div className="bottomline_container">
                            <div>
                              <div className="price-container">
                                <div className={`${discountExists === 'inline' ? '' : 'product_offer_price_nodiscount1'}`}>

                                  <Typography
                                    variant="subtile1"
                                    className={` ${discountExists === 'inline' ? 'normal_item' : 'product_offer_price_nodiscount'}`}
                                    style={{ textAlign: "left", color: "black", fontWeight: "600", paddingTop: "4px", textDecoration: `${textDecoration}`, wordBreak: 'break-all' }}
                                  >
                                    {data.price && data.price ? currency(data.price * 1) : "₹ 5"}
                                  </Typography>
                                </div>
                                <div>
                                  <Typography
                                    variant="subtile1"
                                    color="text.secondary"
                                    className="product_offer_price"
                                    style={{ textAlign: "left", color: "#000000", fontWeight: "600", fontSize: "10px", wordBreak: 'break-all' }}
                                  >
                                    {data.discounted_rate && data.discounted_rate ? currency(data.discounted_rate * 1) : " "}
                                  </Typography>

                                </div>
                              </div>



                            </div>
                            <p style={{ alignSelf: 'flex-end' }} className="productUnit">{isFruitsAndVegMerchant === "FRUITS" || "GROCERY" ? data.unit && data.unit : ""}</p>
                            <span style={{ alignSelf: "flex-end", marginBottom: "3px" }}>
                              {data.count > 0 ? (
                                <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '72px', height: '24px', alignItems: 'center', border: '1px solid rgba(18,93,141)', paddingTop: '2px', borderRadius: '3px' }}>
                                  <p className="plus_minus_text" style={{ flex: 1, textAlign: 'center' }} onClick={variantsExists ? () => handleVariantsButton(data) : () => decreaseCount(data.count, data.id, "fromHome")}>-</p>
                                  <p className="product_count" style={{ flex: 1, textAlign: 'center', paddingTop: '1px' }}>{data.count}</p>
                                  <p className="plus_minus_text" style={{ flex: 1, textAlign: 'center' }} onClick={variantsExists ? () => handleVariantsButton(data) : () => addCount(data.count, data.id, "fromHome")}>+</p>
                                </div>

                              ) : (
                                <>
                                  {data.status === 0 ? (
                                    <div style={{ border: '1px solid rgba(18,93,141)', width: '72px', height: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '3px' }} onClick={orderPlaced ? giveToastMessage : (variantsExists ? () => handleVariantsButton(data) : () => addCount(data.count, data.id, "fromHome"))}>
                                      {variantsExists ? (
                                        <p className="options_text_grid">{`${variantLength} Options`}</p>
                                      ) : (
                                        <p className="add_text_grid">ADD</p>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="not-available-text-container">
                                      <p className="not-available-text-grid">Not Available</p>
                                    </div>
                                  )}
                                </>

                              )}
                            </span>

                          </div>
                        </div>


                      </Card>
                    </>
                  )
                })}
            </div>
          )}
        </div>

      )}

      {/* For search */}

      {searchInput !== '' && (
        <div>
          {catalogLoader ? (
            <div className="catalog-loader-container">
              <MoonLoader size={30} color="#125d8d" loading={true} />
            </div>
          ) : (
            <div>
              {(gridType !== 1) ? (
                <div className="grid">

                  {/* 2 x 2 for s1category */}

                  {modificationData.length !== 0 && modificationData
                    .map((data, index) => {
                      let discountExists = "";
                      let textDecoration = "";
                      let fdType = '';
                      let foodTypeIconShow = false;
                      let variantsExists = false;
                      let variantLength = ''
                      discountExists = "none";
                      textDecoration = "none";
                      let offer = '';
                      if (data.foodType !== null && data.foodType !== undefined && data.foodType !== "") {
                        foodTypeIconShow = true;
                        if (data.foodType === 'Non-Veg') {
                          fdType = non_veg_icon;
                        } else if (data.foodType === 'Egg') {
                          fdType = egg_icon;
                        } else if (data.foodType === 'Veg') {
                          fdType = veg_icon;
                        }
                      }
                      if (data.variants !== null && data.variants !== undefined && data.variants !== "" && data.variants.length !== 0) {
                        variantsExists = true;
                        if (data.variants[0].variantDisPrice !== null && data.variants[0].variantDisPrice !== "" && data.variants[0].variantDisPrice !== undefined) {
                          discountExists = "inline";
                          textDecoration = "line-through";
                          offer = 100 - (data.variants[0].variantDisPrice / data.variants[0].variantPrice) * 100;
                          offer = Math.round(offer)
                        }
                        variantLength = data.variants.length;
                      } else {
                        if (data.discounted_rate !== null && data.discounted_rate !== "" && data.discounted_rate !== undefined) {
                          discountExists = "inline";
                          textDecoration = "line-through";
                          offer = 100 - (data.discounted_rate / data.price) * 100;
                          offer = Math.round(offer)
                        }
                      }

                      let instructionFromHomePage = true;
                      return (
                        <>
                          <Card className="box" key={data.id}>

                            <div style={{ position: "relative" }}>
                              <div style={{ position: "absolute", marginTop: "10px", display: `${discountExists === 'none' ? 'none' : 'block'}` }}>
                                <p className="offer_text">{offer}% {"\n"} OFF </p>
                                <img src={discount_tag} style={{ width: "40px" }} alt='discount' />
                              </div>
                              <div style={{ position: "absolute", right: "0", marginTop: "15px" }}>
                                {data.bestseller === 0 && data.bestseller !== undefined && data.bestseller !== null && data.bestseller !== '' ? (
                                  <img src={bestSeller_tag} style={{ height: "26px" }} alt='bestseller-tag' />
                                ) : ''}

                              </div>
                              {data.image_url !== undefined && data.image_url !== null && data.image_url !== '' ? (
                                <LazyLoadImage component="img" src={Array.isArray(data.image_url) ? data.image_url[0] : data.image_url} className='gridImage' alt="NA" onClick={() => handleviewproductdetail(data)} />
                              ) : (
                                <div>
                                  <p className="image-not-available-text">Image not available</p>
                                </div>
                              )}
                            </div>

                            <div className="product-bottom-container">
                              <div className="brand-name-container">
                                {data.brandName ? (
                                  <p className="brand_name">{data.brandName}</p>
                                ) : ''}
                                <Typography color="text.secondary" className={`item_name ${data.brandName ? '' : 'item-name-optional2'}`}>
                                  {data.item_name}
                                </Typography>
                              </div>
                              <div className="bottomline_container">
                                <div>
                                  <div className="price-container">
                                    <div className={`${discountExists === 'inline' ? '' : 'product_offer_price_nodiscount1'}`}>

                                      <Typography
                                        variant="subtile1"
                                        className={`${discountExists === 'inline' ? 'normal_item' : 'product_offer_price_nodiscount'}`}
                                        style={{ textAlign: "left", color: "black", fontWeight: "600", paddingTop: "4px", textDecoration: `${textDecoration}`, wordBreak: 'break-all' }}
                                      >
                                        {data.price && data.price ? currency(data.price * 1) : "₹ 5"}
                                      </Typography>
                                    </div>
                                    <div>
                                      <Typography
                                        variant="subtile1"
                                        color="text.secondary"
                                        className="product_offer_price"
                                        style={{ textAlign: "left", color: "#000000", fontWeight: "600", fontSize: "10px", wordBreak: 'break-all' }}
                                      >
                                        {data.discounted_rate && data.discounted_rate ? currency(data.discounted_rate * 1) : " "}
                                      </Typography>

                                    </div>
                                  </div>



                                </div>
                                <p style={{ alignSelf: 'flex-end' }} className="productUnit">{isFruitsAndVegMerchant === "FRUITS" || "GROCERY" ? data.unit && data.unit : ""}</p>
                                <span style={{ alignSelf: "flex-end", marginBottom: "3px" }}>
                                  {data.count > 0 ? (
                                    <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '72px', height: '24px', alignItems: 'center', border: '1px solid rgba(18,93,141)', paddingTop: '2px', borderRadius: '3px' }}>
                                      <p className="plus_minus_text" style={{ flex: 1, textAlign: 'center' }} onClick={variantsExists ? () => handleVariantsButton(data) : () => decreaseCount(data.count, data.id, "fromHome")}>-</p>
                                      <p className="product_count" style={{ flex: 1, textAlign: 'center', paddingTop: '1px' }}>{data.count}</p>
                                      <p className="plus_minus_text" style={{ flex: 1, textAlign: 'center' }} onClick={variantsExists ? () => handleVariantsButton(data) : () => addCount(data.count, data.id, "fromHome")}>+</p>
                                    </div>

                                  ) : (
                                    <div style={{ border: '1px solid rgba(18,93,141)', width: '72px', height: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '3px' }} onClick={orderPlaced ? giveToastMessage : (variantsExists ? () => handleVariantsButton(data) : () => addCount(data.count, data.id, "fromHome"))}>
                                      {variantsExists ? (
                                        <p className="options_text_grid">{`${variantLength} Options`}</p>
                                      ) : (
                                        <p className="add_text_grid">ADD</p>
                                      )}

                                    </div>



                                  )}
                                </span>

                              </div>
                            </div>


                          </Card>
                        </>
                      )
                    })}
                </div>
              ) : (
                <div className="grid2">

                  {/* 1 x n for s1category */}

                  {modificationData.length !== 0 && modificationData
                    .map((data, index) => {
                      let discountExists = "";
                      let textDecoration = "";
                      let fdType = '';
                      let foodTypeIconShow = false;
                      let variantsExists = false;
                      let variantLength = 0;
                      discountExists = "none";
                      textDecoration = "none";
                      let offer = '';
                      console.log("discount value", data.discounted_rate);
                      if (data.foodType !== null && data.foodType !== undefined && data.foodType !== "") {
                        foodTypeIconShow = true;
                        if (data.foodType === 'Non-Veg') {
                          fdType = non_veg_icon;
                        } else if (data.foodType === 'Egg') {
                          fdType = egg_icon;
                        } else {
                          fdType = veg_icon;
                        }
                      }
                      if (data.variants !== null && data.variants !== undefined && data.variants !== "" && data.variants.length !== 0) {
                        variantsExists = true;
                        variantLength = data.variants.length;
                      }
                      if (data.discounted_rate !== null && data.discounted_rate !== "" && data.discounted_rate !== undefined) {
                        discountExists = "inline";
                        textDecoration = "line-through";
                        offer = 100 - (data.discounted_rate / data.price) * 100;
                        offer = Math.round(offer)
                      }

                      let instructionFromHomePage = true;


                      return (
                        <>
                          <Card className="maincards" key={data.id}>
                            <CardContent className="main_image_container">
                              <div className="tags_container">
                                <img src={fdType} style={{ width: "21px", height: '21px', display: `${foodTypeIconShow ? "block" : "none"}`, marginRight: '7px' }} alt="food-type" />
                                <div style={{ position: 'relative', display: `${discountExists === 'none' ? 'none' : 'block'}` }}>
                                  <div className="offer_text2">
                                    <p className="offer_text2" style={{ color: "white" }}>{offer}% OFF</p>
                                  </div>
                                  <img src={discount_tag_hor} style={{ height: '22px', width: '65px', marginRight: '7px' }} alt="tag" />
                                </div>
                                {data.bestseller === 0 && data.bestseller !== undefined && data.bestseller !== null && data.bestseller !== '' ? (
                                  <img src={bestSeller_tag} style={{ width: '80px', height: '24px' }} alt="tag" />
                                ) : ''}

                              </div>
                              <Typography className="product_name" color="text.secondary" style={{ lineHeight: "1.2", wordBreak: 'break-word' }}>
                                {data.item_name}
                              </Typography>

                              <Typography
                                variant="subtile1"
                                className={`${discountExists === 'inline' ? 'normal_item2' : 'product_normal_price2'}`}
                                style={{ textAlign: "left", color: "black", textDecoration: `${textDecoration}` }}
                              >
                                {data.price && data.price ? currency(data.price * 1) : "₹ 5"}
                              </Typography>

                              <Typography
                                variant="subtile1"
                                color="text.secondary"
                                className="product_offer_price2"
                                style={{ textAlign: "left", color: "#000000", display: `${discountExists}` }}
                              >
                                {data.discounted_rate && data.discounted_rate ? currency(data.discounted_rate * 1) : " "}
                              </Typography>
                              <p className="productUnit">{isFruitsAndVegMerchant === "FRUITS" || "GROCERY" ? data.unit && data.unit : ""}</p>

                              {/* <Divider className="dot_divider" /> */}

                              <Typography className="maincards_typodescription2" variant="h6" color="text.secondary" style={{ paddingTop: "9px" }}>
                                {data.desc ? data.desc : " "}
                              </Typography>
                            </CardContent>

                            <CardContent className="main_image_container">
                              <span>
                                <LazyLoadImage className="cardimgs" component="img" src={Array.isArray(data.image_url) ? data.image_url[0] : data.image_url} alt="Image not available" onClick={() => handleviewproductdetail(data)} />
                              </span>

                              {data.product_available === 1 ? (
                                <CardActions disableSpacing className="product_availability_container" style={{ marginBottom: '30px' }}>
                                  <span className="product_availability_btn" style={{ paddingBottom: "2px", position: "relative" }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', width: '100px', alignItems: 'center', border: '1px solid #D9D9D9', paddingTop: '2px', borderRadius: '5px', backgroundColor: 'white' }}>
                                      <p className="product_availability_text">{data.avail_msg}</p>
                                    </div>
                                  </span>
                                </CardActions>

                              ) : (
                                <span>
                                  {data.count > 0 ? (
                                    <>
                                      <CardActions disableSpacing className="main_add_sub_container_single">
                                        <span
                                          className="maincard_button"
                                          style={{ paddingBottom: "2px", position: "relative" }}
                                        >
                                          <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '85px', height: '28px', alignItems: 'center', border: '1px solid rgba(18,93,141)', paddingTop: '2px', borderRadius: '5px', backgroundColor: 'white' }}>
                                            <p className="plus_minus_text_grid" style={{ flex: 1, textAlign: 'center' }} onClick={variantsExists ? () => handleVariantsButton(data) : () => decreaseCount(data.count, data.id, "fromHome")}>-</p>
                                            <p className="product_count2" style={{ flex: 1, textAlign: 'center', paddingTop: '1px' }}>{data.count}</p>
                                            <p className="plus_minus_text_grid" style={{ flex: 1, textAlign: 'center' }} onClick={variantsExists ? () => handleVariantsButton(data) : () => addCount(data.count, data.id, "fromHome")}>+</p>

                                          </div>
                                        </span>
                                      </CardActions>
                                    </>
                                  ) : (
                                    <CardActions disableSpacing className="main_add_sub_container_single">
                                      <span
                                        className="maincard_button"
                                        style={{
                                        }}
                                        onClick={orderPlaced ? giveToastMessage : (variantsExists ? () => handleVariantsButton(data) : () => addCount(data.count, data.id, "fromHome"))}
                                      >
                                        <div style={{ width: '85px', backgroundColor: 'white', borderRadius: '5px', border: '1px solid #125d8d', height: '28px' }}>
                                          {variantsExists ? (
                                            <p className="options_text">{`${variantLength} Options`}</p>
                                          ) : (
                                            <p className="add_text">ADD</p>
                                          )}
                                        </div>
                                      </span>
                                    </CardActions>
                                  )}
                                  <div class="special_instruction">
                                    {data.instruction == "" ? (
                                      <a className="maincard_instruction1" onClick={() => modalopen(data.id, data.instruction, instructionFromHomePage)}>
                                        <div className="plus_text">+ </div>
                                        <div className="specialInstructionText">Special Instructions</div>
                                      </a>
                                    ) : (
                                      <a className="edit_instruction" onClick={() => modalopen(data.id, data.instruction, instructionFromHomePage)}>
                                        <img src={edit_icon} alt="icon" style={{ width: '14px', height: '18px' }}></img>
                                        <p className="specialEditText">{data.instruction} </p>
                                      </a>
                                    )}
                                  </div>
                                </span>
                              )}


                            </CardContent>
                          </Card>

                        </>
                      )
                    })}
                </div>
              )}
            </div>
          )}
        </div>

      )}



      {/* For 1 x n layout */}

      {s1CategoryList.length !== 0 && s1CategoryList.length > 0 && gridType === 1 && searchInput === '' ? (
        s1CategoryList.map((cat, index) => {
          return (
            <div key={index}>
              {searchInput === '' ? (
                <>
                  <span onClick={() => openCategory(cat)} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: '10px', paddingRight: '15px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p id={index} className="s1cat_name">{cat.category}</p>
                      <p className="s1cat_count">{`(${cat.count})`}</p>
                    </div>

                    <img src={cat.show ? up_arrow : down_arrow} style={{ height: '11px' }} alt="NA" />

                  </span>
                </>
              ) : ''}

              {(bestsellersCount !== 0 && index === 0) ? (
                <div hidden={!cat.show}>
                  <div className="grid2">

                    {modificationData.length !== 0 && modificationData.filter((dat) => dat.bestseller === 0)
                      .map((data, index) => {
                        let discountExists = "";
                        let textDecoration = "";
                        let fdType = '';
                        let foodTypeIconShow = false;
                        let variantsExists = false;
                        let variantLength = 0;
                        discountExists = "none";
                        textDecoration = "none";
                        let offer = '';
                        console.log("discount value", data.discounted_rate);
                        if (data.foodType !== null && data.foodType !== undefined && data.foodType !== "") {
                          foodTypeIconShow = true;
                          if (data.foodType === 'Non-Veg') {
                            fdType = non_veg_icon;
                          } else if (data.foodType === 'Egg') {
                            fdType = egg_icon;
                          } else {
                            fdType = veg_icon;
                          }
                        }
                        if (data.variants !== null && data.variants !== undefined && data.variants !== "" && data.variants.length !== 0) {
                          variantsExists = true;
                          variantLength = data.variants.length;
                        }
                        if (data.discounted_rate !== null && data.discounted_rate !== "" && data.discounted_rate !== undefined) {
                          discountExists = "inline";
                          textDecoration = "line-through";
                          offer = 100 - (data.discounted_rate / data.price) * 100;
                          offer = Math.round(offer)
                        }

                        let instructionFromHomePage = true;


                        return (
                          <>
                            <Card className="maincards" key={data.id}>
                              <CardContent className="main_image_container">
                                <div className="tags_container">
                                  <img src={fdType} style={{ width: "21px", height: '21px', display: `${foodTypeIconShow ? "block" : "none"}`, marginRight: '7px' }} alt="food-type" />
                                  <div style={{ position: 'relative', display: `${discountExists === 'none' ? 'none' : 'block'}` }}>
                                    <div className="offer_text2">
                                      <p className="offer_text2" style={{ color: "white" }}>{offer}% OFF</p>
                                    </div>
                                    <img src={discount_tag_hor} style={{ height: '22px', width: '65px', marginRight: '7px' }} alt="tag" />
                                  </div>
                                  {data.bestseller === 0 && data.bestseller !== undefined && data.bestseller !== null && data.bestseller !== '' ? (
                                    <img src={bestSeller_tag} style={{ width: '80px', height: '24px' }} alt="tag" />
                                  ) : ''}

                                </div>
                                <Typography className="product_name" color="text.secondary" style={{ lineHeight: "1.2", wordBreak: 'break-word' }}>
                                  {data.item_name}
                                </Typography>

                                <Typography
                                  variant="subtile1"
                                  className={`${discountExists === 'inline' ? 'normal_item2' : 'product_normal_price2'}`}
                                  style={{ textAlign: "left", color: "black", textDecoration: `${textDecoration}` }}
                                >
                                  {data.price && data.price ? currency(data.price * 1) : "₹ 5"}
                                </Typography>

                                <Typography
                                  variant="subtile1"
                                  color="text.secondary"
                                  className="product_offer_price2"
                                  style={{ textAlign: "left", color: "#000000", display: `${discountExists}` }}
                                >
                                  {data.discounted_rate && data.discounted_rate ? currency(data.discounted_rate * 1) : " "}
                                </Typography>
                                <p className="productUnit">{isFruitsAndVegMerchant === "FRUITS" || "GROCERY" ? data.unit && data.unit : ""}</p>

                                {/* <Divider className="dot_divider" /> */}

                                <Typography className="maincards_typodescription2" variant="h6" color="text.secondary" style={{ paddingTop: "9px" }}>
                                  {data.desc ? data.desc : " "}
                                </Typography>
                              </CardContent>

                              <CardContent className="main_image_container">
                                <span>
                                  <LazyLoadImage className="cardimgs" component="img" src={Array.isArray(data.image_url) ? data.image_url[0] : data.image_url} alt="Image not available" onClick={data.status === 0 ? () => handleviewproductdetail(data) : null} />
                                </span>

                                {data.product_available === 1 ? (
                                  <CardActions disableSpacing className="product_availability_container" style={{ marginBottom: '30px' }}>
                                    <span className="product_availability_btn" style={{ paddingBottom: "2px", position: "relative" }}>
                                      <div style={{ display: 'flex', justifyContent: 'center', width: '100px', alignItems: 'center', border: '1px solid #D9D9D9', paddingTop: '2px', borderRadius: '5px', backgroundColor: 'white' }}>
                                        <p className="product_availability_text">{data.avail_msg}</p>
                                      </div>
                                    </span>
                                  </CardActions>

                                ) : (
                                  <span>
                                    {data.count > 0 ? (
                                      <>
                                        <CardActions disableSpacing className="main_add_sub_container_single">
                                          <span
                                            className="maincard_button"
                                            style={{ paddingBottom: "2px", position: "relative" }}
                                          >
                                            <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '85px', height: '28px', alignItems: 'center', border: '1px solid rgba(18,93,141)', paddingTop: '2px', borderRadius: '5px', backgroundColor: 'white' }}>
                                              <p className="plus_minus_text_grid" style={{ flex: 1, textAlign: 'center' }} onClick={variantsExists ? () => handleVariantsButton(data) : () => decreaseCount(data.count, data.id, "fromHome")}>-</p>
                                              <p className="product_count2" style={{ flex: 1, textAlign: 'center', paddingTop: '1px' }}>{data.count}</p>
                                              <p className="plus_minus_text_grid" style={{ flex: 1, textAlign: 'center' }} onClick={variantsExists ? () => handleVariantsButton(data) : () => addCount(data.count, data.id, "fromHome")}>+</p>

                                            </div>
                                          </span>
                                        </CardActions>
                                      </>
                                    ) : (
                                      <CardActions disableSpacing className="main_add_sub_container_single">
                                        {data.status === 0 ? (
                                          <span
                                            className="maincard_button"
                                            style={{
                                            }}
                                            onClick={orderPlaced ? giveToastMessage : (variantsExists ? () => handleVariantsButton(data) : () => addCount(data.count, data.id, "fromHome"))}
                                          >
                                            <div style={{ width: '85px', backgroundColor: 'white', borderRadius: '5px', border: '1px solid #125d8d', height: '28px' }}>
                                              {variantsExists ? (
                                                <p className="options_text">{`${variantLength} Options`}</p>
                                              ) : (
                                                <p className="add_text">ADD</p>
                                              )}
                                            </div>
                                          </span>
                                        ) : (
                                          <span
                                            className="maincard_button"
                                          >
                                            <div style={{ width: '120px', backgroundColor: 'grey', borderRadius: '5px', height: '28px' }}>
                                              <p className="unavailable_text">Not Available</p>
                                            </div>
                                          </span>
                                        )}

                                      </CardActions>
                                    )}
                                    {data.status === 0 ? (
                                      <div class="special_instruction">
                                        {data.instruction === "" ? (
                                          <div className="maincard_instruction1" onClick={() => modalopen(data.id, data.instruction, instructionFromHomePage)}>
                                            <div className="plus_text">+ </div>
                                            <div className="specialInstructionText">Special Instructions</div>
                                          </div>
                                        ) : (
                                          <div className="edit_instruction" onClick={() => modalopen(data.id, data.instruction, instructionFromHomePage)}>
                                            <img src={edit_icon} alt="icon" style={{ width: '14px', height: '18px' }}></img>
                                            <p className="specialEditText">{data.instruction} </p>
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <div class="special_instruction" />
                                    )}

                                  </span>
                                )}


                              </CardContent>
                            </Card>

                          </>
                        )
                      })}
                  </div>
                </div>
              ) : (
                <div hidden={!cat.show}>
                  <div className="grid2">
                    {console.log("BestSSSS", modificationData)}

                    {modificationData.length !== 0 && modificationData.filter((dat) => dat.s1category === cat.category)
                      .map((data, index) => {
                        let discountExists = "";
                        let textDecoration = "";
                        let fdType = '';
                        let foodTypeIconShow = false;
                        let variantsExists = false;
                        let variantLength = 0;
                        discountExists = "none";
                        textDecoration = "none";
                        let offer = '';
                        console.log("discount value", data.discounted_rate);
                        if (data.foodType !== null && data.foodType !== undefined && data.foodType !== "") {
                          foodTypeIconShow = true;
                          if (data.foodType === 'Non-Veg') {
                            fdType = non_veg_icon;
                          } else if (data.foodType === 'Egg') {
                            fdType = egg_icon;
                          } else {
                            fdType = veg_icon;
                          }
                        }
                        if (data.variants !== null && data.variants !== undefined && data.variants !== "" && data.variants.length !== 0) {
                          variantsExists = true;
                          variantLength = data.variants.length;
                        }
                        if (data.discounted_rate !== null && data.discounted_rate !== "" && data.discounted_rate !== undefined) {
                          discountExists = "inline";
                          textDecoration = "line-through";
                          offer = 100 - (data.discounted_rate / data.price) * 100;
                          offer = Math.round(offer)
                        }

                        let instructionFromHomePage = true;


                        return (
                          <>
                            <Card className="maincards" key={data.id}>
                              <CardContent className="main_image_container">
                                <div className="tags_container">
                                  <img src={fdType} style={{ width: "21px", height: '21px', display: `${foodTypeIconShow ? "block" : "none"}`, marginRight: '7px' }} alt="food-type" />
                                  <div style={{ position: 'relative', display: `${discountExists === 'none' ? 'none' : 'block'}` }}>
                                    <div className="offer_text2">
                                      <p className="offer_text2" style={{ color: "white" }}>{offer}% OFF</p>
                                    </div>
                                    <img src={discount_tag_hor} style={{ height: '22px', width: '65px', marginRight: '7px' }} alt="tag" />
                                  </div>
                                  {data.bestseller === 0 && data.bestseller !== undefined && data.bestseller !== null && data.bestseller !== '' ? (
                                    <img src={bestSeller_tag} style={{ width: '80px', height: '24px' }} alt="tag" />
                                  ) : ''}

                                </div>
                                <Typography className="product_name" color="text.secondary" style={{ lineHeight: "1.2", wordBreak: 'break-word' }}>
                                  {data.item_name}
                                </Typography>

                                <Typography
                                  variant="subtile1"
                                  className={`${discountExists === 'inline' ? 'normal_item2' : 'product_normal_price2'}`}
                                  style={{ textAlign: "left", color: "black", textDecoration: `${textDecoration}` }}
                                >
                                  {data.price && data.price ? currency(data.price * 1) : "₹ 5"}
                                </Typography>

                                <Typography
                                  variant="subtile1"
                                  color="text.secondary"
                                  className="product_offer_price2"
                                  style={{ textAlign: "left", color: "#000000", display: `${discountExists}` }}
                                >
                                  {data.discounted_rate && data.discounted_rate ? currency(data.discounted_rate * 1) : " "}
                                </Typography>
                                <p className="productUnit">{isFruitsAndVegMerchant === "FRUITS" || "GROCERY" ? data.unit && data.unit : ""}</p>

                                {/* <Divider className="dot_divider" /> */}

                                <Typography className="maincards_typodescription2" variant="h6" color="text.secondary" style={{ paddingTop: "9px" }}>
                                  {data.desc ? data.desc : " "}
                                </Typography>
                              </CardContent>

                              <CardContent className="main_image_container">
                                <span>
                                  <LazyLoadImage className="cardimgs" component="img" src={Array.isArray(data.image_url) ? data.image_url[0] : data.image_url} alt="Image not available" onClick={data.status === 0 ? () => handleviewproductdetail(data) : null} />
                                </span>

                                {data.product_available === 1 ? (
                                  <CardActions disableSpacing className="product_availability_container" style={{ marginBottom: '30px' }}>
                                    <span className="product_availability_btn" style={{ paddingBottom: "2px", position: "relative" }}>
                                      <div style={{ display: 'flex', justifyContent: 'center', width: '100px', alignItems: 'center', border: '1px solid #D9D9D9', paddingTop: '2px', borderRadius: '5px', backgroundColor: 'white' }}>
                                        <p className="product_availability_text">{data.avail_msg}</p>
                                      </div>
                                    </span>
                                  </CardActions>

                                ) : (
                                  <span>
                                    {data.count > 0 ? (
                                      <>
                                        <CardActions disableSpacing className="main_add_sub_container_single">
                                          <span
                                            className="maincard_button"
                                            style={{ paddingBottom: "2px", position: "relative" }}
                                          >
                                            <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '85px', height: '28px', alignItems: 'center', border: '1px solid rgba(18,93,141)', paddingTop: '2px', borderRadius: '5px', backgroundColor: 'white' }}>
                                              <p className="plus_minus_text_grid" style={{ flex: 1, textAlign: 'center' }} onClick={variantsExists ? () => handleVariantsButton(data) : () => decreaseCount(data.count, data.id, "fromHome")}>-</p>
                                              <p className="product_count2" style={{ flex: 1, textAlign: 'center', paddingTop: '1px' }}>{data.count}</p>
                                              <p className="plus_minus_text_grid" style={{ flex: 1, textAlign: 'center' }} onClick={variantsExists ? () => handleVariantsButton(data) : () => addCount(data.count, data.id, "fromHome")}>+</p>

                                            </div>
                                          </span>
                                        </CardActions>
                                      </>
                                    ) : (
                                      <CardActions disableSpacing className="main_add_sub_container_single">
                                        {data.status === 0 ? (
                                          <span
                                            className="maincard_button"
                                            style={{
                                            }}
                                            onClick={orderPlaced ? giveToastMessage : (variantsExists ? () => handleVariantsButton(data) : () => addCount(data.count, data.id, "fromHome"))}
                                          >
                                            <div style={{ width: '85px', backgroundColor: 'white', borderRadius: '5px', border: '1px solid #125d8d', height: '28px' }}>
                                              {variantsExists ? (
                                                <p className="options_text">{`${variantLength} Options`}</p>
                                              ) : (
                                                <p className="add_text">ADD</p>
                                              )}
                                            </div>
                                          </span>
                                        ) : (
                                          <span
                                            className="maincard_button"
                                          >
                                            <div style={{ width: '120px', backgroundColor: 'grey', borderRadius: '5px', height: '28px' }}>
                                              <p className="unavailable_text">Not Available</p>
                                            </div>
                                          </span>
                                        )}

                                      </CardActions>
                                    )}
                                    {data.status === 0 ? (
                                      <div class="special_instruction">
                                        {data.instruction == "" ? (
                                          <a className="maincard_instruction1" onClick={() => modalopen(data.id, data.instruction, instructionFromHomePage)}>
                                            <div className="plus_text">+ </div>
                                            <div className="specialInstructionText">Special Instructions</div>
                                          </a>
                                        ) : (
                                          <a className="edit_instruction" onClick={() => modalopen(data.id, data.instruction, instructionFromHomePage)}>
                                            <img src={edit_icon} alt="icon" style={{ width: '14px', height: '18px' }}></img>
                                            <p className="specialEditText">{data.instruction} </p>
                                          </a>
                                        )}
                                      </div>
                                    ) : (
                                      <div class="special_instruction" />
                                    )}
                                  </span>
                                )}


                              </CardContent>
                            </Card>

                          </>
                        )
                      })}
                  </div>
                </div>
              )}



              {searchInput === '' || searchInput.toLowerCase() === cat.category.toLowerCase() ? (
                <Divider className="dividers" />
              ) : ''}

            </div>
          )
        })
      ) : ''}

      {modificationData.length === 0 && searchInput === '' && (
        <div
          style={{
            display: "flex",
            textAlign: "center",
            color: "blue",
            fontWeight: "bold",
            flexDirection: "column",
            border: "2px solid white",
            alignItems: "center",
            borderRadius: "5px",
          }}
        >
          <Typography variant="caption" color="text.secondary" style={{ color: "black", textAlign: "center" }}>
            Business has not added products. You may chat/call and inquire
          </Typography>
        </div>
      )}



      {modificationData.length !== 0 && (
        <div
          style={{
            display: "flex",
            textAlign: "center",
            color: "blue",
            fontWeight: "bold",
            flexDirection: "column",
            border: "2px solid white",
            alignItems: "center",
            borderRadius: "5px",
            marginTop: '30px',
          }}
        >
          <Typography className="search_empty_text" variant="caption" color="text.secondary">
            Looking for something else ?
          </Typography>
          <Typography onClick={handlewhatsapp} className="chat_enquire_text pointer" variant="caption" noWrap color="text.secondary">
            You may Chat & Enquire
          </Typography>
        </div>
      )}

      {isFruitsAndVegMerchant === "GROCERY" && (
        <>
          <Divider className="dividers" style={{ marginBottom: '20px' }} />
          <Typography className="disclaimer" variant="caption" color="text.secondary" style={{ paddingBottom: '40px' }}>
            <div className="disclaimer">Disclaimer:</div>
            <p className="disclaimer">All images are for representation purpose only. While every effort is made to provide up to date product information, it is recommended to read product labels, warnings, and directions
              before using or consuming a product. For other products or any additional information, please contact the seller over chat or call. In case you wish to contact the manufacturer, the
              address mentioned on the product label should be used.</p>
          </Typography>
        </>
      )}

      <br />

      {/* View cart */}
      <Dialog
        open={viewCartVisible}
        fullScreen
        classes={{
          paper: classes.bigscreens,
        }}
        scroll="paper"
        PaperProps={{ style: { marginTop: "120px", borderRadius: "0px", paddingBottom: `${totalCount > 0 ? "120px" : "60px"}` } }}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
      >
        <img
          src={close_icon}
          // style={{
          //   position: "fixed",
          //   left: "90%",
          //   zIndex: "2",
          //   top: "37px",
          //   width: "30px",
          // }}
          className={classes.closeIcon}
          onClick={() => {
            setViewCartVisible(!viewCartVisible);
          }}
          alt="close"
        />
        <DialogTitle sx={{ m: 0, p: 0 }}>
          <div className="cart_dialog_header">
            {/* <FontAwesomeIcon className='start-element' onClick={() => setViewCartVisible(!viewCartVisible)} icon={faChevronLeft} style={{ color: '#0375b1', height: '17px', marginRight: '5px' }} /> */}
            <span className="cart_dialog_header_title">Order Summary</span>
            <span className="cart_dialog_header_item"> {totalCount && totalCount ? totalCount : 0} Items</span>
            <span className="savings_text" style={{ display: `${savings === 0 ? 'none' : 'inline'}` }}> Rs {savings} savings</span>
          </div>
        </DialogTitle>
        <DialogContent className="dialog_content_grid2">
          {cartData.length !== 0 && totalCount > 0 ? (
            cartData.length > 0 ? (
              cartData.filter((data) => data.variants === undefined || data.variants === null || data.variants === "" || data.variants.length === 0)
                .map((data, index) => {

                  return (
                    <>
                      <Card className="cart_container" key={data.id} style={{ paddingTop: '20px' }}>
                        {console.log("prAddress/", priorityAddress)}
                        <CardContent style={{ padding: "0px", display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                          <div style={{ flex: 2, display: 'flex', alignItems: 'flex-start' }}>
                            <img src={data.image_url[0]} style={{ width: '50px', marginLeft: '10px', borderRadius: '5px' }} alt="NA" />
                            <div style={{ display: 'flex', marginTop: '2px', paddingRight: '10px' }}>
                              <img src={data.foodType === "Veg" ? veg_icon : data.foodType === "Non-Veg" ? non_veg_icon : egg_icon} style={{ width: "15px", height: '15px', marginBottom: "1px", display: `${data.foodType !== null && data.foodType !== undefined && data.foodType !== "" ? "block" : "none"}`, marginLeft: '5px' }} alt="food-type" />
                              <p className="cart_normal_pd_name" >
                                {data.item_name}
                              </p>
                            </div>

                          </div>
                          <span
                            style={{ paddingBottom: "2px", position: "relative" }}
                          >

                            <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '85px', height: '28px', alignItems: 'center', border: '1px solid rgba(18,93,141)', paddingTop: '2px', borderRadius: '5px', backgroundColor: 'white' }}>
                              <p className="plus_minus_text_grid" style={{ flex: 1, textAlign: 'center' }} onClick={() => decreaseCount(data.count, data.id, "fromCart")}>-</p>
                              <p className="product_count2" style={{ flex: 1, textAlign: 'center', paddingTop: '1px' }}>{data.count}</p>
                              <p className="plus_minus_text_grid" style={{ flex: 1, textAlign: 'center' }} onClick={() => addCount(data.count, data.id, "fromCart")}>+</p>

                            </div>
                          </span>
                          <div style={{ paddingTop: '2px', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', minWidth: '80px', paddingRight: '5px' }}>

                            <Typography
                              noWrap
                              variant="subtile1"
                              color="text.secondary"
                              className="cart_pd_price"
                              style={{ color: 'black' }}
                            >
                              {data.price && data.price ? ` ${data.count > 0 ? ((data.discounted_rate !== null && data.discounted_rate !== undefined && data.discounted_rate !== "") ? currency(data.discounted_rate * data.count) : currency(data.price * data.count)) : ((data.discounted_rate !== null && data.discounted_rate !== undefined && data.discounted_rate !== "") ? currency(data.discounted_rate) : currency(data.price))}` : "₹ 0"}
                            </Typography>
                            {(data.discounted_rate !== null && data.discounted_rate !== undefined && data.discounted_rate !== "") ? (
                              <Typography
                                noWrap
                                variant="subtile1"
                                color="text.secondary"
                                className="cart_old_price_nodisplay"
                                style={{ textDecoration: 'line-through' }}
                              >
                                {data.price && data.price ? ` ${data.count > 0 ? currency(data.price * data.count) : currency(data.price)}` : "₹ 0"}
                              </Typography>
                            ) : ''}
                          </div>

                        </CardContent>
                        <CardContent style={{ padding: 0 }}>

                          {data.instruction === "" ? (
                            <div style={{ paddingLeft: '10px' }} class="special_instruction">
                              <div className="maincard_instruction1" onClick={() => modalopen(data.id, data.instruction, instructionFromHomePage = true, 'fromCart')}>
                                <div style={{ color: '#125d8d' }} className="cart_plustext">+ </div>
                                <div style={{ color: '#808081' }} className="cart_specialInstructionText">Special Instructions</div>
                              </div>
                            </div>
                          ) : (
                            <div style={{ paddingLeft: '10px' }} className="edit_instruction" onClick={() => modalopen(data.id, data.instruction, instructionFromHomePage = true, 'fromCart')}>
                              <img src={edit_icon} alt="icon" style={{ width: '14px', height: '18px' }}></img>
                              <p className="specialEditText">{data.instruction} </p>
                            </div>
                          )}



                        </CardContent>

                      </Card>
                      {/* <Divider style={{opacity: 0.2}} className="dividers" /> */}
                    </>
                  );
                })
            ) : (
              <div
                style={{
                  display: "flex",
                  textAlign: "center",
                  color: "blue",
                  fontWeight: "bold",
                  flexDirection: "column",
                  border: "2px solid white",
                  alignItems: "center",
                  borderRadius: "5px",
                }}
              >
                <Typography variant="caption" noWrap color="text.secondary" style={{ color: "black", textAlign: "center" }}>
                  No product available
                </Typography>
              </div>
            )
          ) : (
            <div
              style={{
                display: "flex",
                textAlign: "center",
                color: "blue",
                fontWeight: "bold",
                flexDirection: "column",
                border: "2px solid white",
                alignItems: "center",
                borderRadius: "5px",
              }}
            >
              <Typography variant="caption" noWrap color="text.secondary" style={{ color: "black", textAlign: "center" }}>
                Your cart is empty{" "}
              </Typography>
            </div>
          )}
          {cartData.length > 0 && totalCount > 0 ? (
            cartData.length > 0 ? (
              cartData.map((modData, index) => (
                (modData.variants && modData.variants.length !== 0) ? (
                  modData.variants.map((data) => {
                    return (
                      <>
                        {data.count > 0 ? (

                          <>
                            <Card style={{ paddingTop: '10px' }} className="cart_container" key={data.id}>
                              {console.log("CartModData==>", modData)}
                              <CardContent style={{ padding: "0px", display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                <div style={{ flex: 2, display: 'flex', alignItems: 'flex-start' }}>
                                  <img src={modData.image_url[0]} style={{ width: '45px', marginLeft: '10px', borderRadius: '5px' }} alt="NA" />
                                  <div style={{ display: 'flex', marginTop: '2px', paddingRight: '10px' }}>
                                    <img src={modData.foodType === "Veg" ? veg_icon : modData.foodType === "Non-Veg" ? non_veg_icon : egg_icon} style={{ width: "15px", height: '15px', marginBottom: "1px", display: `${modData.foodType !== null && modData.foodType !== undefined && modData.foodType !== "" ? "block" : "none"}`, marginLeft: "5px", marginBottom: '5px' }} alt="food-type" />
                                    <p className="cart_normal_pd_name" variant="h6" color="text.secondary" >
                                      {data.variantName}
                                    </p>

                                  </div>


                                </div>
                                <span
                                  style={{ paddingBottom: "2px", position: "relative" }}
                                >

                                  <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '85px', height: '28px', alignItems: 'center', border: '1px solid rgba(18,93,141)', paddingTop: '2px', borderRadius: '5px', backgroundColor: 'white' }}>
                                    <p className="plus_minus_text_grid" style={{ flex: 1, textAlign: 'center' }} onClick={() => decreaseCountForVariant(data.count, data.varientId, data.id, "fromCart")}>-</p>
                                    <p className="product_count2" style={{ flex: 1, textAlign: 'center', paddingTop: '1px' }}>{data.count}</p>
                                    <p className="plus_minus_text_grid" style={{ flex: 1, textAlign: 'center' }} onClick={() => addCountForVariant(data.count, data.varientId, data.id, "fromCart")}>+</p>
                                  </div>
                                </span>
                                <div style={{ paddingTop: '2px', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', minWidth: '80px', paddingRight: '5px' }}>

                                  <Typography
                                    noWrap
                                    variant="subtile1"
                                    color="text.secondary"
                                    className="cart_pd_price"
                                    style={{ color: 'black' }}
                                  >
                                    {data.variantPrice && data.variantPrice ? ` ${data.count > 0 ? ((data.variantDisPrice !== null && data.variantDisPrice !== undefined && data.variantDisPrice !== "") ? currency(data.variantDisPrice * data.count) : currency(data.variantPrice * data.count)) : ((data.variantDisPrice !== null && data.variantDisPrice !== undefined && data.variantDisPrice !== "") ? currency(data.variantDisPrice) : currency(data.variantPrice))}` : "₹ 0"}
                                  </Typography>
                                  {(data.variantDisPrice !== null && data.variantDisPrice !== undefined && data.variantDisPrice !== "") ? (
                                    <Typography
                                      noWrap
                                      variant="subtile1"
                                      color="text.secondary"
                                      className="cart_old_price_nodisplay"
                                      style={{ textDecoration: 'line-through' }}
                                    >
                                      {data.variantPrice && data.variantPrice ? ` ${data.count > 0 ? currency(data.variantPrice * data.count) : currency(data.variantPrice)}` : "₹ 0"}
                                    </Typography>
                                  ) : ''}

                                </div>

                              </CardContent>
                              <CardContent style={{ padding: 0 }}>
                                {data.instruction === "" ? (
                                  <div style={{ paddingLeft: '10px' }} class="special_instruction">
                                    <div className="maincard_instruction1" onClick={() => modalopen(data.varientId, data.instruction, instructionFromHomePage = false, 'fromCart')}>
                                      <div style={{ color: '#125d8d' }} className="cart_plustext">+ </div>
                                      <div style={{ color: '#808081' }} className="cart_specialInstructionText">Special Instructions</div>
                                    </div>
                                  </div>
                                ) : (
                                  <div style={{ paddingLeft: '10px' }} className="edit_instruction" onClick={() => modalopen(data.varientId, data.instruction, instructionFromHomePage = false, 'fromCart')}>
                                    <img src={edit_icon} alt="icon" style={{ width: '14px', height: '18px' }}></img>
                                    <p className="specialEditText">{data.instruction} </p>
                                  </div>
                                )}

                              </CardContent>

                            </Card>
                            {/* <Divider className="dividers" /> */}
                          </>

                        ) : ''}
                      </>
                    )
                  })
                ) : ''
              )
              )) : '') : ''}

          {cartData.length !== 0 && (
            <div className="custom_product_container">
              <p onClick={() => setInfoPopUp(true)} className="bahnschrift cutom_pd_header">Did not find all items? <span className="bahnschrift write_here_text">Write here</span><img src={info} className="info_img" alt="info" /></p>
              <div className="custom_pd_box">
                {console.log("custom--->", customInstructions)}
                {customInstructions.length && customInstructions.map((value, index) => (
                  <div id={`custom_pd${index}`} className="custom_pd">
                    <div className="green_bullet" />
                    <input value={value} className="custom_pd_input" placeholder="Add item" onChange={(event) => handleCustomProduct(event, index)} onInput={(e) => { e.target.value = e.target.value.toString().slice(0, 50); }} />
                  </div>
                ))}
              </div>
            </div>
          )}

          <div style={{ display: `${totalCount > 0 ? 'block' : 'none'}` }} className='bill_container'>
            {isLoading ? (
              <div style={{ height: '200px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <BeatLoader color="#36d7b7" loading={true} />
              </div>
            ) : (
              <>
                <p className='bill_subtext bill_large_font_size' style={{ color: 'black', marginBottom: '5px' }}>Bill Details</p>
                <div>
                  {oldTotalPrice !== totalPrice && (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p className='bill_subtext'>MRP Total</p>
                      <p style={{ color: 'black' }} className='bill_amount'>{currency(oldTotalPrice)}</p>
                    </div>
                  )}

                  {savings !== 0 && (
                    <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '5px' }}>
                      <p className='bill_subtext'>Savings</p>
                      <p style={{ color: '#2bb673' }} className='bill_amount'>- {currency(savings)}</p>
                    </div>
                  )}
                  <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '5px' }}>
                    <p className='bill_subtext'>Item Total</p>
                    <p style={{ color: 'black' }} className='bill_amount'>{currency(totalPrice)}</p>
                  </div>
                  <div className='cart_divider' />
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p className='bill_subtext'>Delivery Charges (for {logisticData.distance}km)</p>
                    <p style={{ color: 'black' }} className='bill_amount'>{logisticData.deliveryCharge ? currency(logisticData.deliveryCharge) : '₹0'}</p>
                  </div>
                  {(logisticData.nightChargeMsg && logisticData.nightChargeMsg !== 'null') && (
                    <p className="cashback_subtext">{logisticData.nightChargeMsg}</p>
                  )}
                  {(logisticData.deliveryMsg && logisticData.deliveryMsg !== "Your location is outside seller's delivery area") ? (
                    <p className="cashback_subtext">{logisticData.deliveryMsg && logisticData.deliveryMsg}</p>
                  ) : (
                    <p className="cashback_subtext">{logisticData.orderMsg && logisticData.orderMsg}</p>
                  )}
                  {(logisticData.govtTax && logisticData.govtTax != 0) && (
                    <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '5px' }}>
                      <p className='bill_subtext'>Govt Taxes</p>
                      <p style={{ color: 'black' }} className='bill_amount'>{currency(logisticData.govtTax)}</p>
                    </div>
                  )}
                  <div className='cart_divider' />
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p className='bill_subtext bill_large_font_size' style={{ color: 'black' }}>Amount To Pay</p>
                    <p className='bill_amount_bold' style={{ color: 'black' }}>{logisticData.payAmount ? currency(logisticData.payAmount) : currency(totalPrice)}</p>
                  </div>
                  {(logisticData.cashback || logisticData.cashback !== 0) && (
                    <>
                      <div className='cart_divider' />
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p className="bill_large_font_size" style={{ color: 'black', fontWeight: '300', marginBottom: 0, marginTop: 0 }}>Cashback</p>
                        <p className='bill_amount' style={{ color: '#2bb673' }}>{priorityAddress === '' ? '₹0' : currency(logisticData.cashback)}</p>
                      </div>
                      <p className="cashback_subtext">Will be credited within 24 hours</p>
                    </>
                  )}

                </div>
              </>
            )}
          </div>

        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: 'flex-start', padding: "0px 0px 16px 0px", borderTopLeftRadius: '10px', borderTopRightRadius: '10px', borderTop: '1px solid lightgrey' }}>


          {cartData.length !== 0 ? (addressData !== undefined && addressData.length !== 0 && priorityAddress !== 'currentLocation') ? (
            <div onClick={() => setShowSelectAddress(true)} style={{ display: 'flex', alignItems: 'center', flex: 1, marginLeft: '10px', marginRight: '10px' }} className="priority_address pointer">
              <FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: '#e48e39', height: '30px' }} />
              <div style={{ flex: 1, marginLeft: '10px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p className="deliver_to_home_text bahnschrift">Deliver to {priorityAddress.label}</p>
                  <p className="address_change_text bahnschrift">Change</p>
                </div>
                <p className="addresslines_text bahnschrift">{priorityAddress.address}</p>
                {console.log("Adde", priorityAddress)}
              </div>
            </div>
          ) : priorityAddress === 'currentLocation' ? (
            <div className="cart_address pointer" onClick={() => setShowSelectAddress(true)}>
              <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                <p className="cart_addaddress_text bahnschrift">Select address</p>
                {console.log("addrssData==>", addressData, "==>", priorityAddress)}
                <p className="cart_addaddress_subtext bahnschrift">For delivery of this order</p>
              </div>
              <FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: '#e48e39', marginRight: '10px', height: '30px' }} />
            </div>
          ) : (
            <div className="cart_address pointer" onClick={() => history.push('/map')}>
              <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                <p className="cart_addaddress_text bahnschrift">Add address</p>
                {console.log("addrssData==>", addressData, "==>", priorityAddress)}
                <p className="cart_addaddress_subtext bahnschrift">For delivery of this order</p>
              </div>
              <FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: '#e48e39', marginRight: '10px', height: '30px' }} />
            </div>
          ) : ''}


          {totalCount > 0 &&
            (shopOff === 0 &&
              (logisticData.deliveryMsg !== "Your location is outside seller's delivery area") ? (
              <div onClick={isCheckout === 1 ? handlesendoder : ((loader || isLoading) ? null : paymentProcess)} style={{ marginLeft: 0 }} className="cart_bottom_bar">
                <span className="view_cart_item">Total : {logisticData.payAmount && logisticData.payAmount ? currency(logisticData.payAmount) : currency(totalPrice)} <span className="send_order_old_price" style={{ textDecoration: "line-through" }}>{(oldTotalPrice && oldTotalPrice && totalPrice !== oldTotalPrice) ? currency(oldTotalPrice) : ''}</span></span>
                <span className="view_cart_text">{loader ? (<ClipLoader color="#36d7b7" />) : (isCheckout === 0 ? 'Pay Now' : 'Send Order')}</span>

              </div>
            ) : shopOff === 1 ? (
              <div style={{ marginLeft: 0 }} className="cart_bottom_bar_shop_closed">
                <p className="shop_closed_text">Shop closed. Currently not accepting orders</p>
              </div>
            ) : (
              <div style={{ marginLeft: 0 }} className="cart_bottom_bar_shop_closed">
                <p className="shop_closed_text">{logisticData.deliveryMsg}</p>
              </div>
            ))}
        </DialogActions>


      </Dialog>

      {/*   Special Instructions */}
      <Dialog open={modalVisible} PaperProps={{ sx: { width: "70%", maxWidth: '307px' } }}>
        <div className="dialog_instruction">
          Special Instructions
          <CloseIcon
            className="close_btn"
            onClick={() => {
              setModalVisible(!modalVisible);
            }}
          />
        </div>
        <DialogContent className="dialogBox">
          <div
            style={{
              color: "blue",
              fontWeight: "bold",
              border: "2px solid white",
              borderRadius: "5px",
              margin: "4px 15px 15px 15px",
              width: "100%",
            }}
          >
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: "#0375B0" },
                },
              }}
              id="outlined-basic"
              className="dialog_instruction_inputs"
              label="Max 30 characters"
              variant="outlined"
              value={instruction}
              multiline
              maxRows={2}
              onChange={(e) => {
                let input = e.target;
                if (input.value.includes('  ')) {
                  input.value = input.value.replace(/\s+/g, ' ');
                }
                if (input.value.charAt(0) === ' ') {
                  input.value = input.value.substring(1);
                }
                setInstruction(input.value);
              }}
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, 30);
              }}
            />
          </div>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "center", padding: "0px 0px 16px 0px" }}>
          <button className="dialog_instruction_buttons" onClick={saveInstruction}>
            Save
          </button>
        </DialogActions>
      </Dialog>

      {/* Extra Instruction Info pop up */}
      <Dialog open={infoPopUp} PaperProps={{ sx: { width: "70%", maxWidth: '307px', padding: '1rem', borderRadius: '1rem' } }}>
        <div className="bahnschrift info_content_header">
          How it works ?
          <CloseIcon
            className="close_btn"
            onClick={() => {
              setInfoPopUp(!infoPopUp);
            }}
          />
        </div>
        <div>
          <div className="info_content">
            <div className="green_bullet" />
            <p className="info_content_text">Create your list</p>
          </div>
          <div className="info_content">
            <div className="green_bullet" />
            <p className="info_content_text">Business responds on chat</p>
          </div>
          <div className="info_content">
            <div className="green_bullet" />
            <p className="info_content_text">Separate payment request is sent</p>
          </div>
        </div>
      </Dialog>

      {/* View Cart Bar */}
      {totalCount > 0 ? (
        <div className="cart_bottom_bar" onClick={(localStorage.getItem("User") ? handleviewcart : () => setLoginVisible(true))}>
          <span className="view_cart_item">
            {totalCount && totalCount ? totalCount : 0} Items &#124; {totalPrice && totalPrice ? currency(totalPrice) : "₹" + "0"}
          </span>
          <span className="view_cart_text">View Cart</span>
        </div>
      ) : (
        ""
      )}

      {/* Order summary band */}
      {orderPlaced && (
        <div onClick={handleOrderSummary} className="order_summary_band pointer">

          <div style={{ display: 'flex', alignItems: 'center' }}>

            <img src={order_placed_green} style={{ width: '40px' }} alt="NA" />
            <div style={{ marginLeft: '10px', marginTop: '5px' }}>
              <p style={{ marginBottom: 0 }} className="order_summary_band_text">Order with {businessName}</p>
              <p className="track_status_text bahnschrift" style={{ marginTop: 0 }}>Click to track status</p>
            </div>

          </div>

          <FontAwesomeIcon className='start-element' icon={faChevronRight} style={{ color: '#0375b1', height: '20px', marginLeft: '20px', marginRight: '10px' }} />

        </div>
      )}

      {/* App download Banner */}
      {showDownloadBanner && (
        <div className="app_download_banner">
          <CloseIcon
            className="download-banner-close"
            style={{ pointerEvents: 'auto', marginTop: '5px' }}
            onClick={() => setShowDownloadBanner(false)}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={chattybao} style={{ width: '35px', marginRight: '10px' }} alt="logo" />
            <div onClick={() => window.open("https://chattybaouser.page.link/76UZ")}>
              <p className="download_text" style={{ margin: 0 }} >Download the App</p>
              <p className="download_sub_text" style={{ margin: 0 }}>For better experience & offers</p>
            </div>
          </div>
          <button onClick={() => window.open("https://chattybaouser.page.link/76UZ")} className="download_btn">Download</button>
        </div>
      )}



      {/* Variants pop up */}
      <Dialog classes={{ paper: classes.botm_dialog }} open={variantsVisible} PaperProps={{ sx: { width: "100%", marginTop: "auto", marginBottom: "0", marginLeft: '0', marginRight: "0" } }} onClose={() => setVariantsVisible(!variantsVisible)}>
        <div className="dialog_instruction_options">
          Options
          <img
            src={close_btn}
            className="close_btn2"
            onClick={() => {
              setVariantsVisible(!variantsVisible);
            }}
            alt="close"
          />
        </div>
        <DialogContent style={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: 0 }}>
          <div>

            {variants.map((datad) => {
              let offer = '';
              console.log("discount value", data.discounted_rate);
              if (datad.variantDisPrice !== null && datad.variantDisPrice !== "" && datad.variantDisPrice !== undefined) {
                offer = 100 - (datad.variantDisPrice / datad.variantPrice) * 100;
                offer = Math.round(offer)
              }
              return (
                <>
                  <Card className="variantCard" key={datad.varientId}>
                    <div className="variant_container">
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '60%', paddingBottom: '10px', paddingLeft: '10px' }}>
                        <div>
                          {variantFoodType !== 'none' ? (
                            <img src={(variantFoodType === 'Veg' ? veg_icon : variantFoodType === 'Non-Veg' ? non_veg_icon : egg_icon)} style={{ height: '19px', paddingRight: '10px' }} alt="NA" />
                          ) : ''}
                          {variantIsBestseller ? (
                            <img src={variant_bestseller} style={{ height: '23px' }} alt="tag" />
                          ) : ''}

                        </div>
                        {offer !== '' ? (
                          <div style={{ position: 'relative' }}>
                            <img src={discount_tag_variants} style={{ height: "21px" }} alt="tag" />
                            <p style={{ color: 'white', left: '7%' }} className="offer_text2">{offer}% OFF</p>
                          </div>
                        ) : ''}

                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <CardContent className="main_image_container_variant" style={{ flex: 1 }}>
                          <Typography className="variant-name" color="text.secondary" style={{ lineHeight: "3", display: 'inline' }}>
                            {datad.variantName}
                          </Typography>
                        </CardContent>
                        {offer !== '' ? (
                          <div style={{ flex: 1, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>


                            <Typography
                              variant="subtile1"
                              className={`${offer !== "" ? 'normal_item' : 'price_without_discount'}`}
                              style={{ textAlign: "left", color: "black", fontWeight: "600", paddingTop: "4px", marginLeft: '25px', lineHeight: 'normal', wordBreak: 'break-all' }}
                            >
                              {datad.variantPrice && datad.variantPrice ? currency(datad.variantPrice * 1) : "₹ 5"}
                            </Typography>

                            <div style={{ display: 'flex', marginLeft: '25px' }}>
                              <Typography
                                variant="subtile1"
                                color="text.secondary"
                                className="product_offer_price"
                                style={{ textAlign: "left", color: "#000000", fontSize: "10px", paddingTop: "4px", paddingRight: '3px', display: `${offer !== "" ? 'inline' : 'none'}`, wordBreak: 'break-all' }}
                              >
                                {datad.variantDisPrice && datad.variantDisPrice ? currency(datad.variantDisPrice * 1) : " "}
                              </Typography>
                            </div>

                          </div>
                        ) : (
                          <div style={{ flex: 1, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                            <div style={{ display: 'flex', marginLeft: '25px' }}>
                              <Typography
                                variant="subtile1"
                                color="text.secondary"
                                className="product_offer_price"
                                style={{ textAlign: "left", color: "#000000", fontSize: "10px", paddingTop: "4px", paddingRight: '3px', wordBreak: 'break-all' }}
                              >
                                {datad.variantPrice && datad.variantPrice ? currency(datad.variantPrice * 1) : "₹ 5"}
                              </Typography>

                              {variantUnit !== '' ? (
                                <div style={{ padding: 0, marginTop: '8px', wordBreak: 'break-all' }} className="pdLayover_unit">{variantUnit}</div>
                              ) : ''}
                            </div>

                          </div>
                        )}

                        <CardContent className="pdLayover_addContainer" style={{ paddingLeft: "9%" }}>
                          {datad.count > 0 ? (
                            <span style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                              <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '85px', height: '28px', alignItems: 'center', border: '1px solid rgba(18,93,141,0.3)', paddingTop: '2px', borderRadius: '5px', backgroundColor: 'white' }}>
                                <p className="plus_minus_text_grid pointer" style={{ flex: 1, textAlign: 'center' }} onClick={() => decreaseCountForVariant(datad.count, datad.varientId, datad.id, null)}>-</p>
                                <p className="product_count2" style={{ flex: 1, textAlign: 'center', paddingTop: '1px' }}>{datad.count}</p>
                                <p className="plus_minus_text_grid pointer" style={{ flex: 1, textAlign: 'center' }} onClick={() => addCountForVariant(datad.count, datad.varientId, datad.id, null)}>+</p>
                              </div>
                            </span>
                          ) : (
                            <div onClick={() => addCountForVariant(datad.count, datad.varientId, datad.id, null)} style={{ width: '85px', backgroundColor: 'white', borderRadius: '5px', border: '1px solid rgba(18,93,141,0.3)', height: '28px', cursor: 'pointer' }}>
                              <p className="add_text">ADD</p>
                            </div>


                          )}

                        </CardContent>
                      </div>

                    </div>


                  </Card>
                  {/* <Divider className="dividers" /> */}
                </>
              )
            }
            )}
          </div>


        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "center", padding: "0px 0px 15px 0px" }}>
          <img src={confirm_btn} style={{ height: "39px", width: "95%" }} onClick={() => setVariantsVisible(false)} alt='confirm' />
        </DialogActions>
      </Dialog>

      {/* Product details */}
      <Dialog
        open={productDetailVisible}
        className=""
        fullScreen
        classes={{
          paper: classes.bigscreens,
        }}
        PaperProps={{ sx: { width: "100%", marginTop: 'auto', marginBottom: 0, height: '80%', paddingRight: 0 } }}
      >
        <img
          src={close_icon}
          className={classes.closeIcon}
          onClick={() => {
            setProductDetailVisible(!productDetailVisible);
          }}
          alt='close'
        ></img>
        <div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ position: 'relative' }}>
              {productImage.length > 1 ? (
                <Slider {...settings}>
                  {productImage.map((data, index) => (
                    <TransformWrapper panning={{ disabled: true }}>
                      <TransformComponent>
                        <div
                          style={{
                            display: "flex",
                            textAlign: "center",
                            color: "blue",
                            fontWeight: "bold",
                            flexDirection: "column",
                            border: "0px",
                            alignItems: "center",
                            borderRadius: "0px",
                            width: "100%",
                            margin: "0px auto",
                          }}
                        >
                          <img
                            src={data}
                            alt="img"
                            style={{
                              width: "100% !imprtant",
                              objectFit: "cover",
                              borderRadius: "0px",
                            }}
                            height={363}
                            onClick={(e) => {
                              alert("0");
                              if (this.state.isMoving) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </TransformComponent>
                    </TransformWrapper>
                  ))}
                </Slider>
              ) : (
                <img src={productImage[0]} style={{ width: '100%' }} alt="img" />
              )}
              {console.log("pdImage==>", productImage)}

              <div style={{ display: `${pdOffer !== '' ? 'in-line' : 'none'}` }}>
                <img src={discount_tag} style={{ width: '75px', display: 'block', position: 'absolute', top: 0, bottom: 0, right: 0, left: 0 }} />
                <p className='ofr_text'>{pdOffer}%<br />OFF</p>
              </div>
              {ifBestSeller ? (
                <img src={bestSeller_tag} style={{ width: '140px', position: 'absolute', top: '3%', right: 0, left: 'inherit', display: 'block' }} />
              ) : ''}

              <div className='button_container'>
                <span style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handlewhatsapp}>
                  <img src={chat_icon} style={{ height: '18px', paddingRight: '10px' }} alt="chat" />
                  <p className='button'>Chat</p>
                </span>
                <span className='hor_divider'></span>
                <span style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleShare}>
                  <img src={share_icon} style={{ height: '18px', paddingRight: '10px' }} alt="share" />
                  <p className='button'>Share</p>
                </span>
              </div>
            </div>

            <div style={{ marginTop: '20px' }}>
              <div className='pd_details'>
                <p className="pd_brandName">{pdBrand}</p>
                <p style={{ wordBreak: 'break-word' }} className='pd_name'>{productName}</p>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <p className={pdOffer !== '' ? 'old_price' : 'discount_price'}>{currency(productPrice * 1)}</p>
                    <p style={{ display: `${pdOffer !== '' ? 'in-line' : 'none'}` }} className='discount_price'>{currency(productDiscPrice * 1)}</p>
                    <p className='unit'>{productUnit}</p>
                  </div>
                  {productCount > 0 ? (
                    <span
                      style={{ paddingBottom: "2px", position: "relative" }}
                    >

                      <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '85px', height: '28px', alignItems: 'center', border: '1px solid rgba(18,93,141)', paddingTop: '2px', borderRadius: '5px', backgroundColor: 'white', marginRight: "10px" }}>
                        <p className="plus_minus_text_grid pointer" style={{ flex: 1, textAlign: 'center' }} onClick={productVariantExists ? () => handleVariantsButton(varDataFromPd) : () => decreaseCount(productCount, productId)}>-</p>
                        <p className="product_count2" style={{ flex: 1, textAlign: 'center', paddingTop: '1px' }}>{productCount}</p>
                        <p className="plus_minus_text_grid pointer" style={{ flex: 1, textAlign: 'center' }} onClick={productVariantExists ? () => handleVariantsButton(varDataFromPd) : () => addCount(productCount, productId)}>+</p>

                      </div>
                    </span>
                  ) : (productAvailabilityData !== '' ? (
                    <span
                      className="maincard_button"
                      style={{
                        marginRight: '10px'
                      }}
                    >
                      <div style={{ width: '100px', backgroundColor: 'white', borderRadius: '5px', border: '1px solid #D9D9D9' }}>
                        <p className="product_availability_text">{productAvailabilityData}</p>
                      </div>
                    </span>
                  ) : (
                    <span
                      className="maincard_button pointer"
                      style={{
                        marginRight: '10px'
                      }}
                      onClick={orderPlaced ? giveToastMessage : (productVariantExists ? () => handleVariantsButton(varDataFromPd) : () => addCount(productCount, productId, "fromHome"))}
                    >
                      <div style={{ width: '85px', backgroundColor: 'white', borderRadius: '5px', border: '1px solid #125d8d', height: '28px' }}>
                        {productVariantExists ? (
                          <p className="options_text">{`${pdVariantLength} Options`}</p>
                        ) : (
                          <p className="add_text">ADD</p>
                        )}

                      </div>
                    </span>
                  ))}


                </div>
                <div style={{ display: `${productDesc === '' || productDesc === null || productDesc === undefined ? 'none' : 'block'}` }}>
                  <Divider />
                  <p className='desc_heading'>Product Description</p>
                  <p className='desc_text' style={{ wordBreak: 'break-word' }}>{productDesc}</p>
                </div>

                <Divider />
                <p className='desc_heading'>Terms & Conditions</p>
                <p className='desc_text'>All images are for representation purpose only. While every effort has been made to maintain accurate and up to date product related content, it is recommended to read product labels, batch and manufacturing details along with warnings, and directions before using or consuming a packaged product. For other products or any additional information, please contact the seller and incase you wish to contact the manufacturer, the address mentioned on the product label should be used.</p>
                <Divider />
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/* Login layover */}
      <Dialog classes={{ paper: classes.botm_dialog }} open={loginVisible} PaperProps={{ sx: { width: "100%", marginTop: "auto", marginBottom: "0", marginLeft: '0', marginRight: "0", height: '45%', paddingTop: '30px' } }} onClose={() => setLoginVisible(!loginVisible)}
      >
        <img
          src={close_icon}
          style={{
            position: "fixed",
            left: "90%",
            zIndex: "2",
            top: "53%",
            width: "30px",
          }}
          className="login_close"
          onClick={() => {
            setLoginVisible(false);
          }}
          alt="close"
        />
        <div>
          <label style={{ paddingLeft: '4%' }} className="mobile_number_text">Enter Mobile Number</label>
          <center>
            <input value={phoneNumber} className="number_input" type="number" onChange={(event) => handlePhoneNumber(event.target.value)} onKeyDown={handleKeyDown} />

          </center>
          <div style={{ display: 'flex', marginTop: '15px', marginBottom: '15px', paddingLeft: '4%', alignItems: 'center' }}>
            <input max={10} className="agreement_check_box" checked={agreementCheck} onChange={() => setAgreementCheck(!agreementCheck)} type="checkbox" />
            <p className="agreement_text" style={{ paddingLeft: '10px' }}>I agree to <span onClick={() => window.open("https://chattybao.com/user-agreement")} className="loginpage_link">Terms and Conditions</span> and <span onClick={() => window.open("https://chattybao.com/privacy-policy")} className="loginpage_link">Privacy Policy</span></p>
          </div>
          <center>
            <button className={agreementCheck && phoneNumber.length === 10 ? "agreement_continue_btn" : "agreement_continue_btn_disabled"} onClick={loader ? null : handleSubmit} disabled={agreementCheck && phoneNumber.length === 10 ? false : true}>{loader ? (<ClipLoader color="#36d7b7" />) : "Continue"}</button>
            <div id="recapcha-container"></div>
          </center>

        </div>
      </Dialog>

      {/* OTP layover */}
      <Dialog classes={{ paper: classes.botm_dialog }} open={otpVisible} PaperProps={{ sx: { width: "100%", marginTop: "auto", marginBottom: "0", marginLeft: '0', marginRight: "0", height: '45%', paddingTop: '30px' } }}
      >
        <img
          src={close_icon}
          style={{
            position: "fixed",
            left: "90%",
            zIndex: "2",
            top: "53%",
            width: "30px",
          }}
          className="login_close"
          onClick={() => {
            setOtpVisible(false);
          }}
          alt="close"
        />
        <div>
          <label style={{ paddingLeft: '4%', marginBottom: 0 }} className="mobile_number_text">Enter Verification Code</label>
          <p className="senton_mobile_text">sent on mobile number</p>
          <center>
            <input value={otp} className="number_input" type="number" onChange={(event) => { handleOtpInput(event.target.value); setOtpVerified(true); }} />
          </center>
          {!otpVerified && (
            <p className="otp_wrong_text">Entered wrong OTP</p>
          )}
          <center>
            <button className={(otp && otp.length === 6) ? "agreement_continue_btn" : "agreement_continue_btn_disabled"} onClick={loader ? null : verifyOTp}>{loader ? (<ClipLoader color="#36d7b7" />) : "Verify"}</button>
          </center>

        </div>
      </Dialog>

      {/* select address layover */}
      <Dialog
        open={showSelectAddress}
        classes={{
          paper: classes.bigscreens,
        }}
        PaperProps={{ sx: { width: "100%", marginTop: "auto", marginBottom: "0", marginLeft: '0', marginRight: "0", minHeight: '50%', paddingLeft: '10px', paddingTop: '30px', overflow: 'visible' } }} onClose={() => setShowSelectAddress(!showSelectAddress)}
      >
        <img
          src={close_icon}
          style={{
            width: "30px",
            alignSelf: 'flex-end',
            marginTop: '-50px',
            marginRight: '10px'
          }}
          onClick={() => {
            setShowSelectAddress(!showSelectAddress);
          }}
          alt="close"
        ></img>
        <DialogTitle sx={{ m: 0, p: 0 }}>
          <p className="select_address_title bahnschrift">Choose a delivery address</p>
        </DialogTitle>

        <DialogContent className="dialog_content_grid2" style={{ paddingBottom: "0px", marginBottom: 0 }}>
          <div>
            {addressData.map((address) => (
              <>
                <div onClick={() => { handleChangeAddress(address); setShowSelectAddress(false); }} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}>
                  <div>
                    <p className='address_head bahnschrift'>{address.label ? address.label : 'Others'}</p>
                    <p className="wholeaddress_text bahnschrift">{`${address.address_line1 !== '' ? address.address_line1 + ', ' : ''}`}
                      {`${(address.apartment_or_area !== '' && address.apartment_or_area !== null) ? address.apartment_or_area + ', ' : ''}`}
                      {`${address.address_line2 !== '' ? address.address_line2 : ''}`}</p>
                  </div>
                  <FontAwesomeIcon className='start-element' icon={faChevronRight} style={{ color: '#0375b1', height: '20px', marginLeft: '20px', marginRight: '10px' }} />
                </div>
                <div className="cart_divider" />
              </>

            ))}
            <div>
              <div onClick={() => { history.push('/map'); customInstructions.length !== 1 && localStorage.setItem("extraInstructions", JSON.stringify(customInstructions)) }} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}>
                <p className='address_head bahnschrift' style={{ color: '#0375b1' }}>Add new Address</p>
                <FontAwesomeIcon className='start-element' icon={faChevronRight} style={{ color: '#0375b1', height: '20px', marginLeft: '20px', marginRight: '10px' }} />
              </div>
              <div className="cart_divider" />
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {/* order summary pop up */}
      <Dialog
        open={showOrderSummary}
        classes={{
          paper: classes.bigscreens,
        }}
        fullScreen
        PaperProps={{ sx: { width: "100%", marginTop: 'auto', marginBottom: 0, height: '80%', backgroundColor: '#e8e8e8', paddingBottom: '100px' } }}
      >
        <img
          src={close_icon}
          className={classes.closeIcon}
          onClick={() => {
            setShowOrderSummary(!showOrderSummary);
          }}
          alt='close'
        />
        <div style={{ paddingLeft: '10px', paddingRight: '10px', position: 'relative' }}>

          <div className="orderdetails_header" style={{ width: "100%", backgroundColor: '#e8e8e8' }}>
            <p className="order_details_text bahnschrift" style={{ textAlign: 'center' }}>Order Summary</p>
          </div>

          <div style={{ marginBottom: '10px', marginTop: '50px' }} className="order_summary_containers">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="order_id_date_text" style={{ margin: 0 }}>Order ID: {orderStatus.orderId}</p>
              <p className="order_id_date_text" style={{ margin: 0 }}>{orderStatus.orderDate}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', marginTop: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <img src={order_placed_green} style={{ width: '40px' }} alt="NA" />
                <p className="summary_icon_texts" style={{ textAlign: 'center', marginTop: '5px', marginBottom: 0 }}>Order Placed</p>
              </div>
              <div className="summary_horiz_divider" />
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <img src={orderStatus.stageStatus >= 2 ? payment_green : payment_grey} style={{ width: '40px' }} alt="NA" />
                <p className="summary_icon_texts" style={{ textAlign: 'center', marginTop: '5px', marginBottom: 0 }}>Payment</p>
              </div>
              <div className="summary_horiz_divider" />
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <img src={(orderStatus.deliveryMode === 0 && orderStatus.shipmentStatus === 5) ? order_delivered_green : order_delivered_grey} style={{ width: '40px' }} alt="NA" />
                <p className="summary_icon_texts" style={{ textAlign: 'center', marginTop: '5px', marginBottom: 0 }}>Delivery</p>
              </div>
            </div>
          </div>

          <div className="order_summary_containers">
            <p className="order_status_title bahnschrift">{logisticData.orderStatusTitle}</p>
            <p className="order_status_text bahnschrift">{logisticData.orderStatusText}</p>
          </div>

          {Object.keys(cartDat).length !== 0 && cartDat.map(item => (
            <div style={{ marginTop: '10px' }} className="order_summary_containers">
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                <div style={{ display: 'flex', flex: 2 }}>
                  <img src={item.image_url[0]} style={{ width: '40px', height: '40px' }} alt="NA" />
                  <p className="summary_pd_text line_clamp2" style={{ alignSelf: 'center', margin: 0, marginLeft: '5px' }}>{item.item_name}</p>
                </div>

                <p className="summary_pd_num" style={{ flex: 1, textAlign: 'center' }}>x {item.count}</p>

                <div style={{ display: 'flex', flex: 2, justifyContent: 'center', alignItems: 'flex-end' }}>
                  <p className={item.discounted_rate ? 'summary_cutout_price' : 'summary_pd_num'}>{currency(item.rate)}</p>
                  {item.discounted_rate && (
                    <p className="summary_pd_num">{currency(item.discounted_rate)}</p>
                  )}
                </div>

              </div>
              {(item.instructions !== '' && item.instructions !== null && item.instructions !== 'null' && item.instructions !== undefined) && (
                <p className="order_instruction">{item.instructions}</p>
              )}
            </div>
          ))}

          {(logisticData.customProduct !== '' && logisticData.customProduct !== null && logisticData.customProduct !== 'null' && logisticData.customProduct !== undefined && logisticData.customProduct.length !== 0) && (
            <div className="custom_pd_container">
              {logisticData.customProduct.map((value, index) => (
                <div className="custom_pd">
                  <div className="green_bullet" />
                  <p className="summary_custom_item_text">{value}</p>
                </div>
              ))}
            </div>
          )}

          <div style={{ marginBottom: '10px', backgroundColor: 'white', marginLeft: 0, marginRight: 0, marginTop: '10px' }} className='bill_container'>
            <p className='bill_subtext bill_large_font_size' style={{ color: 'black', marginBottom: '5px' }}>Bill Details</p>
            <div>
              {logisticData.savings !== 0 && (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p className='bill_subtext'>MRP Total</p>
                  <p style={{ color: 'black' }} className='bill_amount'>{currency(logisticData.mrpTotal)}</p>
                </div>
              )}

              {logisticData.savings !== 0 && (
                <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '5px' }}>
                  <p className='bill_subtext'>Savings</p>
                  <p style={{ color: '#2bb673' }} className='bill_amount'>- {currency(logisticData.savings)}</p>
                </div>
              )}
              <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '5px' }}>
                <p className='bill_subtext'>Item Total</p>
                <p style={{ color: 'black' }} className='bill_amount'>{currency(logisticData.itemTotal)}</p>
              </div>
              <div className='cart_divider' />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className='bill_subtext'>Delivery Charges</p>
                <p style={{ color: 'black' }} className='bill_amount'>{currency(logisticData.deliveryCharge)}</p>
              </div>
              {(logisticData.govtTax !== 0 && logisticData.govtTax !== "undefined" && logisticData.govtTax !== "null") && (
                <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '5px' }}>
                  <p className='bill_subtext'>Govt Taxes</p>
                  <p style={{ color: 'black' }} className='bill_amount'>{currency(logisticData.govtTax)}</p>
                </div>
              )}
              <div className='cart_divider' />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className='bill_subtext bill_large_font_size' style={{ color: 'black' }}>Amount To Pay</p>
                <p className='bill_amount_bold' style={{ color: 'black' }}>{currency(logisticData.payAmount)}</p>
              </div>
              {(logisticData.cashback !== 0 && logisticData.cashback !== "undefined" && logisticData.cashback !== "null") && (
                <>
                  <div className='cart_divider' />
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p className="bill_large_font_size" style={{ color: 'black', fontWeight: '300', marginBottom: 0, marginTop: 0 }}>Cashback</p>
                    <p className='bill_amount' style={{ color: '#2bb673' }}>{currency(logisticData.cashback)}</p>
                  </div>
                  <p className="cashback_subtext">Will be credited within 24 hours</p>
                </>
              )}

            </div>

          </div>

          <div className="summary_total_container">
            <p className="summary_total_text">Total: {currency(logisticData.payAmount)}</p>
            <p className="item_saving_text">{cartDat.length} Items {logisticData.savings !== 0 && (<span className="item_saving_text">| {currency(logisticData.savings)} savings</span>)}</p>
          </div>
          {console.log("LogisticData", logisticData)}
        </div>
      </Dialog>
    </div>
  );
};

export default function Index(props) {
  const { Images, searchInput, setCartCount, cartVisible, setCartVisible, setNewLogin, menuOption, setMenuOption } = props;
  const [isFruitsAndVegMerchant, setIsFruitsAndVegMerchant] = useState("");
  const merchantdetails = { data: JSON.parse(localStorage.getItem("merchantdetails")).merchantDetails }
  console.log("MerInsude", merchantdetails)
  let l3 = merchantdetails && merchantdetails.data.categoryl3;
  let PhoneNumber = merchantdetails && merchantdetails.data.mainmobilenumber;
  let merchId = merchantdetails && merchantdetails.data.merchantid;

  useEffect(() => {
    if ("95f732e0-737b-11ec-9806-93163a488dc9" == l3 || "3d795333-84ae-11ec-a0d0-795c4aba562b" == l3) {
      setIsFruitsAndVegMerchant("FRUITS");
    } else if ("8650b2d0-737b-11ec-9806-93163a488dc9" == l3) {
      setIsFruitsAndVegMerchant("GROCERY");
    } else {
      setIsFruitsAndVegMerchant("");
    }
  }, []);

  return (
    <>
      {/* <Cart /> */}
      <Page
        Images={Images}
        searchInput={searchInput}
        isFruitsAndVegMerchant={isFruitsAndVegMerchant}
        PhoneNumber={PhoneNumber}
        gridType={merchantdetails.data.gridtype}
        businessName={merchantdetails.data.shopname}
        merchId={merchId}
        isCheckout={merchantdetails.data.eligibility_of_checkout}
        shopOff={merchantdetails.data.shopClosed}
        setCartCount={setCartCount}
        cartVisible={cartVisible}
        setCartVisible={setCartVisible}
        catalogCount={merchantdetails.data.catalogue_count}
        userOfferBanner={merchantdetails.data.userOfferBanner}
        menuOption={menuOption}
        setMenuOption={setMenuOption}
      />
    </>
  );
}
