
const APIConfig = {
    baseUrl : "https://dev.chattybao.com/v1/", //TEST
    baseUrl1: "https://dev.chattybao.com/v2/", //TEST
    baseUrl2: "https://dev.chattybao.com/v3/", //TEST

    // baseUrl : "https://api.chattybao.com/v1/", //PROD
    // baseUrl1 : "https://api.chattybao.com/v2/", //PROD
    // baseUrl2 : "https://api.chattybao.com/v3/", //PROD
    getMerchantDataForCatalogue: 'getMerchantDataForCatalogue',
    viewMerchant: 'viewMerchant?id=',
    getUserAddresses: 'getUserAddresses',
    getUserLogisticCharge: 'getUserLogisticCharge',
    gmapApiKey: 'AIzaSyDgzOSXP40P4tXXs1a2uUQ4dblD9pXKdEY',
    gMapCall: 'https://maps.googleapis.com/maps/api/geocode/json?address=',
    makePayment: 'makePayment',
    webUserLogin: 'webUserLogin',
    updateUserAddress: 'updateUserAddress',
    getChannelDetailsv3: 'getChannelDetailsv3',
    getMerchUserChannel: 'getMerchUserChannel',
    trackOrder: 'trackOrder',
    getMerchantS1Categories: 'getMerchantS1Categories',
    getS1CategoriesProducts: 'getS1CategoriesProducts',
    searchMerchantCatalogue: 'searchMerchantCatalogue'
}

export { APIConfig };