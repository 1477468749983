import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./../src/app/layouts";
import { AuthLayout } from "./../src/app/layouts";

// Route pages

import Homepage from "./../src/app/pages/Homepage";
import MapContainer from './other-catalog-pages/Map';
import AddressPage from './other-catalog-pages/AddressPage';
import OrderTracking from "./app/pages/ONDC-OrderTracking/OrderTracking";

export default [
  // {
  //   path: `/${localStorage.getItem("fullurl")}`,
  //   exact: true,
  //   layout: AuthLayout,
  //   component: () => <Redirect to="/" />,
  // },
  // {
  //   path: "/",
  //   layout: AuthLayout,
  //   component: NewHome,
  // },
  {
    path: `/${localStorage.getItem("fullurl")}`,
    layout: DefaultLayout,
    component: Homepage,
  },
  {
    path: `/map`,
    layout: DefaultLayout,
    component: MapContainer,
  },
  {
    path: `/address`,
    layout: DefaultLayout,
    component: AddressPage,
  },
  // {
  //   path: `/product-page`,
  //   exact: true,
  //   layout: DefaultLayout,
  //   component: ProductPage,
  // },
];
