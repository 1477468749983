import React, { useEffect } from 'react'
import './MerchantContactUs.css'
import call_btn from '../../../assets/call_btn.png';
import gmap from '../../../assets/gmap.png';
import location_marker from '../../../assets/location.png';
import timings from '../../../assets/Timings.png';
import calendar from '../../../assets/Calendar.png';
import instagramIcon from '../../../assets/contact-insta.png';
import twitterIcon from '../../../assets/contact-twitter.png';


import { Divider } from '@mui/material'

const MerchantContactUs = ({ merchantdetails }) => {
  const allEmptyStrings = merchantdetails.mobile_numbers_enable_on_website.every((element) => {
    // Check if element is not null or undefined
    if (element != null) {
      // Trim the element and check if it's an empty string
      return element.trim() === '';
    } else if (element === null) {
    // If element is null or undefined, consider it as an empty string
      return true
    } else {
      return false
    }
  });
  const firstNonEmptyIndex = merchantdetails.mobile_numbers_enable_on_website.findIndex((element) => element?.trim() !== '');
  console.log("VVVV", allEmptyStrings, "ind", firstNonEmptyIndex)

  useEffect(() => {
    localStorage.setItem("fromContact", 1)
  }, [])

  const handleMapClick = () => {
    if (merchantdetails.displayAddress.latitude !== "" && merchantdetails.displayAddress.longitude !== "") {
      window.open(`https://www.google.com/maps?q=${merchantdetails.displayAddress.latitude},${merchantdetails.displayAddress.longitude}`, "_blank")
    } else {
      window.open(`https://www.google.com/maps?q=${merchantdetails.latitude},${merchantdetails.longitude}`, "_blank")
    }
  }

  return (
    <div style={{ padding: '0 10px' }}>
      <h6 className='contact-header'>Contact Us</h6>
      <div className='contact-address-container'>
        <div className='address-makr-container'>
          <img src={location_marker} className='contact-data-icon' alt="marker" />
          {merchantdetails.displayAddress.addressline1 !== "" ? (
            <p className="text_inside_businessdetail">{merchantdetails.displayAddress.addressline1}, {merchantdetails.displayAddress.addressline2}, {merchantdetails.displayAddress.city} - {merchantdetails.displayAddress.pincode}</p>
          ) : (
            <p className="text_inside_businessdetail">{merchantdetails.addressline1}, {merchantdetails.addressline2}, {merchantdetails.city} - {merchantdetails.pincode}</p>
          )}

        </div>
        <img src={gmap} className='gmap-icon' alt="map" onClick={handleMapClick} />

      </div>
      <Divider className="dividers" />
      <div className='contact-data-container'>
        <img src={timings} className='contact-data-icon' alt="marker" />
        <div>
          {merchantdetails.opentime === "Open 24 hours" ? (
            <p className="text_inside_businessdetail">Timings: Open 24 hours</p>
          ) : (
            <>
              <p className="text_inside_businessdetail">Timings: {merchantdetails.opentime} - {merchantdetails.closetime} {merchantdetails.closetime2 !== undefined && merchantdetails.closetime2 !== null && merchantdetails.closetime2 !== '' && merchantdetails.opentime2 !== undefined && merchantdetails.opentime2 !== null && merchantdetails.opentime2 !== '' ? `, ${merchantdetails.opentime2} - ${merchantdetails.closetime2}` : ''}</p>
            </>
          )}
        </div>

      </div>
      <Divider className="dividers" />
      <div className='contact-data-container'>
        <img src={calendar} className='contact-data-icon' alt="marker" />
        <p className="text_inside_businessdetail">Open {
          merchantdetails.weeklyoff === '0' ? 'Monday to Saturday' :
            merchantdetails.weeklyoff === '1' ? 'Tuesday to Sunday' :
              merchantdetails.weeklyoff === '2' ? 'Wednesday to Monday' :
                merchantdetails.weeklyoff === '3' ? 'Thursday to Tuesday' :
                  merchantdetails.weeklyoff === '4' ? 'Friday to Wednesday' :
                    merchantdetails.weeklyoff === '5' ? 'Saturday to Thursday' :
                      merchantdetails.weeklyoff === '6' ? 'Sunday to Friday' :
                        merchantdetails.weeklyoff === '7' ? 'Sunday to Saturday' : ''
        }</p>
        <p className="text_inside_businessdetail closed-day-text">
          {
            merchantdetails.weeklyoff === '0' ? ', closed on Sunday' :
              merchantdetails.weeklyoff === '1' ? ', closed on Monday' :
                merchantdetails.weeklyoff === '2' ? ', closed on Tuesday' :
                  merchantdetails.weeklyoff === '3' ? ', closed on Wednesday' :
                    merchantdetails.weeklyoff === '4' ? ', closed on Thursday' :
                      merchantdetails.weeklyoff === '5' ? ', closed on Friday' :
                        merchantdetails.weeklyoff === '6' ? ', closed on Saturday' : ''
          }
        </p>
      </div>
      <Divider className="dividers" />
      {allEmptyStrings !== true && (
        <div className='contact-data-container'>
          <img src={call_btn} className='contact-data-icon' alt="marker" />
          <div className='phone-numbers-container'>
            {merchantdetails.mobile_numbers_enable_on_website.length !== 0 && (
              merchantdetails.mobile_numbers_enable_on_website.map((data, index) => {
                return (
                  data ? (
                    <a className='ph-number-link' href={`tel:+91${data}`}>
                      <p className="text_inside_businessdetail phone-number-text">{firstNonEmptyIndex === index ? data : `, ${data}`}</p>
                    </a>
                  ) : ''
                )
              })
            )}
          </div>
        </div>
      )}
      {merchantdetails.merchant_email && merchantdetails.merchant_email.showOnWebsite === true && (
        <>
          <Divider className="dividers" />
          <div className='contact-data-container'>
            <p className='text_inside_businessdetail'>{merchantdetails.merchant_email.email}</p>
          </div>
        </>
      )}
      <Divider className="dividers" />
      <div className='social-icons-contact-us contact-data-container'>
        {merchantdetails.socialMedia && merchantdetails.socialMedia.instagramUrl !== "" && (
          <a href={`https://www.instagram.com/${merchantdetails.socialMedia.instagramUrl}/`}>
            <img src={instagramIcon} className='contact-social-icon' alt='social' />
          </a>
        )}
        {merchantdetails.socialMedia && merchantdetails.socialMedia.twitterUrl !== "" && (
          <a href={`https://twitter.com/${merchantdetails.socialMedia.twitterUrl}/`}>
            <img src={twitterIcon} className='contact-social-icon' alt='social' />
          </a>
        )}
        {/* <img src={fbContact} className='contact-social-icon' alt='social' />
                  <img src={youtubeContact} className='contact-social-icon' alt='social' /> */}
      </div>
    </div>
  )
}

export default MerchantContactUs;
