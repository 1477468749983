import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCs7Ndj0SBQ04EM2ySeWSRwH626k4FBDFQ",
  authDomain: "chattybao-user.firebaseapp.com",
  projectId: "chattybao-user",
  storageBucket: "chattybao-user.appspot.com",
  messagingSenderId: "483368453434",
  appId: "1:483368453434:web:d81f5cf60cafa808c67c03",
  measurementId: "G-MZXGVS7PC7",  
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);
