import { APIConfig } from "./apiConfig";
import { ApiCallGet, ApiCallPost } from './ApiCall';

const ApiService = {
    getMerchantDataForCatalogue: async (data, token) => {
        const { getMerchantDataForCatalogue, baseUrl } = APIConfig;
        const url = baseUrl + getMerchantDataForCatalogue
        let headers = {};
        if (token !== null) {
            headers = {
                token,
            }  
        } else {
            headers = {
                'Content-Type': 'application/json',
            }
        }
        
        return ApiCallPost(url, data, headers)
    },

    getaddresses: async (token) => {
        const { getUserAddresses, baseUrl } = APIConfig;
        const url = baseUrl + getUserAddresses
        const headers = {
            token
        }
        return ApiCallGet(url, headers)
    },
    getUserLogisticCharge: async (data, token) => {
        const { getUserLogisticCharge, baseUrl2 } = APIConfig;
        const url = baseUrl2 + getUserLogisticCharge
        const headers = {
            token
        }
        return ApiCallPost(url, data, headers)
    },
    gMapsApi: async (lat, lng) => {
        const { gMapCall, gmapApiKey } = APIConfig;
        return fetch(gMapCall + lat + ',' + lng + '&key=' + gmapApiKey)
    },
    makePayment: async (data, token) => {
        const { makePayment, baseUrl } = APIConfig;
        const url = baseUrl + makePayment
        const headers = {
            token
        }
        return ApiCallPost(url, data, headers)
    },
    webUserLogin: async (data) => {
        const { webUserLogin, baseUrl } = APIConfig;
        const url = baseUrl + webUserLogin
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallPost(url, data, headers)
    },
    updateUserAddress: async (data, token) => {
        const { updateUserAddress, baseUrl1 } = APIConfig;
        const url = baseUrl1 + updateUserAddress
        const headers = {
            token
        }
        return ApiCallPost(url, data, headers)
    },
    getChannelDetailsv3: async (data, token) => {
        const { getChannelDetailsv3, baseUrl2 } = APIConfig;
        const url = baseUrl2 + getChannelDetailsv3
        const headers = {
            token
        }
        return ApiCallPost(url, data, headers)
    },
    getMerchUserChannel: async (data, token) => {
        const { getMerchUserChannel, baseUrl } = APIConfig;
        const url = baseUrl + getMerchUserChannel
        const headers = {
            token
        }
        return ApiCallPost(url, data, headers)
    },
    trackOrder: async (orderId) => {
        const { trackOrder, baseUrl } = APIConfig;
        const url = baseUrl + trackOrder + `?orderId=${orderId}`
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallGet(url, headers)
    },

    getMerchantS1Categories: async (data) => {
        const { getMerchantS1Categories, baseUrl } = APIConfig;
        const url = baseUrl + getMerchantS1Categories
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallPost(url, data, headers)
    },

    getS1CategoriesProducts: async (data) => {
        const { getS1CategoriesProducts, baseUrl } = APIConfig;
        const url = baseUrl + getS1CategoriesProducts
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallPost(url, data, headers)
    },

    searchMerchantCatalogue: async (data) => {
        const { searchMerchantCatalogue, baseUrl } = APIConfig;
        const url = baseUrl + searchMerchantCatalogue
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallPost(url, data, headers)
    }
}
export default ApiService
