import React from "react";
import "./spinner.css";
import { BarLoader, BeatLoader, ClipLoader } from "react-spinners";


export default function LoadingSpinner() {
  return (
    <div className="spinner-container">
      <BarLoader color="#36d7b7" />
      {/* <div className="loading-spinner"></div> */}
      
    </div>
  );
}