import React from "react";
import blue from "../../../assets/images/bluebg.jpg";
import orange from "../../../assets/images/orangebg.jpg";
import img7 from "../../../assets/images/play-store.png";
import "./index.scss";
import { Table, ListGroup, ListGroupItem } from "react-bootstrap";

const MerchantAgreementPage = () => {
  return (
    <div>
      <div className="agreement">
        <div
          className="con"
          style={{
            backgroundImage: `url(${blue})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundPosition: "revert",
          }}
        >
          <div style={{ paddingTop: "40px" }} className="col">
            <div className="user">
              <div className="user__heading">
                <h1>Platform Listing Agreement</h1>
              </div>
            </div>
          </div>
          <div className="user__content1">
            <p>
              This document is an electronic record in terms of the Information
              Technology Act, 2000 (“IT Act”) and rules made thereunder, as
              applicable, and the amended provisions pertaining to electronic
              records in various statutes, as amended from time to time. This
              document is generated by a computer system and does not require
              any physical signatures.
              <br />
              For this Platform Listing Agreement (hereinafter, the Agreement),
              wherever the context so requires “You”, “Your”, “Yours” or
              "Merchant" shall mean any natural or legal person who has agreed
              to use the services of{" "}
              <span style={{ fontWeight: "700", color: "black" }}>
                ChattyBao Technologies Private Limited{" "}
              </span>
              referred to as “Chattybao”, “Company”, "We", "Us", "Our" and/or
              its affiliates, on its channels, including on WhatsApp, mobile
              app, website or others to sell their products or services in
              accordance with the terms of this Platform Listing Agreement.
              Chattybao and Merchant shall collectively be referred to as
              “Parties” and individually as “Party”.
              <br />
              By clicking on the “I Accept” button below, You indicate that You
              have read and agree to the terms presented in this Platform
              Listing Agreement. By agreeing to the terms, the Parties hereby
              enter into a legally enforceable valid agreement as per the Indian
              Contract Act, 1872.
            </p>
            <p style={{ fontWeight: "700", color: "black" }}>WHEREAS:</p>
            <p>
              1. Chattybao is the owner and operator of the website,
              www.chattybao.com, and their respective mobile application/s (“
              <span style={{ fontWeight: "700", color: "black" }}>
                Platform
              </span>
              ”).
              <br />
              2. Chattybao is operating an online marketplace and assumes the
              role of facilitator, and does not at any point of time during any
              transaction between Customer and Merchant on the Platform come
              into or take possession of any of the products or services offered
              by Merchant. At no time shall the Company hold any right, title,
              or interest over the products nor shall the Company have any
              obligations or liabilities in respect of such contract entered
              into between Customer and Merchant. The Company is only providing
              a platform for communication and it is agreed that the contract
              for the sale of any of the products or services shall be a
              strictly bipartite contract between the Merchant and the Customer.
              Chattybao provides the functionality to the Merchants, to showcase
              their products through certain features like a catalog. Chattybao
              is in no manner privy to the conversations between the Merchants
              and Customers or the nature or quantum or content of transactions
              between Merchants and Customers, or even to the fact that a
              sale/purchase was actually concluded between a Merchant and a
              Consumer. Chattybao has no control of the catalogue (which is
              generated by a Merchant), its content, pricing, Products on the
              catalogue, etc.
              <br />3 .Chattybao Platform acts as a buying and selling platform
              for the Customer and Merchant to negotiate and interact with each
              other for entering into negotiations with respect thereof for the
              sale or supply of goods or services. Chattybao is not a party to
              any negotiations that take place between the Customer and Merchant
              and is also not a party to any agreement including an agreement
              for sale or supply of goods or services or otherwise, concluded
              between the Customer and Merchant only through the Platform.
              <br />4 .Chattybao is an independent contractor providing the
              Services in terms of this Agreement and does not control and is
              not liable in respect of or responsible for the quality, safety,
              genuineness, lawfulness or availability of the products or
              services offered for sale through the platform or the ability of
              the merchant selling or supplying the goods or services to
              complete a sale or the ability of Customer purchasing goods or
              services to complete a purchase. This agreement shall not be
              deemed to create any partnership, joint venture, or any other
              joint business relationship or agency between Chattybao and the
              Merchant.
              <br />
              5. The Merchant is the Service Provider/manufacturer/brand owner
              or authorized distributor/ Retailer of Products and is desirous of
              retailing the Products/Services to end customers by listing
              themselves on the platform/partner portal through different
              methods as may be available/provided by the Chattybao.
              <br />
              6. The Parties are desirous of entering into this Agreement,
              whereby the Merchant shall handle inquiries and retail their
              Products and Services to end customers by directly interacting
              with them through the conversational interface provided by the
              Chattybao.
              <br />
              7. The Parties are entering into this Agreement to reduce to
              writing the terms and conditions based on which the Parties shall
              regulate their relationship in respect of the Merchant availing
              the Services provided by Chattybao.
              <br />
              <span style={{ fontWeight: "700", color: "black" }}>
                NOW, THEREFORE
              </span>
              , in consideration of the foregoing premises and for other good
              and valuable consideration, the receipt and adequacy of which are
              hereby acknowledged, the Parties hereto hereby agree as follows:
            </p>
            <Table bordered style={{ color: "#424149" }}>
              <thead>
                <tr>
                  <th><span style={{ fontWeight: "700", color: "black" }}>
                    Clause No.
                  </span></th>
                  <th><span style={{ fontWeight: "700", color: "black" }}>Clause</span></th>
                  <th><span style={{ fontWeight: "700", color: "black" }}>Information</span></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td><span style={{ fontWeight: "700", color: "black" }}>Definition</span></td>
                  <td>
                    a) <span style={{ fontWeight: "700", color: "black" }}>“Agreement”</span> means the Platform Listing Agreement along
                    with its Annexures (defined below) and shall include any
                    subsequent written modifications and amendments thereto
                    mutually agreed to in writing and signed by the Parties.{" "}
                    <br /> b) <span style={{ fontWeight: "700", color: "black" }}>“Applicable Law”</span> means all applicable provisions
                    of all:
                    <ListGroup>
                      <ListGroupItem style={{ borderStyle: "none" }}>
                        i. Constitutions, treaties, statutes, laws (including
                        the common law and Taxation law), codes, rules,
                        regulations, ordinances, or orders of any governmental
                        authority;{" "}
                      </ListGroupItem>
                      <ListGroupItem style={{ borderStyle: "none" }}>
                        ii. Governmental approvals;
                      </ListGroupItem>
                      <ListGroupItem style={{ borderStyle: "none" }}>
                        iii. Orders, decisions, injunctions, judgments, awards,
                        and decrees of or agreements having the force of law
                        with any Governmental authority; and{" "}
                      </ListGroupItem>
                      <ListGroupItem style={{ borderStyle: "none" }}>
                        iv. judgment, notification, rule of common law, bye-law,
                        statutory directive, guideline, requirement or other
                        governmental restriction, or any decision of, or
                        determination, interpretation or policy, having the
                        force of law, by or of any court of law or any
                        governmental authority including any entity exercising
                        executive, legislative, regulatory or administrative
                        functions having jurisdiction over the matter in
                        question, in effect at the relevant time, in any
                        applicable jurisdiction
                      </ListGroupItem>
                    </ListGroup>
                    <br /> c) <span style={{ fontWeight: "700", color: "black" }}>“Brand”</span> shall mean the brand name or trademark
                    bearing on the Products or associated with the Services
                    retailed by the Merchant through the Platform.
                    <br /> d) <span style={{ fontWeight: "700", color: "black" }}>“Confidential Information”</span> shall mean any and
                    every information including any proprietary information of a
                    Party, including but not limited to the terms of this
                    Agreement, commercial, technical and artistic information
                    relating to such Party’s establishment, business, operation,
                    maintenance, marketing and promoting of its own services and
                    products, products, programming techniques, experimental
                    work, customers, clients, vendors, developments, inventions,
                    technology, etc. Confidential Information will include all
                    information(whether available in written, oral or
                    machine-readable form) relating to the business or affairs
                    of the Party, including (without limitation) information
                    relating to existing or future systems, software, hardware,
                    products and services, and those in development, and
                    accompanying marketing plans, details of employees and
                    business strategies, etc, the contents or subject matter of
                    this Agreement, trade secrets, projects, plans and
                    proposals, pricing and commission information, credit
                    techniques, information concerning dealings, transactions or
                    affairs of the Company, program codes, software design
                    know-how, software programs, any data, documents, plans,
                    drawings, photographs, reports, statements correspondence,
                    etc. and technical information, financial projections,
                    methods, know-how, processes, specifications, blueprints,
                    formulae, devices, models, and instructions as well as
                    business details or commercial policies including papers and
                    documents, computer floppies, compact discs or other media
                    containing the same.
                    <br /> e) <span style={{ fontWeight: "700", color: "black" }}>“Customer”</span> shall mean the end consumer/users who
                    shall purchase/avail Products/Services retailed/ sold by the
                    Merchant through this Platform.
                    <br />
                    f) <span style={{ fontWeight: "700", color: "black" }}>“Intellectual Property”</span> includes concepts, creations,
                    discoveries, domain names, inventions, improvements, know
                    how, trade or business secrets; patents, copyright
                    (including all copyright in any designs and any moral
                    rights), trademarks, service marks, designs, utility models,
                    tools, devices, models, methods, procedures, processes,
                    systems, algorithms, works of authorship, electronic codes,
                    drawings, schematics, prototypes, models, inventions,
                    processes, ideas, techniques, know-how, compositions of
                    matter, materials, methods, concepts, apparatuses,
                    improvements (whether or not patentable), data, manuals,
                    notes and other documents, proprietary techniques and other
                    confidential and proprietary information; in either printed
                    or machine- readable form, whether or not copyrightable or
                    patentable, or any written or verbal instructions or
                    comments. It includes (i) all rights, title and interest
                    under any statute or under common law including in any
                    Intellectual Property or any similar rights, anywhere in the
                    world, whether negotiable or not and whether registered or
                    not, (ii) any licenses, permissions and grants in any of the
                    foregoing; (iii) applications for any of the foregoing and
                    the right to apply for them in any part of the world, and
                    (iv) all extensions and renewals thereto.
                    <br />
                    g) <span style={{ fontWeight: "700", color: "black" }}>“Losses”</span> shall mean any and all liabilities, claims,
                    demands, causes of action, Damages, loss and/or expenses,
                    including reasonable attorneys’ fees and including any
                    interest thereon.
                    <br />
                    h) <span style={{ fontWeight: "700", color: "black" }}>“Partner Portal”</span> shall mean a secure, Chattybao owned and
                    hosted, web-based application made available by Chattybao to
                    the Merchant that provides the Merchant various updates,
                    reports and information relating to the sales of the
                    Products/Services along with other related information.
                    <br />
                    i) <span style={{ fontWeight: "700", color: "black" }}>“Products”</span> shall mean product retailed by the Merchant to
                    the Customer as per the terms of this Agreement.
                    <br />
                    j) <span style={{ fontWeight: "700", color: "black" }}>“Services”</span> shall mean the services provided by the
                    Merchant to the Customer as per the terms of this Agreement.
                    <br />
                    k) <span style={{ fontWeight: "700", color: "black" }}>“Subscription and Other Charges”</span> shall mean the charges
                    published by the Company on the Partner Portal from time to
                    time.
                    <br />
                    l) <span style={{ fontWeight: "700", color: "black" }}>“Territory”</span> shall mean and include Republic of India.
                  </td>
                </tr>
                <tr>
                  <td>2.</td>
                  <td><span style={{ fontWeight: "700", color: "black" }}>Term and Termination</span></td>
                  <td>
                    The term will commence from the date of signing of Agreement
                    and continues unless terminated mutually in writing by
                    Parties in accordance with the provisions of Clause 5
                    (“<span style={{ fontWeight: "700", color: "black" }}>Term</span>”).
                  </td>
                </tr>
                <tr>
                  <td>3.</td>
                  <td><span style={{ fontWeight: "700", color: "black" }}>Subscription and Other Charges</span></td>
                  <td>
                    The rate and payment schedules for the Subscription and
                    other Charges shall be published by us on the Partner
                    Portal. The Subscription Charges and Other Charges agreed
                    upon may be unilaterally modified by Chattybao at any time
                    during the term by way of issuing prior notice to the
                    Merchant via email or through Partner Portal atleast 48
                    hours prior to revision of such Subscription Charges and
                    other Charges. However, the detailed terms and conditions
                    governing the payment of subscription charges and facilities
                    available to the subscribers are given in Annexure 2.
                  </td>
                </tr>
                <tr>
                  <td>4.</td>
                  <td><span style={{ fontWeight: "700", color: "black" }}>Services</span></td>
                  <td>
                    a) <span style={{ fontWeight: "700", color: "black" }}>“Customer Support services”</span>: Chattybao shall provide
                    customer support services i.e., assisting the Customers with
                    any order through the Platform.
                    <br />
                    b) <span style={{ fontWeight: "700", color: "black" }}>“Preferred Supplier or Trusted Supplier”</span>: Based on the
                    subscription taken by the Merchant or certain scoring
                    criteria defined internally by Chattybao, Chattybao may
                    provide services to certain Merchants under the category of
                    ‘Preferred Supplier’. It gives the Merchant priority listing
                    within categories as available on the platform, thus
                    increasing the visibility of their products.
                    <br />
                    c) <span style={{ fontWeight: "700", color: "black" }}>"Catalog"</span>: Depending on the subscription plan taken by
                    the merchants, Chattybao would facilitate the Merchants by
                    allowing them to create a catalog of Products through
                    ChattyBao Merchant applications. It is a cataloging service
                    available on the platform, along with independent control to
                    add, delete, and edit product information.
                    <br />
                    d) <span style={{ fontWeight: "700", color: "black" }}>“Delivery service”</span>: Subject to availability and plans
                    subscribed by merchants, Merchants may be able to avail
                    Delivery services from ChattyBao, provided by ChattyBao
                    directly or facilitated via independent third party service
                    providers
                    <br />
                    e)Any additional services that the Merchant wishes to avail
                    of will be mutually discussed between the parties and made
                    shall be deemed to be part and parcel of this Agreement.
                  </td>
                </tr>
                <tr>
                  <td>5.</td>
                  <td><span style={{ fontWeight: "700", color: "black" }}>Termination</span></td>
                  <td>
                    a) Chattybao can terminate this agreement without any cause
                    by giving a notice of 7 days to the Merchant. However, such
                    notice may not affect existing supply orders placed by
                    Customers which shall be fulfilled by the Merchant.
                    <br />
                    b) Chattybao shall, in its sole discretion, also have the
                    right to take down/delist the Merchant, and/or temporarily
                    suspend this Agreement and/or blacklist/ ban the Merchant,
                    (i) in the event of merchant’s continuous unresponsiveness
                    to customer’s order and service request, (ii) in the event
                    of any breach committed by the Merchant of this Agreement or
                    any policy of the Company.
                    <br />
                    c) In the event of a breach committed by either Party
                    (<span style={{ fontWeight: "700", color: "black" }}>“Defaulting Party”</span>), of any provision of this Agreement, a
                    notice is to be served within 7 days of the breach to the
                    Defaulting Party to rectify such breach and if such breach
                    is not rectified by Defaulting Party after notice being duly
                    served within the said period of 15 days, the non-
                    defaulting Party may terminate this Agreement forthwith.
                    <br />
                    d) Chattybao may terminate the Agreement forthwith if at any
                    point in time if there is a breach of any Applicable Law.
                    <br />
                    e) Chattybao shall be entitled to terminate the Agreement
                    with immediate effect by written notice to the Merchant if,
                    during the Term of this Agreement (i) The Merchant is
                    prevented from registering the use of Product related trade
                    mark(s) in the Territory; or (ii) if We have reasonable
                    cause to believe that the use of the Merchant’s Intellectual
                    Property in the Products by Us may infringe a right of any
                    third party and as a consequence, the performance of the
                    Agreement would be affected to a material degree (iii) if
                    the Product is imported or manufactured in a country with
                    whom India has or creates a trade embargo.
                  </td>
                </tr>
                <tr>
                  <td>6.</td>
                  <td><span style={{ fontWeight: "700", color: "black" }}>Indemnity</span></td>
                  <td>
                    a) Notwithstanding anything to the contrary in this entire
                    document/contract, the Merchant shall indemnify and hold
                    harmless Chattybao, its Affiliates, directors, officers and
                    employees from and against all Losses and claims arising due
                    to any breach or violation of any terms of this Agreement
                    (including the Merchant’s representations and warranties
                    herein) or any other acts or actions of the Customer with
                    respect to the brand or sale of the Product or services
                    provided by the Merchant through the Platform.
                    <br />
                    b) The Merchant shall indemnify Chattybao for any Claims it
                    receives in relation to (a) breach or non-compliance of any
                    Applicable Laws in relation to the sale of the Products; (b)
                    breach of any third-party intellectual property rights in
                    relation to the name and trademarks, whether registered or
                    not, relating to the Brand, Products and Services and (c)
                    breach of confidential information.
                    <br />
                    c) Merchants shall be required to indemnify and hold
                    harmless Chattybao, its promoters, officers, directors,
                    employees, affiliates, agents, sub-contractors and other
                    representatives from any losses including loss of input tax
                    credit, claims, demands, liabilities, suits, proceedings,
                    penalties, costs or expenses of any kind (including,
                    attorneys’ fees and expenses) on account of violation of
                    applicable tax laws by the Merchant (including but not
                    limited to non-filing of the requisite forms with the tax
                    authorities to claim tax credit etc.).
                    <br />
                    d) With specific reference to Customer disputes and claims,
                    arising solely on account of the services provided by
                    Chattybao including but not limited to payments gateways,
                    etc., You hereby authorize Chattybao to settle the Customer
                    disputes, and any resulting liability/losses shall be
                    recoverable and/or subject to set off from final
                    payments/settlements to be done to you.
                    <br />
                    e) The Merchant shall indemnify and hold harmless Chattybao,
                    its Affiliates, directors, officers and employees from and
                    against all Losses arising due to issue of any refunds by
                    the Company to the Customers on behalf of the Merchants.
                  </td>
                </tr>
                <tr>
                  <td>7.</td>
                  <td><span style={{ fontWeight: "700", color: "black" }}>Limitation of Liability/ Disclaimer</span></td>
                  <td>
                    The Company’s liability under all circumstances is limited
                    to the amount of charges/ fees, if any, paid by You to the
                    Company. Notwithstanding anything contained in the
                    provisions of this Agreement, Chattybao shall not be liable
                    for any special, direct, indirect, punitive, incidental or
                    consequential damages or any damages whatsoever (including
                    but not limited to damages for loss of profits or savings,
                    business interruption, loss of information), whether in
                    contract, negligence, tort, strict liability or otherwise or
                    any other damages resulting from any of the following:
                    <br />
                    a) The use or the inability to use the Platform at any given
                    point of time;
                    <br />
                    b) Any defect in goods, samples, data, information, or
                    services purchased or obtained from a Merchant or a
                    third-party service provider through the Platform;
                    <br />
                    c) Violation of Third Party Rights or claims or demands that
                    the manufacture, importation, distribution, offer, display,
                    purchase, sale and/or use of Products or services offered or
                    displayed on the web site may violate or may be alleged to
                    violate third party rights; or claims by any party that they
                    are entitled to defense or indemnification in relation to
                    assertions of rights, demands or claims by third party
                    rights claimants;
                    <br />
                    d) Unauthorized access by third parties to data or private
                    information of any User(s);
                    <br />
                    e) Statements or conduct of any User(s) of the platform; or
                    <br />
                    f) Any matters relating to Services however arising,
                    including negligence. The features and services on the
                    platform are provided on an " as is " and " as available "
                    basis, and Chattybao hereby expressly disclaims any and all
                    warranties, express or implied, including but not limited to
                    any warranties of condition, quality, durability,
                    performance, accuracy, reliability, merchantability or
                    fitness for a particular purpose. All such warranties,
                    representations, conditions, undertakings, and terms are
                    hereby excluded. Under no circumstances shall Chattybao be
                    held liable for any delay or failure or disruption of the
                    content or services delivered through the Platform resulting
                    directly or indirectly from acts of nature, forces, or
                    causes beyond its reasonable control, including without
                    limitation, The Company’s liability under all circumstances
                    is limited to the amount of charges/ fees, if any, paid by
                    You to the Company. Notwithstanding anything contained in
                    the provisions of this Agreement, Chattybao shall not be
                    liable for any special, direct, indirect, punitive,
                    incidental or consequential damages or any damages
                    whatsoever (including but not limited to damages for loss of
                    profits or savings, business interruption, loss of
                    information), whether in contract, negligence, tort, strict
                    liability or otherwise or any other damages resulting from
                    any of the following: a) The use or the inability to use the
                    Platform at any given point of time; b) Any defect in goods,
                    samples, data, information, or services purchased or
                    obtained from a Merchant or a third-party service provider
                    through the Platform; c) Violation of Third Party Rights or
                    claims or demands that the manufacture, importation,
                    distribution, offer, display, purchase, sale and/or use of
                    Products or services offered or displayed on the web site
                    may violate or may be alleged to violate third party rights;
                    or claims by any party that they are entitled to defense or
                    indemnification in relation to assertions of rights, demands
                    or claims by third party rights claimants; d) Unauthorized
                    access by third parties to data or private information of
                    any User(s); e) Statements or conduct of any User(s) of the
                    platform; or f) Any matters relating to Services however
                    arising, including negligence. The features and services on
                    the platform are provided on an " as is " and " as available
                    " basis, and Chattybao hereby expressly disclaims any and
                    all warranties, express or implied, including but not
                    limited to any warranties of condition, quality, durability,
                    performance, accuracy, reliability, merchantability or
                    fitness for a particular purpose. All such warranties,
                    representations, conditions, undertakings, and terms are
                    hereby excluded. Under no circumstances shall Chattybao be
                    held liable for any delay or failure or disruption of the
                    content or services delivered through the Platform resulting
                    directly or indirectly from acts of nature, forces, or
                    causes beyond its reasonable control, including without
                    limitation, Internet failures, computer, telecommunications
                    or any other equipment failures, electrical power failures,
                    strikes, labor disputes, riots, insurrections, civil
                    disturbances, shortages of labour or materials, fires,
                    flood, storms, explosions, Acts of God, natural calamities,
                    war, governmental actions, orders of domestic or foreign
                    courts or tribunals or non-performance of third parties.
                    Chattybao assumes no liability or responsibility for any (i)
                    unauthorized access to or use of our secure servers and/or
                    any and all personal information and/or financial
                    information stored therein, (ii) any interruption or
                    cessation of transmission to or from our Platform, (v) any
                    bugs, viruses, trojan horses and/or the like which may be
                    transmitted to or through our Platform by any third party,
                    or (v) any loss or damage to content or data (whether stored
                    through the site and/or service or otherwise), or (vi) any
                    errors or omissions in any content or for any loss or damage
                    of any kind incurred as a result of the use of any content
                    posted, emailed, transmitted or otherwise made available via
                    the Platform. Chattybao does not warrant, endorse, guarantee
                    or assume responsibility for any product, service or
                    opportunity offered by a third party through the Platform.
                  </td>
                </tr>
                <tr>
                  <td>8.</td>
                  <td><span style={{ fontWeight: "700", color: "black" }}>Intellectual Property Rights (“IP”)</span></td>
                  <td>
                    {" "}
                    a) Nothing contained in this Agreement shall construe to
                    transfer, sell, or assign any intellectual property (“IP”)
                    rights by either Party to the other Party. Each Party shall
                    continue to own its IP rights in the Platform, the Products,
                    and the Brand as the case may be.
                    <br />
                    b) Neither Party shall do anything, which is detrimental to
                    intellectual property rights of the other Party, whether now
                    or in the future.
                    <br />
                    c) The Parties shall not apply, whether directly or
                    indirectly, for registering any copyrights, trademarks or
                    any other IP rights or any work/services produced by the
                    other Party under, or in connection to this Agreement.
                    <br />
                    d) Merchant grants Chattybao, a non-exclusive, non-
                    transferable, revocable, royalty-free right to use and
                    display its Brand name and associated trademarks on or
                    within the Platform during the Term solely for the purpose
                    of performing its obligations under this Agreement.
                    <br />
                    e) In case, the Merchant is the sole proprietor of Brand
                    bearing in the Products or Services, the Merchant
                    acknowledges sharing the copy of the trademark certificate
                    with Chattybao, certifying that the Brand name of the
                    Product or Services is duly registered with the Indian Trade
                    Marks Registry as per Applicable Law. In case of the absence
                    of the trademark certificate, the Merchant warrants to
                    provide Chattybao the ‘Indemnity Bond”.
                  </td>
                </tr>
                <tr>
                  <td>9.</td>
                  <td><span style={{ fontWeight: "700", color: "black" }}>Governing Law, Dispute Resolution and Jurisdiction</span></td>
                  <td>
                    This Agreement shall be interpreted and governed in all
                    respects by the laws of India. Subject to the provision
                    below, each party submits to the exclusive jurisdiction of
                    the courts of New Delhi, India.
                    <br />
                    In case of a dispute between the Parties, the Parties shall
                    endeavor to amicably resolve such dispute. If the dispute s
                    has not been resolved within 180 days, then such a dispute
                    shall be determined by arbitration in accordance with the
                    Indian Arbitration and Conciliation Act, 1996 (as amended
                    from time to time). The place of arbitration will be Delhi
                    and the language of the arbitral proceedings will be
                    English. A sole arbitrator will be appointed by the Company
                    to decide the dispute and the award of the sole arbitrator
                    shall be final and binding on both the Parties herein. Any
                    arbitral award rendered hereunder may be entered in any
                    court having jurisdiction, or application may be made to
                    such court for a judicial acceptance of the award and an
                    order of enforcement, as the case may be. Unless the sole
                    arbitrator otherwise provides, the remuneration of the sole
                    arbitrator and all costs of such arbitral proceeding will be
                    borne equally by both the Parties, with each Party bearing
                    the cost of their own legal representative.
                  </td>
                </tr>
                <tr>
                  <td>10.</td>
                  <td><span style={{ fontWeight: "700", color: "black" }}>Good and Service Tax (“GST”)</span></td>
                  <td>
                    The Parties hereby warrant to comply with certain guidelines
                    relating to compliance under The Good and Service Tax Act,
                    2017, as annexed in Annexure 6 of this Agreement.
                  </td>
                </tr>
                <tr>
                  <td>11.</td>
                  <td><span style={{ fontWeight: "700", color: "black" }}>Anti-Bribery and Anti- Corruption</span></td>
                  <td>
                    Chattybao has no tolerance for bribery or corruption in any
                    aspect of its business. Chattybao policy prohibits engaging
                    in any form of corruption, bribery, kickback, extortion,
                    embezzlement or money laundering. Chattybao is committed not
                    only to complying with all Applicable Laws and regulations
                    regarding such activities but also to acting with integrity
                    and the highest ethical standards. Merchant shall not
                    participate in, facilitate or permit any form of corruption,
                    bribery, kickback, extortion, embezzlement or money
                    laundering, whether with respect to public officials or to
                    any other person. Merchant shall comply strictly with all
                    Applicable Laws and regulations and with Chattybao policy.
                    Breach of this Clause shall be deemed a material breach of
                    this Agreement entitling Chattybao to terminate this
                    Agreement forthwith.
                  </td>
                </tr>
                <tr>
                  <td>12.</td>
                  <td><span style={{ fontWeight: "700", color: "black" }}>Assignment</span></td>
                  <td>
                    Neither Party shall in any manner whatsoever transfer or
                    otherwise assign this Agreement or any of its rights or
                    obligations hereunder without the prior written consent of
                    the other Party, which consent shall not be unreasonably
                    withheld. Notwithstanding anything contained herein,
                    Chattybao may assign, without prior written consent, this
                    Agreement to a company that is part of its group.
                  </td>
                </tr>
                <tr>
                  <td>13.</td>
                  <td><span style={{ fontWeight: "700", color: "black" }}>Representations and Warranties</span></td>
                  <td>
                    a) Each Party represents and warrants that:
                    <ListGroup>
                      <ListGroupItem style={{ borderStyle: "none" }}>
                        i. It has all requisite corporate power and authority to
                        execute, deliver and perform its obligations under this
                        Agreement and has been fully authorized by all requisite
                        corporate actions to do so;
                      </ListGroupItem>
                      <ListGroupItem style={{ borderStyle: "none" }}>
                        ii. It is not in violation of any Applicable Law,
                        whether in contract, law, regulation, order or decree or
                        that it is hindered or obstructed in for effectively
                        performing its obligations under the terms and
                        conditions of this Agreement;
                      </ListGroupItem>
                      <ListGroupItem style={{ borderStyle: "none" }}>
                        iii. This Agreement creates a valid and binding
                        obligation, enforceable against it in accordance with
                        its terms;
                      </ListGroupItem>
                      <ListGroupItem style={{ borderStyle: "none" }}>
                        iv. It will provide such cooperation as reasonably
                        necessary in order to give full effect to the provisions
                        of this Agreement.
                      </ListGroupItem>
                    </ListGroup>{" "}
                    b) The Merchant further represents and covenants as follows:
                    <ListGroupItem style={{ borderStyle: "none" }}>
                      i. The Products shall be in a merchantable condition and
                      such Products shall conform to the quality standards
                      prescribed by Applicable Law, if any.
                    </ListGroupItem>
                    <ListGroupItem style={{ borderStyle: "none" }}>
                      ii. Any information provided by the Merchant together with
                      Products concerning quality, purpose, user’s guide and
                      operating instructions, descriptions, accompanying
                      certificates or any other papers for the Products are
                      correct, not misleading and complete in all material
                      respects and Customers shall have the unconditional right
                      to use the user’s guide, operating instructions,
                      descriptions and other papers / images.
                    </ListGroupItem>
                    <ListGroupItem style={{ borderStyle: "none" }}>
                      iii. The Merchant will provide the applicable Product or
                      Service warranty and shall start from the date of sale to
                      the Customer.
                    </ListGroupItem>
                    <ListGroupItem style={{ borderStyle: "none" }}>
                      iv. The intellectual property rights associated with the
                      Products, Services and the Brand are the Merchant's
                      property and that the Products or Services do not infringe
                      the intellectual rights of any third party or that the
                      Merchant has necessary authorization and permission from
                      the lawful owner of the Brand and/or Products and/or
                      Services to allow the Merchant to sell the Products or
                      provide the Services through the Platform.
                    </ListGroupItem>
                    <ListGroupItem style={{ borderStyle: "none" }}>
                      v. The Products are genuine, defect free, within expiry
                      and not counterfeits.
                    </ListGroupItem>
                    <ListGroupItem style={{ borderStyle: "none" }}>
                      vi. The Products shall, at the time of delivery to the
                      Customer (i) be free from material defects; and (ii) be
                      manufactured in accordance with good manufacturing
                      practice and Applicable Law (such warranty being referred
                      to as the "Product Warranty").
                    </ListGroupItem>
                    <ListGroupItem style={{ borderStyle: "none" }}>
                      vii. The Products shall be packaged, labelled and
                      cataloged in terms of Applicable Laws including the Legal
                      Metrology (Packaged Commodities) Rules, 2011. The
                      Merchants shall ensure that the declarations regarding the
                      quality, value etc. required to be imprinted on the
                      packages sold by Merchants are imprinted as per Applicable
                      Law and all such declarations are accurate, complete and
                      not misleading. The descriptions, images, and all other
                      content in relation to the Products that the Merchant
                      utilizes to sell the Products shall be accurate, complete
                      and not misleading, irrespective of whether the Company is
                      privy to such information or not.
                    </ListGroupItem>
                    <ListGroupItem style={{ borderStyle: "none" }}>
                      viii. The verification and supporting documents provided
                      by you to us, at the time of Merchant registration and
                      from time to time are true and correct.
                    </ListGroupItem>
                    <ListGroupItem style={{ borderStyle: "none" }}>
                      ix. If any imported goods or services are being listed for
                      sale on the Platform by the Merchant, the Merchant shall
                      correctly provide the details of the importer including
                      the name and contact information of the importer from whom
                      it purchased such goods or services, to the Company such
                      that the information is published as per Applicable Law.
                    </ListGroupItem>
                    <ListGroupItem style={{ borderStyle: "none" }}>
                      x. The Merchants shall provide to the Company and keep the
                      Company updated at all times with respect to information
                      pertaining to such Merchant including name of their
                      business, geographic address, customer care number. In the
                      event of any change in such information, the Merchant
                      shall forthwith provide the updated information to the
                      Company. The same can be provided by updating the
                      information on the ChattyBao Merchant applications, or by
                      calling the ChattyBao Merchant Help numbers provided to
                      the merchant.
                    </ListGroupItem>
                  </td>
                </tr>
                <tr>
                  <td>14.</td>
                  <td><span style={{ fontWeight: "700", color: "black" }}>Force Majeure</span></td>
                  <td>
                    Any delay or failure in the performance by either Party
                    hereunder shall be excused if and to the extent caused by
                    the occurrence of a Force Majeure event. For purposes of
                    this Agreement, “Force Majeure” shall mean a cause or event
                    that is not reasonably foreseeable or otherwise caused by or
                    under the control of the Party claiming Force Majeure, such
                    as acts of God, fires, floods, pandemics, explosions, riots,
                    wars, hurricane, sabotage terrorism, vandalism, accident,
                    restraint of government, governmental acts, injunctions,
                    labor strikes and other like events that are beyond the
                    reasonable anticipation and control of the Party affected
                    thereby.
                  </td>
                </tr>
                <tr>
                  <td>15.</td>
                  <td><span style={{ fontWeight: "700", color: "black" }}>Waiver</span></td>
                  <td>
                    The failure of any Party to enforce any term or provision
                    hereof shall not be construed to be a waiver of such term or
                    provision and shall in no way affect the right of such Party
                    thereafter to enforce such term or provision or any term or
                    provision hereof.
                  </td>
                </tr>
                <tr>
                  <td>16.</td>
                  <td><span style={{ fontWeight: "700", color: "black" }}>Amendment</span></td>
                  <td>
                    No modification, amendment or waiver of the terms and
                    conditions of this Agreement shall be valid or binding
                    unless made in writing and duly executed by the Parties.
                  </td>
                </tr>
                <tr>
                  <td>17.</td>
                  <td><span style={{ fontWeight: "700", color: "black" }}>Principal to Principal</span></td>
                  <td>
                    a) This Agreement has been entered into on a principal-to
                    principal basis and nothing contained in this Agreement
                    shall be deemed to constitute a joint venture, partnership,
                    or agency relationship between Chattybao and Merchant.
                    <br />
                    b) The Parties hereto shall not represent as an agent of the
                    other under any circumstances and at any place and at any
                    point of time and shall fulfil their obligations strictly in
                    terms of this Agreement as between two independent
                    principals in commercial transactions and none of the terms
                    and conditions of this Agreement or their context shall be
                    read or meant to be otherwise.
                  </td>
                </tr>
                <tr>
                  <td>18.</td>
                  <td><span style={{ fontWeight: "700", color: "black" }}>Notice</span></td>
                  <td>
                    All notices, requests for written approval and other
                    communication provided for in this Agreement shall be
                    submitted in writing and transmitted via email to the
                    addresses mentioned by the Merchant at the time of Merchant
                    onboarding or Partner Portal or by registered post, prepaid
                    registered airmail, courier service or facsimile
                    transmission at the registered address of the Parties.
                    Either Party shall, inform the other Party in case of any
                    changes in communication address including email IDs and
                    registered office addresses.
                  </td>
                </tr>
                <tr>
                  <td>19.</td>
                  <td><span style={{ fontWeight: "700", color: "black" }}>Severability</span></td>
                  <td>
                    If any provision of this Agreement is held by a court of
                    competent jurisdiction to be void, invalid, unenforceable or
                    illegal, such provision shall be enforced to the maximum
                    extent possible and the remaining provisions shall remain in
                    full force and effect.
                  </td>
                </tr>
                <tr>
                  <td>20.</td>
                  <td><span style={{ fontWeight: "700", color: "black" }}>Annexures and Exhibits</span></td>
                  <td>
                    You further acknowledge and understand that Annexures form
                    an integral part of this Agreement and you agree to be bound
                    by the terms therein.
                  </td>
                </tr>
                <tr>
                  <td>21.</td>
                  <td><span style={{ fontWeight: "700", color: "black" }}>Entire Agreement</span></td>
                  <td>
                    This Agreement executed between the Parties along with the
                    Annexures / Exhibits hereto shall constitute the entire
                    Agreement between the Parties hereto relating to the subject
                    matter thereof, and supersedes all previous agreements,
                    promises, representations, understandings and negotiations,
                    whether written or oral, between the Parties with respect to
                    the subject matter hereof. For the avoidance of doubt and
                    for the operational purposes, the terms and conditions of
                    services / terms of use / rules and/or any other policies
                    mentioned on the Platform shall be binding.
                  </td>
                </tr>
                <tr>
                  <td>22.</td>
                  <td><span style={{ fontWeight: "700", color: "black" }}>Survival</span></td>
                  <td>
                    Any provision of the Agreement that contemplates performance
                    or observance subsequent to termination or expiration of the
                    Agreement will survive termination or expiration of the
                    Agreement and continue in full force and effect, including
                    without limitation the following:
                    <br />
                    i. Confidential Information;
                    <br />
                    ii. Intellectual property rights;
                    <br />
                    iii. Representations, Warranties and Undertakings;
                    <br />
                    iv. Indemnity and Liability;
                    <br />
                    v. Governing Law and Dispute Resolution; and
                    <br />
                    vi. Notices.
                  </td>
                </tr>
              </tbody>
            </Table>
            <h6
              style={{ color: "black", fontWeight: "900", textAlign: "center" }}
            >
              <u>ANNEXURE 1</u>
            </h6>
            <h6 style={{ color: "black", textAlign: "center" }}>
              Merchants’ Terms Of Use (“TOU”)
            </h6>
            <p>
              Using the Platform indicates Your agreement with all the terms and
              conditions under the TOU. Please read the TOU carefully before
              proceeding. By impliedly or expressly accepting the TOU, you also
              accept and agree to be bound by Chattybao’s policies (including
              but not limited to privacy policy, return policy), as amended or
              updated by Chattybao, from time to time.
            </p>
            <h6>1. Merchant Eligibility:</h6>
            <p>
              The use of the Platform is available only to persons who can form
              legally binding contracts under the Indian Contract Act, 1872. If
              You are a minor i.e., under the age of 18 years, you shall not
              register as a Merchant on the Platform, transact or use the
              Platform. Chattybao reserves the right to terminate Your
              registration and/or refuse Your access to the Platform forthwith
              if it is brought to Chattybao’s notice or discovered that you are
              under the age of 18 years.
            </p>
            <h6>2. Your Account And Registration Obligations:</h6>
            <p>
              If You use the Platform, you shall be responsible for maintaining
              confidentiality of login and password details to the Platform and
              Partner Portal and all activities that occur with the use of login
              and password details. You agree that if you provide any
              information which is untrue, inaccurate, not current, or
              incomplete or we have reasonable grounds to suspect that such
              information is untrue, inaccurate, not current, incomplete, or not
              in accordance with the ToU (Terms of Use), we shall have the right
              to indefinitely suspend, terminate or block you from accessing the
              Platform without any notice.
            </p>
            <h6>3. Communications:</h6>
            <p>
              When You use the Platform or send emails, other data, information,
              or communication to Us, you agree and understand that You are
              communicating with Us through electronic records and You consent
              to receive communications via electronic records from Us
              periodically or as and when required. We may communicate with You
              by email, via Partner Portal or any other mode of communication
              including but not limited to WhatsApp etc., electronic or
              otherwise.
            </p>
            <h6>4. Platform For Transaction And Communication:</h6>
            <p>
              The Platform is an online Conversational commerce platform that
              users utilize to meet and interact with Merchants for their
              transactions wherein user will be on WhatsApp/ Chattybao
              application, while merchants/You will use our partner portal.
              Chattybao is not and cannot be a party to or control in any manner
              any transaction between users through the Platform,
              <br />
              Accordingly:
              <br />
              a. All commercial/contractual terms are offered by You and agreed
              upon between You and customers alone. Chattybao does not determine
              advice, have any control, or in any way involve itself in the
              offering or acceptance of such commercial/contractual terms
              between You and Customers. The “commercial/contractual terms” for
              the purposes of this Agreement mean and include (without
              limitation) price, terms, warranties and after-sales services
              related to Products and services.
              <br />
              b. Chattybao does not make any representations or warranties
              regarding specifics (such as quality, quantity, value, and
              salability) of the Products to be sold or services to be rendered,
              offered to be sold or rendered by Merchants through the Platform.
              <br />
              c. Chattybao does not implicitly or explicitly support or endorse
              the sale or purchase of any Product and services through the
              Platform, except for any conditions directly attributable under
              terms of this Agreement.
              <br />
              d. Chattybao is not responsible for any non-performance or breach
              of any contract between You and Customers. Chattybao shall not and
              is not required to mediate or resolve disputes or disagreements
              between You and Customers with respect to the Product/Services or
              the sale of the Product/rendering of Services through the Platform
              by the Merchant.
              <br />
              e. Chattybao cannot and does not guarantee that You and Customers
              concerned will perform transaction(s) concluded through the
              Platform.
              <br />
              f. Chattybao does not make any representations or warranties
              regarding Product- specifics (such as legal title,
              creditworthiness, identity, etc.) of any of its users/Customers.
              You are advised to independently verify the bona fides of any
              particular Customer you choose to deal with through the Platform
              and use your best judgment in that regard.
              <br />
              g. Chattybao does not at any point in time during a transaction
              between You and a Customer through the Platform come into or take
              possession of any of the Products or product warranty services
              offered by You, gain title to or have any rights or claim over the
              Products or product warranty services offered by You to the
              Customer through the Platform.
              <br />
              h. Chattybao is not responsible for unsatisfactory or delayed
              performance of services, damages, or delays as a result of
              Products which are out of stock, unavailable, or back- ordered.
              <br />
              i. Notwithstanding its reasonable efforts in that behalf,
              Chattybao cannot control the information provided by other users
              which is made available through the Platform. You may find other
              user's information to be offensive, harmful, inaccurate or
              deceptive. Please use caution and practice safe trading when using
              the Platform. Please note that there may be risks in dealing with
              underage persons or acting under false pretense.
              <br />
              j. Chattybao does not guarantee the identity of any User nor does
              it ensure that a Customer or a Merchant will complete a
              transaction.
              <br />
              k. Chattybao reserves the absolute right to cancel all past,
              pending and future orders without any liability as per the “Terms
              and Conditions” of the Platform, as amended or updated by
              Chattybao, from time to time.
            </p>
            <h6>5. Use Of The Platform:</h6>
            <p>
              You agree, undertake and confirm that Your use of the Platform
              shall be strictly governed by the following binding principles:
              <br />
              a. You shall not upload, transmit, update or share any information
              which:
              <ListGroup>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  i. belongs to another person and over which You have no right;
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  ii. is grossly harmful, harassing, blasphemous, defamatory,
                  bigotry, obscene, pornographic, pedophilic, libelous, invasive
                  of another’s privacy, hateful, or racially, ethnically
                  objectionable, disparaging, relating to or encouraging money
                  laundering or gambling, or otherwise unlawful in any manner
                  whatever, or unlawfully threatening or harassing, including
                  but not limited to ‘indecent representation of women’ within
                  the meaning of the Indecent Representation of Women
                  (Prohibition) Act, 1986;
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  iii. is false, inaccurate or misleading in any way;
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  iv. involves the transmission of ‘junk mail’, ‘chain letters’,
                  unsolicited mass mailing, or ‘spamming’;
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  v. infringes upon or violates any third party's rights
                  including but not limited to intellectual property rights,
                  rights of privacy (including without limitation unauthorized
                  disclosure of a person's name, email address, physical
                  address, or phone number) or rights of publicity;
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  vi. promotes an illegal or unauthorized copy of another
                  person's copyrighted work (see “Copyright complaint" below for
                  instructions on how to lodge a complaint about uploaded
                  copyrighted material) such as providing pirated computer
                  programs or links, information to circumvent
                  manufacturer-installed copy- protect devices, or pirated music
                  or links to pirated music files;
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  vii. contains restricted or password-only access pages, hidden
                  pages or images (those not linked to or from another
                  accessible page);
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  viii. provides material that exploits people in a sexual,
                  violent or otherwise inappropriate manner or solicits personal
                  information from anyone;
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  ix. provides instructional information about illegal
                  activities such as making or buying illegal weapons, violating
                  someone's privacy, providing or creating computer viruses;
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  x. contains unauthorized videos, photographs or images of
                  another person (whether a minor or an adult);
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  xi. tries to gain unauthorized access or exceeds the scope of
                  authorized access to the Platform, profiles, blogs,
                  communities, account information, bulletins, friend requests,
                  or other areas of the Platform, or solicits passwords or
                  personal identifying information for commercial or unlawful
                  purposes from other users on the Platform;
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  xii. solicits gambling or engages in any gambling activity
                  which we, at our sole discretion, believe is or could be
                  construed as being illegal;
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  xiii. interferes with another’s use and enjoyment of the
                  Platform;
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  xiv. refers to any Platform/URL which, at our sole discretion,
                  contains material that is inappropriate for the Platform;
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  xv. violates any Applicable Law;
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  xvi. deceives or misleads the addressee/ users / Customers
                  about the origin of messages or communicates any information
                  which is grossly offensive or menacing in nature;
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  xvii. contains software viruses or any other computer codes,
                  files, or programs designed to interrupt, destroy, or limit
                  the functionality of any computer resource; or contains any
                  trojan horses, worms, time bombs, cancelbots, easter eggs, or
                  other computer programming routines that may damage,
                  detrimentally interfere with, diminish value of,
                  surreptitiously intercept, or expropriate any system, data, or
                  personal information;
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  xviii. threatens the unity, integrity, defense, security or
                  sovereignty of India, friendly relations with foreign states,
                  or public order or causes incitement to the commission of any
                  offence or prevents investigation of any offence or is
                  insulting any other nation;
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  xix. shall, directly or indirectly, offer or attempt to offer
                  trade or attempt to trade in any Product on the Platform which
                  is prohibited or restricted in any manner under the provisions
                  of any applicable law, rule, regulation or guideline for the
                  time being in force;
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  xx. shall create liability for Us or cause Us to lose (in
                  whole or part) the services of our Internet Service Provider
                  (“ISPs”) or other Merchants.
                </ListGroupItem>
              </ListGroup>
              <br />
              b. From time to time, you shall be responsible for providing
              information relating to the Product or Services.
              <br />
              c. You understand that We have the right at all times to disclose
              any information (including the identity of the persons who have
              provided information or material on the Platform) as necessary to
              satisfy any law, regulation, or valid governmental request under
              any Applicable Law or otherwise. This may include, without
              limitation, disclosure of the information in connection with the
              investigation of an alleged illegal activity or its solicitation
              and/or response to a lawful court order or subpoena. In addition,
              We can (and you hereby expressly authorized us to) disclose any
              information about You to law enforcement or other government
              officials as We, in our sole discretion, deem necessary or
              appropriate in connection with the investigation and/or resolution
              of possible crimes, especially those that may involve personal
              injury. We reserve the right, but have no obligation, to monitor
              the material transmitted/posted through the Platform.
              <br />
              d. Chattybao shall have the right, at its sole discretion, to
              remove or edit any content that violates or is alleged to violate
              any Applicable Law or terms of this Agreement. Notwithstanding
              this right, You remain solely responsible for the content of the
              material You send through the Platform. Please be advised that
              such content upload/transmitted/shared does not reflect
              Chattybao’s views.
              <br />
              e. Chattybao shall have all the rights to take necessary action
              and claim damages that may occur due to Your
              involvement/participation in any way on Your own or through
              group(s) of people, intentionally or unintentionally, in denial of
              product warranty service.
              <br />
              f. Chattybao may decide to provide functionality to the merchants,
              to showcase their products through certain features like catalog.
              Any information related to products created by merchants, should
              be a sole responsibility of the merchant and may not be reviewed
              by Chattybao before showcasing it to the customers.
              <br />
              g. Merchant shall provide after-sales services related to
              Products/Services including but not limited to warranty services,
              within a time period and manner as specified or agreed.
              <br />
              h. Subject to Applicable Laws, Merchant shall ensure that no
              Products are sourced or used in the manufacturing or in the
              provision of services which originate from any of the countries
              with which trade is restricted by the Government of India.
              <br />
              i. Merchant agrees that all the term & conditions of the Whatsapp
              or any other platform used by Chattybao shall also be applicable
              to them.
              <br />
              j. Notwithstanding anything to the contrary, Chattybao shall have
              the right to use chat data for other services and businesses of
              ChattyBao, which may include working with third party providers
              for lending or other commerce services. Chattybao shall also have
              the right to collect and analyze data relating to the provision,
              use and performance of the Services (including, without
              limitation, information concerning User Data and data derived
              therefrom). Company will be free (during and after the term
              hereof) to use such data except the user identification data: (a)
              to enhance the Services, (b) for development, diagnostic and
              corrective purposes in connection with the Services in order to
              provide better support to the User, and (c) to disclose it in
              aggregate or other de-identified form in connection with its
              business. No rights or licenses are granted except as expressly
              set forth herein.
              <br />
              k. It shall be the duty of the Merchant to ensure that all
              information supplied to the Customers is accurate and in
              accordance with applicable laws (including without limitation
              under the Legal Metrology (Packaged Commodities) Rules, 2011).
              <br />
              l. It shall be the duty of the Merchant to ensure that all the
              information required to be supplied and shown to the Customers
              along with the Products in accordance with applicable laws
              (including without limitation under the Legal Metrology (Packaged
              Commodities) Rules, 2011) is actually supplied and shown to the
              Customers in real-time
              <br />
              m. The Merchant shall ensure that it receives an explicit written
              consent of the Customers for purchase of products/ services before
              actually concluding the sale to the Customers. The Merchant shall
              keep such consent on record and share with ChattyBao periodically
              or upon request.
            </p>
            <h6>6. Selling:</h6>
            <p>
              Listings may only include text descriptions, graphics and pictures
              that describe Your Entity or Brand. All Merchants must be listed
              in an appropriate category on the Platform. The listing
              description of the Merchant must not be misleading. Customer will
              connect with Local Merchants for their needs for products and
              services across multiple categories through Whatsapp or other
              ChattyBao applications by selecting the appropriate category.
              Customers will be on whatsapp or any other platform powered by
              Chattybao while you will use our partner portal. User will
              directly interact with You either by text, image, audio or video.
              Merchant will be able to choose the delivery option.
            </p>
            <h6>7. Payment Facility For Customers:</h6>
            <p>
              a) Transactions, transaction price and all commercial terms such
              as delivery, dispatch of Products are as per
              principal-to-principal bipartite contractual obligations between
              Merchants and Customers and the payment facility is merely used by
              Merchants and Customers to facilitate the completion of
              transactions.
              <br />
              b) You have specifically authorized Chattybao to instruct the
              payment gateway service provider to collect, process, facilitate,
              and remit payments, refunds and/or the transaction price,
              electronically, to and from Customers in respect of transactions
              through payment facilities available through the Platform. Use of
              the payment facility shall not render Chattybao liable or
              responsible for non-delivery, non-receipt, non- payment,
              non-provision of after-sales or warranty services or fraud as
              regards the Products/Services sold/rendered through the Platform.
              Therefore, you understand, accept, and agree that the electronic
              payment facilities provided by the payment gateway service
              provider appointed by Chattybao is neither a banking nor financial
              service, but merely a facilitator providing anelectronic,
              automated online electronic payment facility for receiving
              payment, collection and remittance for transactions on the
              Platform using the existing authorized banking infrastructure and
              credit card payment gateway (PG) network. Further, by providing
              payment facility, Chattybao neither acts as a trustee nor
              fiduciary with respect to transaction or transaction price.
              <br />
              c) It is hereby clarified that cash on delivery (<span style={{ fontWeight: "700", color: "black" }}>“CoD”</span>) option may
              be provided by the merchant directly for their products at their
              own discretion.
            </p>
            <h6>8. Payment Facility For Merchants:</h6>
            <p>
              The remittance to the Merchant shall be made in accordance with
              the RBI Intermediary Guidelines as amended from time to time and
              after deduction of all charges and applicable taxes at source as
              may be applicable.
            </p>
            <h6>9. Merchant’s Delivery Obligations:</h6>
            <p>
              a) You, as a Merchant, shall directly or through the logistic
              service provider engaged by you shall be required to dispatch the
              Products for every transaction to the Customer, or you may opt to
              use ChattyBao Delivery Service, as per the Platform standards and
              policies, and all terms of such delivery including terms agreed as
              per the Annexure 4. It is hereby clarified and agreed by the
              Merchant that Chattybao is merely a facilitator of the services
              provided through the Platform.
              <br />
              b) Merchant shall instruct its Logistic Service Provider to
              dispatch details and details of after-sales services related to
              Products and services to Chattybao in such a manner and within a
              time period as specified by Chattybao.
              <br />
              c) Notwithstanding the terms of Annexure 4, any and all Proof of
              Delivery (<span style={{ fontWeight: "700", color: "black" }}>“PoD”</span>) documentation relating to delivery should be
              maintained by the Merchant for a period of twelve (12) months from
              the date of dispatch to the Customer and shall be furnished to
              Chattybao on demand within the time frame as notified from time to
              time.
              <br />
              d) Merchant shall ensure that the dispatch details provided to
              Chattybao by the Logistic Service Provider are true, correct, and
              duly authorized and shall not be misleading, fraudulent, false,
              unauthorized, or illegal and shall not contain any
              misrepresentation of facts. In case a Merchant fails to provide
              dispatch details or provides dispatch details not complying with
              policies, it shall result in consequences as more specifically
              stated in the policies and may lead to suspension and/or
              termination of the Merchant’s account.
              <br />
              e) For home delivery service, merchant shall provide correct address for delivery.
              If the actual delivery location is far from the provided location,
              ChattyBao may debit an extra delivery charge for it's service, for the incremental
              distance, as per the prevailing rate card.e. For home delivery service, merchant shall
              provide correct address for delivery. If the actual delivery location is far
              from the provided location, ChattyBao may debit an extra delivery charge for it's service,
              for the incremental distance, as per the prevailing rate card.
              <br />
              f) Chattybao agrees that the transaction price paid by a Customer
              will be remitted to a Merchant’s bank account only after deducting
              any dues payable related to services rendered and/or tax-related
              thereto including but not limited to TCS, TDS etc., contingent
              upon the following events:
              <ListGroup>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  i. successful delivery of Products to the Customer;
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  ii. Customer does not take any action on payment facility (eg.
                  to confirm delivery within such a time period as provided in
                  the policies of the Platform, despite confirmation of dispatch
                  of Products received by the Customer);
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  iii. Customer’s refund claim is rejected by Chattybao due to
                  any breach of the ToU, policies, and/or any Applicable Law.
                </ListGroupItem>
              </ListGroup>
              g) Remittances to a Merchant (after deduction of Charges/Fee at
              applicable rates including present and past if any and all
              applicable taxes) for successful transactions under promotional
              offers (<span style={{ fontWeight: "700", color: "black" }}>“PO”</span>) may be made in multiple transactions through its
              bank accounts and would be in compliance with directions issued by
              the Reserve Bank of India (RBI) for opening and operation of
              accounts and settlement of payments for electronic payment
              transactions involving intermediaries vide it’s the relevant RBI
              notification (‘RBI Intermediary Guidelines”).
              <br />
              h) <span style={{ fontWeight: "700", color: "black" }}>Transaction Timelines</span>: Merchant hereby undertakes that the
              transaction on the Platform shall be deemed to be complete only
              once the following action items are concluded by the respective
              parties as detailed below: Action Item Days
              <Table bordered style={{ color: "#424149" }}>
                <thead>
                  <tr>
                    <th>Sl No.</th>
                    <th>Action Item</th>
                    <th>Days</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Merchant to procure and ship the product (s) sold</td>
                    <td>
                      1 day (starting from the placement of the order by the
                      Customer)
                    </td>
                  </tr>
                </tbody>
              </Table>
            </p>
            <h6>10. Chattybao’s Delivery Obligations:</h6>
            <p>
              Merchant can avail the delivery services provided by Chattybao
              directly or through aligned third party service providers, at a
              fee agreed and displayed to the merchant on the merchant
              application. ChattyBao at its sole discretion, may offer the
              delivery option to all or select merchants. Merchant will be
              responsible for appropriately packaging and carefully handing over
              the right product to the delivery partners of ChattyBao. In case
              of any complaint from Customer in regard to damaged / wrong /
              short / expired Products, Seller shall replace / refund such
              product to the Customer and Merchant will be sole responsible for
              such damaged or inconvenience caused to the Customer. Merchant
              agrees and acknowledges that when the Customer places an order for
              any of its Products and If the delivery of such Product is to be
              made by the delivery partners (<span style={{ fontWeight: "700", color: "black" }}>"Delivery Partner"</span>) of ChattyBao,
              in that event:
              <br />
              i. Merchant agrees to ensure that the Product is ready for
              dispatch to the Delivery Partner as per the agreed timelines.
              <br />
              ii. Merchant shall ensure proper coordination with the Delivery
              Partner for timely handover of products.
              <br />
              Parties hereby agree that Chattybao shall not be responsible for
              any loss, damage, costs or liabilities that may arise as a
              consequence of the delivery service provided by Chattybao.
            </p>
            <h6>11. Charges For Additional Services:</h6>
            <p>
              Chattybao may, at its sole discretion, introduce new services and
              modify some or all of the existing services offered. In such an
              event, Chattybao reserves the right to introduce fees for the new
              services offered or amend/introduce fees for existing services, as
              the case may be. Changes to the fee policy shall either be posted
              on Partner Portal or notified via email giving Merchants seven (7)
              days advance notice of such change. Unless otherwise stated, all
              fees shall be quoted in Indian Rupees (INR) and payable to
              Chattybao. You shall be solely responsible for compliance with all
              Applicable Laws, including those in India, for making payments to
              Chattybao. Payments (including but not limited to fee charges or
              taxes) payable to the Chattybao for the services provided by them
              can be deducted from the merchant’s payout.
            </p>
            <h6>12. CB Balance:</h6>
            <p>
              i. At the time of account creation, a virtual closed-loop account, CB Balance, is created for the merchant<br />
              ii. CB Balance is a digital account maintained on your behalf to seamlessly carry out transactions<br />
              iii. Amount in CB Balance can be added by you or by ChattyBao in the form of cashback, as per the cashback policy<br />
              iv. The balance can be used to pay for services offered on the CB Partner app, currently including home delivery services or for renewal of subscription plan<br />
              v. The balance cannot be transferred to any bank account or used for any service outside of CB Partner app<br />
              vi. The amount in CB Balance will expire after 12 months of inactivity in the wallet<br />
              vii. Any penalty levied on your account can also be recovered from the CB Balance
            </p>
            <h6>13. Taxes:</h6>
            <p>
              a. You are responsible for paying all fees associated with the use
              of the Platform and You agree to bear any and all applicable
              taxes, charges, cesses etc. levied thereon.
              <br />
              b. A Merchant expressly agrees that issuing correct and complete
              invoice is the sole and primary responsibility of the Merchant.
              <br />
              c. Merchants are required to mandatorily submit their PAN and TAN
              details along with any other documents required as per Applicable
              Law in order to comply with tax audits and to ensure a hassle-free
              tax assessment process.
            </p>
            <h6>14. Chargeback:</h6>
            <p>
              a. “Chargeback” shall mean the reversal of payment made by the
              Customer in respect of any order which the Customer disputes as
              transaction not done by Customer/payer or the delivery of Products
              not received by Customer despite making payment or the allegation
              that transaction has been done by unknown persons which is
              disputed by Customer or payer and a request for Chargeback/refund
              has been made by the Customer or payer through the respective
              bank.
              <br />
              b. The Parties acknowledge, that Chattybao shall not be
              responsible for any refund or Chargeback due to any reason
              whatsoever (i) if such a request is received after the delivery of
              Product to the Customer for which the Customer has performed the
              Transaction, and (ii) if Chattybao or Merchant has sufficient
              proof of successful delivery made to the Customer.
              <br />
              c. In case of any Chargebacks levied by the bank, Chattybao shall
              have the right to deduct such Chargebacks from Merchant
              remittances, present and future, and a Merchant’s only remedy will
              be to discuss and resolve the same with the bank. A Merchant
              hereby agrees to extend full co-operation in resolving the
              Chargeback disputes raised by a Customer through the bank and
              shall provide necessary documentation regarding the transaction to
              the complete satisfaction of the bank. In case the chargeback is
              ruled against a Merchant, Chattybao shall be entitled and
              authorized to recover the same from the Merchant and the bank’s
              decision shall be final and binding in this regard. In case,
              Chattybao has made any excess payment to Merchant inadvertently,
              such excess payments shall be set-off from any future payments
              payable by Chattybao to the Merchant.
              <br />
              d. Chattybao may delay notifying the payment confirmation, i.e.
              informing Merchant to dispatch if Chattybao deems suspicious or a
              Customer conducts high transaction volumes to ensure safety of the
              transaction and transaction price. In addition, Chattybao may hold
              transaction price or remit transaction price to law enforcement
              officials (instead of refunding the same to a Customer) at the
              request of law enforcement officials or in the event of a Customer
              being engaged in any form of illegal activity.
              <br />
              e. Merchants acknowledge that Chattybao will not be liable for any
              damages, interests, Claim etc. resulting from not processing a
              transaction/transaction price or any delay in processing a
              transaction/transaction price that is beyond the control of
              Chattybao.
              <br />
              f. Chattybao shall make payments into the bank account provided by
              a Merchant during the Merchant registration process. Once
              Chattybao has made payments into such a bank account number,
              Chattybao shall be discharged of any/all liabilities towards the
              Merchant and the Merchant shall not be eligible for any claim
              whatsoever.
            </p>
            <h6>15. Audits:</h6>
            <p>
              Chattybao shall have the right to inspect and audit Merchant’s
              records and premises / place of business through itself or through
              Chattybao approved third party testing agencies solely to ensure
              compliance with the terms of this Agreement. Cost of such audit
              shall solely be borne by Chattybao unless the audit reflects
              discrepancy in Merchant accounts / non-compliance with Chattybao’s
              policies, in which case cost of audit shall be borne by the
              Merchant.
            </p>
            <h6
              style={{ color: "black", fontWeight: "900", textAlign: "center" }}
            >
              <u>ANNEXURE 2</u>
            </h6>
            <h6 style={{ color: "black", textAlign: "center" }}>
              Subscription Plans/ Charges/ Fees/ Commission Policy
            </h6>
            <h6>1. Subscription Plans and other Charges / Fees:</h6>
            <p>
              Merchant can find the details of all Plans, applicable charges and
              fees under the Charges section on the merchant portal. At the time
              of signup, all merchants are assigned a default/ regular plan,
              depending on their business category. Merchant can opt for a
              Subscription Plan after reading the plan details, benefits and
              applicable Terms and Conditions, via the merchant application/
              portal. Provided further that each plan has an expiry date, once
              the plan is expired all the benefits associated with plan shall
              cease to exist till the renewal of plan. Merchant can signup for
              auto renewal of plan at the time of initial payment for the plan.
            </p>
            <h6>2. Payments:</h6>
            <p>
              Merchants have the option to make payments for their subscription
              plans either through the UPI link or the UPI Auto Pay feature
              provided on the ChattyBao Merchant Portal. Both payment methods
              are secure and convenient for merchants to use. Notwithstanding of
              the chosen payment option, the subsequent monthly deductions for
              subscription charges can also be deducted through the daily
              settlement of the merchant.
            </p>
            <h6>3. Non-Payment:</h6>
            <p>
              Chattybao reserves the right to issue a warning to you or
              temporarily/ indefinitely suspend/ terminate your account/listing
              on the Platform and/or refuse to provide you with access to the
              Platform in case of non-payment of any fees/dues or processing of
              refunds by you to Chattybao. Chattybao also reserves the right to
              take legal action in case of non-payment of fees/dues by you to
              Chattybao.
            </p>
            <h6>4. Payment Surcharges</h6>
            <p>
              i. Merchants shall not charge Customers an additional fee for
              their use of ordinary forms of payment, including electronic
              transfers or credit cards. Such costs should be built into the
              price of the Product. This policy reduces the potential for
              confusion among Customers about the true cost of a Product.
              Further, some forms of payment surcharges, such as credit card
              surcharges, are prohibited by law or under the issuing
              institution's rules and regulations for merchants.
              <br />
              ii. Parties expressly agree that any/all
              remittance/settlement-related pay-outs shall be deemed to have
              been accepted by Merchant if Merchant does not furnish a written
              objection specifying the nature of the dispute within ten (10)
              days from the date of transaction.
            </p>
            <h6>5. Change in bank details:</h6>
            <p>
              In order to avoid any unnecessary charges or penalties on Us, You
              acknowledge to inform Us (in writing), with at least 15 business
              days prior notice, in the event You decide to change Your bank
              details, warehouse address, or communication address. In case You
              fail to update Us on such changes, We shall not be held liable to
              pay any charges or penalties for the same.
            </p>
            <h6>6. Promotional Offers:</h6>
            <p>
              ChattyBao may provide different promotional offers from time to
              time for the benefit of Merchants. The offers and applicable terms
              and conditions will be communicated to the applicable merchants at
              the time of applicability of offers.
            </p>
            <h6>7. Miscellaneous:</h6>
            <p>
              a) The merchant retains the right to terminate the plan by
              contacting the helpdesk as per the information provided on the
              application. However, it should be noted that no refund shall be
              provided for the remaining duration of the plan in the event of
              cancellation during the term.
              <br />
              b) Merchant settlements are subject to deductions for settlement
              charges and government taxes, which are calculated on a per-order
              basis. These charges apply to the net amount receivable after
              refunds have been accounted for. A detailed breakdown of the
              charges can be found in the ChattyBao Merchant portal and will be
              available for the purpose of reconciliation.
            </p>
            <h6
              style={{ color: "black", fontWeight: "900", textAlign: "center" }}
            >
              <u>ANNEXURE 3</u>
            </h6>
            <h6 style={{ color: "black", textAlign: "center" }}>
              MRP BREACH POLICY
            </h6>
            <p>
              The MRP Breach Policy states that a Merchant cannot mention a
              higher selling price to the Customer than that mentioned on the
              MRP label of the Product.
            </p>
            <h6>1. MRP Breach Policy</h6>
            <p>
              Merchant acknowledges that the Product cannot be sold at a higher
              price than the MRP (Maximum Retail Price) stated on the box.
              Merchants who sell Products at a higher price than the MRP will be
              suspended and further blacklisted. Following are the cases in
              which the MRP Breach Policy is applicable:{" "}
              <ListGroup>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  i. Pricing your Product above the MRP on the Platform.
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  ii. Charging the Customer more than the MRP mentioned on the
                  label of the Product.
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  iii. Charging an additional fee in the form of taxes over and
                  above the MRP. This is prohibited as MRP mentioned is all
                  inclusive of taxes.
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  iv. Labels without mandatory declarations as prescribed by the
                  Applicable Laws and the Government of India.
                </ListGroupItem>
              </ListGroup>
            </p>
            <h6>
              2. Mandatory provisions to be included on the MRP declaration
              label amongst others are:
            </h6>
            <p>
              <ListGroup>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  i. The name and address of the manufacturer, the name and
                  address of both the manufacturer and the packer (when they are
                  not same) or the name and address of the importer.
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  ii. The common or generic name of the commodity.
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  iii. Net quantity contained in the package (excluding the
                  weight of any packing material).
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  iv. Month and year of manufacture or import.
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  v. The retail sale price.
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  vi. The name of the office, address, telephone no and e-mail
                  id (optional) of the person who can be or the office which can
                  be contacted, in case of consumer complaints
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  vii. Country of Origin
                </ListGroupItem>
              </ListGroup>
              Further note, that only those labels, which are securely affixed
              on the container are valid.
            </p>
            <h6>
              If the Merchant is found guilty of violating the MRP Policy, the
              following actions will be taken:
            </h6>
            <p>
              Defaulters will be blacklisted and will be liable for breach under
              the Legal Metrology Act, 2009 and the Legal Metrology (Packaged
              Commodities) Rules, 2011.
            </p>
            <h6
              style={{ color: "black", fontWeight: "900", textAlign: "center" }}
            >
              <u>ANNEXURE 4</u>
            </h6>
            <h6>
              1. Fulfilment by Merchant: (End-to-End Delivery and Returns)
            </h6>
            <p>
              a) Merchant may use his/their own delivery services at their own
              risk.
              <br />
              b) The Logistic Service Provider appointed by You shall be
              conducting end-to-end delivery of the Product.
              <br />
              c) Merchant shall ensure that their Logistic Service Provider are
              responsible for;
              <ListGroup>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  i. packaging, delivery, installation, re-packaging,
                  uninstallation and reverse logistics of Products;
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  ii. reverse logistics;
                </ListGroupItem>
                <ListGroupItem style={{ borderStyle: "none" }}>
                  iii. using the packaging material with “Chattybao” name and/or
                  logo, for delivery of Products sold through the Platform, if
                  provided by the Chattybao. Under no circumstances shall the
                  packaging material contain name, trademarks, logos, images,
                  symbol, text, graphics, photographs and/or other material or
                  information belonging to any third party including any
                  platform other than Chattybao.
                </ListGroupItem>
              </ListGroup>
              <br />
              d) Parties hereby agree that Chattybao shall not be responsible
              for any loss, damage, costs or liabilities that may arise as a
              consequence of the delivery, installation, packaging, re-
              packaging, uninstallation, reverse logistics or any post-sale
              service provided by the Merchant to the Customer. Merchant agrees
              to indemnify Chattybao against any damage suffered by Chattybao as
              a consequence of any breach or claim on Chattybao with respect to
              such services provided by the Merchant.
              <br />
              e) Merchant agrees to observe the instructions and guidelines
              determined by Chattybao with respect to shipment and regularly
              update the status of shipment against every order to Chattybao.
              The status may be updated as ‘dispatched’, ‘delivered’,
              ‘installed’ etc. (as appropriate).
            </p>
            <h6>
              2. Chattybao reserves the right, at its sole discretion, to
              change, modify, add, or remove portions of this policy at any time
              and without any prior written notice to the Merchant. It is the
              responsibility of the Merchant to review this policy periodically
              for updates/changes.
            </h6>
            <h6>
              3. You shall ensure that the consignment is properly packed with
              thick paper material to avoid damage. You shall use the packaging
              material with “Chattybao” name and/or logo, if provided by
              Chattybao for delivery of each Products (“Packaging Material”).
              The Packaging Material provided shall be in accordance with
              applicable laws including without limitation Legal Metrology Act,
              2009 and the Legal Metrology (Packaged Commodities Rules), 2011.
              Under no circumstances, shall the packaging material contain name,
              trademarks, logos, images, symbol, text, graphics, photographs
              and/or other material or information belonging to any third-party
              including any third-party platform. Merchant shall place a
              purchase order for the Packaging Material with Chattybao as per
              the requirements of Merchant for fulling its obligations under
              this Agreement.
            </h6>
            <h6>4. Delivery:</h6>
            <p>
              a) Merchant shall be responsible for end-to-end delivery of the
              Product till the location specified by the Customer.
              <br />
              b) Merchant shall deliver the Products to the Customer within the
              committed timelines.
              <br />
              c) A request for early or late delivery by the Customer will be
              entertained at the sole discretion of the Merchant on a
              case-to-case basis. Chattybao will be in no way responsible for
              the costs incurred due to change in the delivery schedule by
              Merchant.
              <br />
              d) Merchant shall update the tracking ID for each shipment on the
              Merchant panel for delivery either directly or through a
              third-party logistics service provider.
            </p>
            <h6>5. Reverse Logistics:</h6>
            <p>
              a. Merchant shall be solely responsible for arranging the visit by
              logistic service provider’s personal; (wherever applicable),
              uninstallation (if required), repackaging, pick up & reverse
              logistics of the product from Customer’s location in case of
              returns or replacement.
              <br />
              b. Once a return or replacement request is raised by the Customer,
              Chattybao customer support will intimate the Merchant about such
              request.
              <br />
              c. If the Merchant is unable to arrange for a logistic personal to
              visit within the agreed timeline, return request will be approved
              and processed by Chattybao.
              <br />
              d. Customer will be refunded the entire amount of the product to
              the original source of payment in case of failure of the Merchant
              to arrange for reverse pick up within 10 days from the day
              customer raises a return request.
            </p>
            <h6
              style={{ color: "black", fontWeight: "900", textAlign: "center" }}
            >
              <u>ANNEXURE 5</u>
            </h6>
            <h6 style={{ color: "black", textAlign: "center" }}>
              Merchant Blacklisting Policy
            </h6>
            <p>
              The Merchant Blacklisting Policy has been designed to maintain
              standards and ensure consistent quality at Chattybao. In addition,
              the Policy is also applicable in cases related to behavioral
              issues (personal or company level) or violation of specific
              policies listed out by Chattybao.
            </p>
            <h6 style={{ color: "black" }}>
              The following reasons would lead to blacklisting of a Merchant:
            </h6>
            <h6>1. Selling Fake Products:</h6>
            <p>
              Merchant must ensure that all the Products to be sold by them are
              genuine. If the Merchant attempts to sell fake products through
              the portal he/she will be blacklisted. Chattybao is against the
              sale of replicas, counterfeit products, products infringing
              trademark or copyright, imitations of the original product or
              unauthorised materials. An attempt to sell any of these will lead
              to blacklisting.
              <br />
              You must refrain from doing the following:
              <br />
              a. Using a trademark/ logo, whether on a product or in any other
              manner that you don’t own or have acquired the license in respect
              of or have authority to affix/ emboss on your private label
              product;
              <br />
              b. Using the name of a brand that you don’t own or have the
              authority to use in the title of your product or in the listing
              attribute “Brand” or as your Merchant Display name;
            </p>
            <h6>2. Copyright:</h6>
            <p>
              A right held by the creator in certain creative works such as
              literature, artistic works, paintings, music, computer programs,
              sound recordings and films. A copyright gives the creator of an
              original work exclusive rights to it and to license it to others.
              <br />
              Examples:
              <br />
              a. The images uploaded by a Merchant for a product is a copyright
              image owned by that Merchant and can not be used by another
              Merchant without permission.
              <br />
              b. Image available on Google Search is owned by third parties and
              permission will be required to use the same.
            </p>
            <h6>3. Poor Performance:</h6>
            <p>
              A Merchant will be blacklisted if the Merchant has poor
              performance based on the following criteria:
              <br />
              a. High returns for any reason including mis-shipment, missing
              Products, damaged products or poor quality products, used/ expired
              products or products not covered by warranty
              <br />
              b. Poor Merchant feedback from Customers and multiple customer
              escalations / claims;
              <br />
              c. Copyright/ trademark infringement
              <br />
              d. Price/ Discount abuse
              <br />
              e. Abusive behavior
              <br />
              f. Using packaging materials of competitors
              <br />
              g. Any attempts at fraudulent activity that will affect customers,
              other Merchants or Chattybao
              <br />
              h. Physical audit results
              <br />
              i. Non-payment of dues to Chattybao and/or Logistics Service
              Provider
              <br />
              j. sourcing of Products with whom India has a trade embargo
              <br />
              k. Mystery shopping results.
              <br />
              l. High response time to customer request.
              <br />
              m. Continuous breach of IPR law or any other law that may be
              applicable.
              <br />
              The Merchant may be blacklisted due to poor performance on a
              single criterion or a combination of the aforementioned factors in
              Chattybao’s sole direction.
            </p>
            <h6>4. Breach of Contract:</h6>
            <p>
              In situations where the Merchant has entered a separate
              contractual agreement with Chattybao under which they are
              obligated to perform in a certain manner or pay against the
              deliverables provided by Chattybao and the Merchant is found in
              breach of the said contract, she/he would be delisted with
              immediate effect and notified of the same. Under this situation,
              the entire portfolio of the Merchant is subject to being delisted.
            </p>
            <h6>5. Prohibited products:</h6>
            <p>
              As a registered Merchant, you may sale the products or provide the
              Services through the portal in accordance with the policies which
              are incorporated by way of reference in the ToU. You must be
              legally able to sell the Product(s) or provide the Services which
              you list for sale on the portal, failing which, you will be
              blacklisted.
            </p>
            <h6>6. Mature Audience Listing:</h6>
            <p>
              Under Indian law, it may be a criminal offense for you to sell
              certain types of films, publications, MMS or computer games that
              may be adult in nature or are otherwise not appropriate for minors
              (individuals under 18 years of age) or are prohibited under the
              law and the same should not be sold through the portal.
              <br />
              Violations of this policy may result in a range of actions,
              including but not limited to the following:
              <br />
              a. Listing cancellation
              <br />
              b. Limits placed on account privileges
              <br />
              c. Loss of special status and/or
              <br />
              d. Account suspension
            </p>
            <h6>7. Disputes:</h6>
            <p>
              Merchants who have a high number of disputes raised against them
              by Customers will be blacklisted.
            </p>
            <h6>8. Negative Feedback:</h6>
            <p>
              If a Merchant consistently receives negative feedback from
              Customers, he/she will be blacklisted.
            </p>
            <h6>9. MRP Breach:</h6>
            <p>
              A Merchant found breaching the MRP Policy by quoting a higher
              price than that mentioned on the product's MRP declaration label
              will be blacklisted.
            </p>
            <h6>10. Threat & abuse:</h6>
            <p>
              Chattybao expects its Merchants, as our business partners, to be
              courteous, professional and respectful while interacting with us
              via email or any other form of communication. Failing to do so, a
              Merchant will be given two initial warnings and will then be
              blacklisted. The following will be considered as threat & abuse:
              <br />
              a. Threat of bodily harm via email/phone or chat
              <br />
              b. Misuse of Chattybao's system
              <br />
              c. Spoof (fake) email
              <br />
              d. Spam
              <br />
              e. Offers to buy or sell outside the portal
              <br />
              Threats of physical harm via email, phone calls or public message
              boards will be dealt with seriously. Failure to take correct
              measures will lead to blacklisting of the Merchant.
            </p>
            <h6>11. Missing Products/Irrelevant Material:</h6>
            <p>
              If a Merchant is found shipping empty boxes or boxes filled with
              irrelevant material (example: Stones, pebbles, garbage etc.)
              instead of the actual product to the Customer, he/she will be
              blacklisted.
            </p>
            <h6>12. Fraud:</h6>
            <p>
              Willful intention to commit fraud against fellow Merchants,
              Customers or Chattybao platform will lead to blacklisting.
            </p>
            <h6>13. Packaging issues:</h6>
            <p>
              Repeated use of poor-quality packaging/ using Chattybao’s
              competitors packaging will lead to blacklisting.
            </p>
            <h6>14. Linked accounts:</h6>
            <p>
              If a Merchant holds multiple accounts, all the accounts will be
              blacklisted as per the blacklisting policy.
            </p>
            <h6>15. Shipping products of incorrect weight:</h6>
            <p>
              If the weight and dimensions of the actual package of the Product
              is found to be less or greater than the weight and dimensions of
              the Product mentioned while selling the product, Chattybao shall
              have the right to take necessary action against the Merchant,
              including but not limited to blacklisting of the Merchant.
            </p>
            <h6>16. Anti-Profiteering:</h6>
            <p>
              If the benefit of reduction in GST rate as provided by the
              Government of India has not been passed on to the end
              customer/consumer and has been retained by the Merchant, while
              selling the product or providing the services, Chattybao shall
              have the right to blacklist the Merchant.
            </p>
            <h6
              style={{ color: "black", fontWeight: "900", textAlign: "center" }}
            >
              <u>ANNEXURE 6</u>
            </h6>
            <h6 style={{ color: "black", textAlign: "center" }}>GST TERMS</h6>
            <p>
              In the interest of smooth functioning and better efficiency of the
              business, the Parties agree that:
              <br />
              1. The invoice shall be raised by the respective parties within
              the time as prescribed under The Central Goods and Service Tax Act
              2017.
              <br />
              2. “You shall also ensure full compliance with the provisions of
              Integrated Goods and Services Tax (IGST), Central Goods and
              Services Tax (CGST) and Union Territory Goods and Services Tax
              (UTGST) or State Goods and Services Tax (SGST) in respect of the
              goods/services supplied by you.
              <br />
              3. It is your responsibility to charge appropriate goods and
              services taxes on the supplies effected and remittance of the same
              to the Government. Chattybao shall not be responsible for any
              deficiency and/ or omission on your part.
              <br />
              4. Pursuant to the tax collection at source provisions under IGST,
              CGST and/UTGST or SGST, the portal would collect tax collection at
              source at applicable rates on net value of taxable supplies made
              through the portal and remit to the appropriate Government.
              <br />
              5. In case of any mismatches on account of tax collection at
              source, you shall be required to provide all relevant information
              to Chattybao to correspond with the relevant authorities and also
              in case of any liability accruing on account of omission shall be
              your obligation to pay such deficit.
              <br />
              6. You shall be required to provide the corresponding Harmonised
              System Nomenclature (HSN) code number for every product sold,
              which would be validated by Chattybao. In the event, the HSN Code
              number differs from the code recommended by Chattybao, the HSN
              code suggested by Chattybao shall be final.
              <br />
              7. You shall also be required to provide your GSTIN, without which
              we will not be able to raise an invoice on you. In the event that
              you do not provide your GSTIN number, transaction on your account
              will be blocked and orders will not be processed on your account.
              In the event of you providing your Input Service Distributor
              Registration Number, Chattybao would be issuing an invoice to the
              ISD GST registration number as furnished by you. It is your
              responsibility to undertake the necessary compliance required in
              respect of the said ISD registration number.
              <br />
              8. Merchants shall be required to indemnify and hold harmless
              Chattybao, its promoters, officers, directors, employees,
              affiliates, agents, sub-contractors and other representatives from
              any losses including loss of input tax credit, claim, demands,
              liabilities, suits, proceedings, penalties, costs or expenses of
              anykind (including, attorneys’ fee s and expenses) on account of
              violation of applicable tax laws by the Merchant (including but
              not limited to non-filing of the requisite forms with the tax
              authorities to claim tax credit etc.).
            </p>
          </div>
        </div>
      </div>
      <div
        className="container1"
        style={{
          backgroundImage: `url(${orange})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPosition: "revert",
        }}
      >
        <div className="con-2">
          <div className="con-2__content">
            <h1>Have a Business ?</h1>
            <h2>5 minute Sign up</h2>
            <div className="con-2__link">
              <a
                href="https://play.google.com/store/apps/details?id=com.goping.merchant"
                target="_blank"
                rel="noreferrer"
              >
                <img src={img7} alt="" />
              </a>
              <div className="con-2__link-content">
                <a
                  href="https://play.google.com/store/apps/details?id=com.goping.merchant"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>Download Now</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantAgreementPage;
