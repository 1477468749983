import { createSlice } from "@reduxjs/toolkit";

export const reduxStore = createSlice({
  name: "counter",
  initialState: {
    cartValue: false,
    totalOrderData: "",
  },
  reducers: {
    setCartValue: (state, action) => {
      state.value = action.payload.payload;
    },
    setTotalOrderData: (state, action) => {
      state.Order = action.payload.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCartValue, setTotalOrderData } = reduxStore.actions;

export default reduxStore.reducer;
